import R14, { AsyncStorage } from "../core";
export default class ManualEntryDomain extends R14.Domain {
  constructor() {
    super();
    this.STATE_AVAILABLE = "AVAILABLE";
    this.STATE_REVIEW = "REVIEW";
    this.STATE_QUEUED = "QUEUED";
    this.STATE_COMPLETE = "COMPLETE";
    this.ACTION_ACCEPT = "ACCEPT";
    this.ACTION_REJECT = "REJECT";
    this.MODE_CHARACTERS = "CHARACTERS";
    this.MODE_FIELD = "FIELD";
    this.MODE_FIELD_CHARACTERS = "FIELD_CHARACTERS";
    this.MODE_DOCUMENT_SET_FIELDS = "DOCUMENT_SET_FIELDS";
    this.ANNOTATION_TYPE_FIELD = "FIELD";
    this.ANNOTATION_TYPE_CHARACTER = "CHARACTER";
    this.ANNOTATION_TYPE_FIELD_GROUP = "FIELD_GROUP";
    this.METRIC_TYPE_SUBMISSIONS = "SUBMISSIONS";
    this.METRIC_TYPE_SECONDS = "SECONDS";
    this.METRIC_TYPE_KEYSTROKES = "KEYSTROKES";
    this.METRIC_TYPE_FIELD_UPDATES = "FIELD_UPDATES";
    this.METRIC_TYPE_FIELD_REJECTIONS = "FIELD_REJECTIONS";
    this.METRIC_TYPE_CHARACTER_UPDATES = "CHARACTER_UPDATES";
    this.METRIC_TYPE_CHARACTER_REJECTIONS = "CHARACTER_REJECTIONS";
    this.METRIC_TYPE_CHARACTERS = "CHARACTERS";
    this.METRIC_TYPE_FIELDS = "FIELDS";
    this.METRIC_TYPE_ANNOTATION_SETS = "ANNOTATION_SETS";
    this.METRIC_TYPE_ACCURACY = "ACCURACY";
    this.METRIC_AGGREGATE_TYPE_AVERAGE_RATE = "AVERAGE_RATE";
    this.METRIC_AGGREGATE_TYPE_PERCENTAGE = "PERCENTAGE";
    this.DOCUMENT_IMAGE_STORAGE_MODE_STORAGE = "STORAGE";
    this.DOCUMENT_IMAGE_STORAGE_MODE_DATASET = "DATASET";
    this.DOCUMENT_IMAGE_INITIAL_SCALE_MODE_COVER = "COVER";
    this.DOCUMENT_IMAGE_INITIAL_SCALE_MODE_CONTAIN = "CONTAIN";
    this.ANNOTATION_SET_DOCUMENT_ACTION_COPY = "COPY";
    this.ANNOTATION_SET_DOCUMENT_ACTION_MOVE = "MOVE";
    this.ANNOTATION_SET_DOCUMENT_ACTION_ROTATE = "ROTATE";

    this.METRIC_RATE_MINUTE = "MINUTE";
    this._maxQueueLength = 5;
    this._mode = null;
    this._queueProcessed = false;
    this._queue = [];
    this._queueIdx = 0;
    this._userMetrics = null;
    this._disableTimeouts = false;
    this._userSession = null;
    this._ndaAcceptRequired = false;
    this._ndaAccepted = false;
    this.init();
    this.state = {
      keyOverlayMode: true,
      focusedCharacterUuid: null,
      uiConfig: { styles: [] },
    };
    // this.state = {
    //   stats: {
    //     fields: 0,
    //     reject: 0,
    //     valid: 0,
    //     seconds: 0,
    //   },
    // };
    // this._mode = this.MODE_CHARACTERS;
  }
  init() {
    this.reset();
    //this.initUserMetrics();
  }
  // async domainDidLoad() {
  //   this.initUserMetrics();
  // }
  reset() {
    this._queueProcessed = false;
    this._queue = [];
    this._queueIdx = 0;
    this._documentSet = null;
    // this.state = {
    //   stats: {
    //     fields: 0,
    //     reject: 0,
    //     valid: 0,
    //     seconds: 0,
    //   },
    // };
  }

  async update() {
    // Check if there are unprocessed items
    let nextUnprocessedQueueItemIdx = this.nextUnprocessedQueueItemIdx;
    if (nextUnprocessedQueueItemIdx !== null) {
      this._queueIdx = nextUnprocessedQueueItemIdx;
      return;
    }
    let fetchNext = !this.isMaxQueueLength;
    let fetchQueued = !this.queueExists;
    this._queueIdx = 0;
    let queue = await this.fetchQueue({
      next: fetchNext,
      queued: fetchQueued,
    });
    if (fetchQueued) this._queue = [];

    queue.forEach((item) => this._queue.push(item));
    let processed = this._queue.length ? true : false;
    for (let idx in this._queue) {
      let item = this._queue[idx];
      if (item.state === this.STATE_QUEUED) {
        processed = false;
        this._queueIdx = parseInt(idx);
        break;
      }
    }
    this._queueProcessed = processed;
  }
  get disableTimeouts() {
    return this._disableTimeouts;
  }
  get keyOverlayMode() {
    let keyOverlayMode = this.dm.userSession.getUserSetting("keyOverlayMode");
    return keyOverlayMode === false ? false : true;
  }
  async setKeyOverlayMode(value) {
    await this.dm.userSession.setUserSetting(
      "keyOverlayMode",
      value === true ? true : false
    );
  }
  get timeoutAt() {
    let timeoutAt = null;
    for (let item of this._queue) {
      let itemTimeoutAt = new Date(item.timeoutAt);
      if (timeoutAt === null || timeoutAt < itemTimeoutAt) {
        timeoutAt = itemTimeoutAt;
      }
    }
    return timeoutAt;
  }
  get focusedCharacterUuid() {
    return this.state.focusedCharacterUuid;
  }
  setFocusedCharacterUuid(focusedCharacterUuid) {
    this.setState({ focusedCharacterUuid });
  }
  filterAnnotations(annotations, filter = {}) {
    if (!annotations || !Array.isArray(annotations)) return [];
    return annotations.filter((annotation) => {
      if (filter.type && annotation.type !== filter.type) return false;
      if (filter.uuid && annotation.uuid !== filter.uuid) return false;
      return true;
    });
  }
  async updateUserSession(manualEntryUserSession = null) {
    if (!manualEntryUserSession || !manualEntryUserSession.uuid)
      throw new Error("Session Error: User session not found.");
    this._userSession = manualEntryUserSession;
  }
  async fetchDocumentSet(options = {}) {
    if (
      ![this.MODE_DOCUMENT_SET_FIELDS].includes(this._mode) ||
      !this._manualEntryDocumentSet ||
      !this._manualEntryAnnotationSetDocuments ||
      !this._manualEntryAnnotationSetDocuments.nodes ||
      !this._manualEntryAnnotationSetDocuments.nodes.length
    )
      return null;
    let documentSet = null;
    try {
      documentSet = {
        uid: this._manualEntryDocumentSet.uid,
        uuid: this._manualEntryDocumentSet.uuid,
        documentImageStorageMode:
          this._manualEntryDocumentSet.documentImageStorageMode,
        totalDocumentImages: this._manualEntryAnnotationSetDocuments.totalCount,
        pages: this.createAnnotationSetDocumentPages(),
        documentImagesPageInfo:
          this._manualEntryAnnotationSetDocuments.pageInfo,
        annotations: [],
      };

      // Get the document set annotations
      this._queue.map((item) => {
        documentSet.annotationSetUid = item.uid;
        item.annotations.map((annotation) =>
          documentSet.annotations.push(annotation)
        );
      });
      if (
        this._manualEntryAnnotationSetAnnotations &&
        this._manualEntryAnnotationSetAnnotations.nodes &&
        this._manualEntryAnnotationSetAnnotations.nodes.length
      ) {
        this._manualEntryAnnotationSetAnnotations.nodes.forEach((annotation) =>
          documentSet.annotations.push(annotation)
        );
        documentSet = {
          ...documentSet,
          totalFieldAnnotations:
            this._manualEntryAnnotationSetAnnotations.totalCount,
          fieldAnnotationsPageInfo:
            this._manualEntryAnnotationSetAnnotations.pageInfo,
        };
      }
    } catch (err) {
      throw err;
      // console.error(err);
      // return null;
    }

    // Sort the pages
    documentSet.pages.sort((a, b) => (a.number > b.number ? 1 : -1));
    return documentSet;
  }

  createAnnotationSetDocumentPages() {
    return this._manualEntryAnnotationSetDocuments.nodes.map(
      (
        { uuid, image, imageHeight, imageWidth, imageRotate, imageName, words },
        idx
      ) => {
        let page = {
          number:
            parseInt(idx) +
            1 +
            (this._manualEntryAnnotationSetDocuments.pageInfo.page - 1) *
              this._manualEntryAnnotationSetDocuments.pageInfo.resultsPerPage,
          image,
          documentUuid: uuid,
          words: words || null,
        };
        switch (this._manualEntryDocumentSet.documentImageStorageMode) {
          case this.DOCUMENT_IMAGE_STORAGE_MODE_DATASET:
            page.image = {
              height: imageHeight,
              width: imageWidth,
              rotate: imageRotate,
              url: `/document/image/${this._manualEntryDocumentSet.uuid}/${imageName}`,
            };
            break;
        }

        let pageState = this.STATE_QUEUED;
        page.metadata = { state: pageState };
        return page;
      }
    );
  }

  async completeDocumentSet(uid) {}
  async updateDocumentSetAnnotation(values, options = {}) {
    let submitValues = this.parseDocumentSetAnnotationFormValues(values);
    let update = await this.updateQueueItem(submitValues, options);
    return update.annotations
      .filter((annotation) => annotation.uuid === values.uuid)
      .at(0);
  }
  parseDocumentSetAnnotationFormValues(values) {
    let ret = {
      //value: "value" in values ? values.value : values.valueSelection || null,
      reject: values.reject === true,
      state: this.STATE_QUEUED,
      uuid: values.uuid,
      startTime: values.startTime,
    };
    if (values.values) ret.values = values.values;
    else
      ret.value =
        "value" in values ? values.value : values.valueSelection || null;
    if (values.offset) ret.offset = values.offset;
    return ret;
  }
  async runAnnotationSetDocumentAction(
    uid,
    documentUuid,
    action,
    value,
    currentAnnotationUuid,
    options = {}
  ) {
    let input = {
      uid,
      action,
      documentUuid,
      value,
      currentAnnotationUuid,
    };

    let res = await this.api.qry(
      `
            mutation RunAnnotationSetDocumentAction($input: RunAnnotationSetDocumentActionInput!) {
              runAnnotationSetDocumentAction(input: $input) {  
                documentUuid 
                manualEntryAnnotationSetDocuments {
                  nodes {
                    uuid
                    pageNumber
                    manualEntryDocumentUid
                    imageName
                    imageWidth
                    imageHeight
                    imageSize
                    imageRotate
                    image {
                      uid
                      height
                      width
                      url
                    }
                  }
                  totalCount
                  pageInfo {
                    page
                    resultsPerPage
                    hasNextPage
                  }
                }         
                manualEntryAnnotationSetAnnotations {
                  nodes {
                    uid
                    uuid
                    documentUuid
                    documentPositionIndex
                    positionIndex
                    name
                    label
                    description
                    parentAnnotationUuid
                    type
                    value
                    updatedValue
                    valueSelections {
                      label
                      value
                    }
                    valueSelectionEditable
                    readOnly
                    values {
                      questionAnnotationUuidPath
                      selectionUuid
                      value
                    }
                    updatedValues {
                      questionAnnotationUuidPath
                      selectionUuid
                      value
                    }
                    fieldType
                    selections {
                      label
                      value
                      uuid
                    }
                    selectionsAnnotationUuid
                    selectionQuestionAnnotations {
                      selectionUuid
                      questionAnnotationUuids
                    }
                    valid
                    required
                    offsetRequired
                    reject
                    imageBase64
                    score
                    lowScore
                    state
                    offset {
                      top
                      left
                      height
                      width
                    }
                    updatedOffset {
                      top
                      left
                      height
                      width
                    }
                  }
                  totalCount
                  pageInfo {
                    page
                    resultsPerPage
                    hasNextPage
                  }
                }
              }
           }`,
      {
        input,
      }
    );

    // documentSet = {
    //   uid: this._manualEntryDocumentSet.uid,
    //   uuid: this._manualEntryDocumentSet.uuid,
    //   documentImageStorageMode:
    //     this._manualEntryDocumentSet.documentImageStorageMode,
    //   totalDocumentImages: this._manualEntryDocumentSet.totalDocuments,
    //   annotations: [],
    //   pages: this.createAnnotationSetDocumentPages(),
    //   documentImagesPageInfo: this._manualEntryAnnotationSetDocuments.pageInfo,
    // };

    let ret = [];
    if (
      res.data &&
      res.data.runAnnotationSetDocumentAction &&
      res.data.runAnnotationSetDocumentAction.manualEntryAnnotationSetDocuments
    ) {
      this._manualEntryAnnotationSetDocuments =
        res.data.runAnnotationSetDocumentAction.manualEntryAnnotationSetDocuments;
    }
    if (
      res.data &&
      res.data.runAnnotationSetDocumentAction &&
      res.data.runAnnotationSetDocumentAction
        .manualEntryAnnotationSetAnnotations
    ) {
      this._manualEntryAnnotationSetAnnotations =
        res.data.runAnnotationSetDocumentAction.manualEntryAnnotationSetAnnotations;
    }
    return {
      uid: this._manualEntryDocumentSet.uid,
      uuid: this._manualEntryDocumentSet.uuid,
      documentImageStorageMode:
        this._manualEntryDocumentSet.documentImageStorageMode,
      totalDocumentImages: this._manualEntryAnnotationSetDocuments.totalCount,
      annotations: this._manualEntryAnnotationSetAnnotations.nodes,
      pages: this.createAnnotationSetDocumentPages(),
      documentImagesPageInfo: this._manualEntryAnnotationSetDocuments.pageInfo,
      fieldAnnotationsPageInfo:
        this._manualEntryAnnotationSetAnnotations.pageInfo,
    };
  }
  async loadAnnotationSetAnnotations(uid, options = {}) {
    if (!options.page && !options.findNext) {
      console.error("No valid input for loadAnnotationSetAnnotations");
      return null;
    }
    let res = await this.api.qry(
      `
            query ManualEntryAnnotationSetAnnotations($uid: ID!, $page: Int, $resultsPerPage: Int, $type: ManualEntryAnnotationSetAnnotationTypeEnum, $findNext: Boolean, $currentAnnotationUuid: String ) {
              manualEntryAnnotationSetAnnotations(uid: $uid, page: $page, resultsPerPage: $resultsPerPage, type: $type, findNext: $findNext, currentAnnotationUuid: $currentAnnotationUuid ){            
                nodes {
                  uid
                  uuid
                  documentUuid
                  documentPositionIndex
                  positionIndex
                  name
                  label
                  description
                  parentAnnotationUuid
                  type
                  value
                  updatedValue
                  valueSelections {
                    label
                    value
                  }
                  valueSelectionEditable
                  readOnly
                  fieldType
                  selections {
                    label
                    value
                    uuid
                  }
                  selectionsAnnotationUuid
                  selectionQuestionAnnotations {
                    selectionUuid
                    questionAnnotationUuids
                  }
                  values {
                    questionAnnotationUuidPath
                    selectionUuid
                    value
                  }
                  updatedValues {
                    questionAnnotationUuidPath
                    selectionUuid
                    value
                  }
                  valid
                  required
                  offsetRequired
                  reject
                  imageBase64
                  score
                  lowScore
                  state
                  offset {
                    top
                    left
                    height
                    width
                  }
                  updatedOffset {
                    top
                    left
                    height
                    width
                  }
                }
                totalCount
                pageInfo {
                  page
                  resultsPerPage
                  hasNextPage
                }
              }
           }`,
      {
        uid,
        ...options,
      }
    );
    if (res.data && res.data.manualEntryAnnotationSetAnnotations) {
      this._manualEntryAnnotationSetAnnotations =
        res.data.manualEntryAnnotationSetAnnotations;
    }
    return {
      uid: this._manualEntryDocumentSet.uid,
      uuid: this._manualEntryDocumentSet.uuid,
      // documentImageStorageMode:
      //   this._manualEntryDocumentSet.documentImageStorageMode,
      totalFieldAnnotations:
        this._manualEntryAnnotationSetAnnotations.totalCount,
      annotations: this._manualEntryAnnotationSetAnnotations.nodes,
      // pages: this.createAnnotationSetDocumentPages(),
      fieldAnnotationsPageInfo:
        this._manualEntryAnnotationSetAnnotations.pageInfo,
    };
  }
  async loadAnnotationSetDocuments(uid, options = {}) {
    if (!options.documentUuid && !options.page) {
      console.error("No valid input for fetchAnnotationSetDocuments");
      return null;
    }

    let res = await this.api.qry(
      `
            query ManualEntryAnnotationSetDocuments($uid: ID!, $page: Int, $resultsPerPage: Int, $documentUuid: ID) {
              manualEntryAnnotationSetDocuments(uid: $uid, page: $page, resultsPerPage: $resultsPerPage, documentUuid: $documentUuid){            
                nodes {
                  uuid
                  pageNumber
                  manualEntryDocumentUid
                  imageName
                  imageWidth
                  imageHeight
                  imageSize
                  imageRotate
                  image {
                    uid
                    height
                    width
                    url
                  }
                  words {
                    word
                    offset {
                      top
                      left
                      height
                      width
                    }
                  }
                }
                totalCount
                pageInfo {
                  page
                  resultsPerPage
                  hasNextPage
                }
              }
           }`,
      {
        uid,
        ...options,
      }
    );
    if (res.data && res.data.manualEntryAnnotationSetDocuments) {
      this._manualEntryAnnotationSetDocuments =
        res.data.manualEntryAnnotationSetDocuments;
    }
    return {
      uid: this._manualEntryDocumentSet.uid,
      uuid: this._manualEntryDocumentSet.uuid,
      documentImageStorageMode:
        this._manualEntryDocumentSet.documentImageStorageMode,
      totalDocumentImages: this._manualEntryAnnotationSetDocuments.totalCount,
      annotations: [],
      pages: this.createAnnotationSetDocumentPages(),
      documentImagesPageInfo: this._manualEntryAnnotationSetDocuments.pageInfo,
    };
  }
  async fetchQueue(options = {}) {
    // return [];
    let res = await this.api.qry(
      `
            mutation ManualEntryAnnotationSetQueue($input: ManualEntryAnnotationSetQueueInput!) {
              manualEntryAnnotationSetQueue(input: $input){
                manualEntryUserSession {
                  uuid
                  confirmed
                }
                manualEntryUser {
                  uid
                  ndaAccepted
                }
                manualEntryConfig {
                  uid
                  ndaAcceptRequired
                  enableDocumentCopy
                  enableDocumentMove
                  enableDocumentRotate
                  enableDocumentFieldOffsetSelect
                  disableReject
                  disableRejectRemaining
                  disableAcceptRemaining
                  disableDocumentPreview
                  enableDocumentFieldOffsetSelect
                  initialDocumentScaleMode
                }
                manualEntryUiConfig {
                  uid
                  disableHistory
                  sideSheetPosition
                  showFieldValues
                  values {
                    name
                    value
                  }
                  features {
                    name
                    enabled
                  }
                  styles {
                    name
                    backgroundColor {
                      themeColor
                      alpha
                      hex
                    }
                    color {
                      themeColor
                      alpha
                      hex
                    }
                    borderColor {
                      themeColor
                      alpha
                      hex
                    }
                    borderRadius
                    borderWidth
                    borderStyle
                    padding
                    paddingLeft
                    paddingRight
                    paddingTop
                    paddingBottom
                    margin
                    marginLeft
                    marginRight
                    marginTop
                    marginBottom
                    width
                    maxWidth
                    minWidth
                    height
                    maxHeight
                    minHeight
                    fontSize
                    fontWeight
                  } 
                }
                manualEntryDocumentSet {
                  uid
                  uuid
                  totalDocuments
                  documentImageStorageMode
                }
                manualEntryAnnotationSetDocuments {
                  nodes {
                    uuid
                    pageNumber
                    manualEntryDocumentUid
                    totalFields
                    imageName
                    imageWidth
                    imageHeight
                    imageSize
                    imageRotate
                    image {
                      uid
                      height
                      width
                      url
                    }
                    words {
                      word
                      offset {
                        top
                        left
                        height
                        width
                      }
                    }
                  }
                  totalCount
                  pageInfo {
                    page
                    resultsPerPage
                    hasNextPage
                  }
                }
                manualEntryAnnotationSetAnnotations {
                  nodes {
                    uid
                    uuid
                    documentUuid
                    documentPositionIndex
                    positionIndex
                    name
                    label
                    description
                    parentAnnotationUuid
                    type
                    value
                    updatedValue
                    valueSelections {
                      label
                      value
                    }
                    valueSelectionEditable
                    readOnly
                    fieldType
                    selections {
                      label
                      value
                      uuid
                    }
                    selectionsAnnotationUuid
                    selectionQuestionAnnotations {
                      selectionUuid
                      questionAnnotationUuids
                    }
                    values {
                      questionAnnotationUuidPath
                      selectionUuid
                      value
                    }
                    updatedValues {
                      questionAnnotationUuidPath
                      selectionUuid
                      value
                    }
                    valid
                    required
                    offsetRequired
                    reject
                    imageBase64
                    score
                    lowScore
                    state
                    offset {
                      top
                      left
                      height
                      width
                    }
                    updatedOffset {
                      top
                      left
                      height
                      width
                    }
                  }
                  totalCount
                  pageInfo {
                    page
                    resultsPerPage
                    hasNextPage
                  }
                }
                mode
                itemsPerQueue
                queue {
                  uid
                  mode
                  state
                  valid
                  reject
                  clientUid
                  manualEntryUserUid
                  manualEntryDocumentUids
                  totalKeystrokes
                  timeoutAt
                  timeoutSeconds
                  annotations {
                    uid
                    uuid
                    documentUuid
                    documentPositionIndex
                    positionIndex
                    name
                    label
                    description
                    parentAnnotationUuid
                    type
                    value
                    updatedValue
                    valueSelections {
                      label
                      value
                    }
                    valueSelectionEditable
                    readOnly
                    fieldType
                    selections {
                      label
                      value
                      uuid
                    }
                    selectionsAnnotationUuid
                    selectionQuestionAnnotations {
                      selectionUuid
                      questionAnnotationUuids
                    }
                    valid
                    required
                    offsetRequired
                    reject
                    imageBase64
                    imageName
                    imageWidth
                    imageHeight
                    score
                    lowScore
                    state
                    offset {
                      top
                      left
                      height
                      width
                    }
                    updatedOffset {
                      top
                      left
                      height
                      width
                    }
                  }
                }
              }
           }`,
      {
        input: {
          next: options.next ? true : false,
          queued: options.queued ? true : false,
        },
      }
    );

    let ret = [];
    // const uiConfig = {
    //   disableHistory: true,
    //   sideSheetPosition: "LEFT",
    //   showFieldValues: true,
    //   values: [
    //     {
    //       name: "sideSheetPosition",
    //       value: "LEFT"
    //     }
    //   ],
    //   features: [
    //     {
    //       name: "history",
    //       enabled: false,
    //     },
    //     {
    //       name: "sideSheetFieldValues",
    //       enabled: true,
    //     },
    //   ],
    //   styles: [
    //     {
    //       name: "fieldActive",
    //       backgroundColor: { themeColor: "yellow", alpha: 0.8 },
    //     },
    //     {
    //       name: "fieldTextActive",
    //       color: { hex: "#1c1c1c" },
    //       fontWeight: 500,
    //     },
    //     {
    //       name: "sideSheet",
    //       width: 540,
    //     },
    //     {
    //       name: "formDescription",
    //       backgroundColor: { themeColor: "onSurface", alpha: 0.04 },
    //       borderRadius: 4,
    //       padding: 8,
    //       marginBottom: 16,
    //     },
    //     {
    //       name: "formDescriptionText",
    //       color: { themeColor: "onSurface" },
    //       fontSize: 14,
    //     },
    //   ],
    // };
    if (res.data && res.data.manualEntryAnnotationSetQueue) {
      await this.updateUserSession(
        res.data.manualEntryAnnotationSetQueue.manualEntryUserSession
      );

      if (res.data.manualEntryAnnotationSetQueue.queue)
        ret = res.data.manualEntryAnnotationSetQueue.queue;
      if (res.data.manualEntryAnnotationSetQueue.itemsPerQueue)
        this._maxQueueLength =
          res.data.manualEntryAnnotationSetQueue.itemsPerQueue;
      this._mode = res.data.manualEntryAnnotationSetQueue.mode || null;
      this._manualEntryDocumentSet =
        res.data.manualEntryAnnotationSetQueue.manualEntryDocumentSet || null;
      this._manualEntryAnnotationSetDocuments =
        res.data.manualEntryAnnotationSetQueue
          .manualEntryAnnotationSetDocuments || null;
      this._manualEntryAnnotationSetAnnotations =
        res.data.manualEntryAnnotationSetQueue
          .manualEntryAnnotationSetAnnotations || null;
      this._ndaAcceptRequired =
        res.data.manualEntryAnnotationSetQueue.manualEntryConfig
          .ndaAcceptRequired || false;
      this._initialDocumentScaleMode =
        res.data.manualEntryAnnotationSetQueue.manualEntryConfig
          .initialDocumentScaleMode ||
        this.DOCUMENT_IMAGE_INITIAL_SCALE_MODE_COVER;
      // this._documentSetEditable =
      //   res.data.manualEntryAnnotationSetQueue.manualEntryConfig
      //     .documentSetEditable || false;

      this._ndaAccepted =
        res.data.manualEntryAnnotationSetQueue.manualEntryUser.ndaAccepted ||
        false;

      this._appFeatures = this.getAppFeatureValues().filter((f) =>
        res.data.manualEntryAnnotationSetQueue.manualEntryConfig[f]
          ? true
          : false
      );
      if (
        res.data.manualEntryAnnotationSetQueue.manualEntryUiConfig &&
        res.data.manualEntryAnnotationSetQueue.manualEntryUiConfig.uid
      )
        this.ui.config.load(
          res.data.manualEntryAnnotationSetQueue.manualEntryUiConfig
        );
    }

    // Update timeout based on client time
    let clientTime = new Date();
    ret = ret.map((manualEntryAnnotationSet) => {
      if (
        manualEntryAnnotationSet.timeoutSeconds &&
        !isNaN(manualEntryAnnotationSet.timeoutSeconds) &&
        manualEntryAnnotationSet.timeoutSeconds > 0
      ) {
        let timeoutAt = new Date(clientTime);
        timeoutAt.setSeconds(
          timeoutAt.getSeconds() + manualEntryAnnotationSet.timeoutSeconds
        );
        manualEntryAnnotationSet.timeoutAt = timeoutAt;
      }
      return manualEntryAnnotationSet;
    });
    return ret;
  }
  hasAppFeature(value) {
    // Disable features for old storage mode
    if (
      this._manualEntryDocumentSet &&
      this._manualEntryDocumentSet.documentImageStorageMode !==
        this.DOCUMENT_IMAGE_STORAGE_MODE_DATASET &&
      [
        "enableDocumentCopy",
        "enableDocumentMove",
        "enableDocumentRotate",
      ].includes(value)
    )
      return false;
    return this._appFeatures && this._appFeatures.includes(value)
      ? true
      : false;
  }
  getAppFeatureValues() {
    return [
      "ndaAcceptRequired",
      "enableDocumentCopy",
      "enableDocumentMove",
      "enableDocumentRotate",
      "disableReject",
      "disableRejectRemaining",
      "disableAcceptRemaining",
      "disableDocumentPreview",
      "enableDocumentFieldOffsetSelect",
      "enabledSideSheetValues",
    ];
  }

  hasCharacterFieldOffset(character) {
    // console.log("CHECK FIELD OFFSET", character.value, character.fieldOffset);
    return (
      character.fieldOffset &&
      !isNaN(character.fieldOffset.top) &&
      !isNaN(character.fieldOffset.left) &&
      !isNaN(character.fieldOffset.width) &&
      !isNaN(character.fieldOffset.height) &&
      character.fieldOffset.top >= 0 &&
      character.fieldOffset.left >= 0 &&
      character.fieldOffset.width >= 0 &&
      character.fieldOffset.height >= 0
    );
  }
  createCharacterGrid(characters) {
    let characterGrid = { fontSize: 0, rows: [] };
    let currRow = 0;
    let lastChar = null;
    for (let char of characters) {
      // check if new rows.
      let hasFieldOffset = this.hasCharacterFieldOffset(char);
      if (hasFieldOffset) {
        if (lastChar) {
          // TODO: There are a lot of exceptions for this.
          if (
            char.fieldOffset.left <
              lastChar.fieldOffset.left + lastChar.fieldOffset.width &&
            char.fieldOffset.top >
              lastChar.fieldOffset.top + lastChar.fieldOffset.height
          ) {
            currRow++;
          }
        }
      }
      if (characterGrid.rows.length === currRow) {
        characterGrid.rows.push({
          offset: {},
          cols: [],
        });
      }
      characterGrid.rows[currRow].cols.push({ hasFieldOffset, ...char });
      if (hasFieldOffset) lastChar = char;
    }
    characterGrid.rows = characterGrid.rows.map((row, rowIdx) => {
      let offset = null;
      // Calculate offset of row in field
      row.cols = row.cols.map((col, colIdx) => {
        if (offset === null) {
          offset = { top: col.fieldOffset.top, height: col.fieldOffset.height };
        } else {
          if (col.fieldOffset.top < offset.top)
            offset.top = col.fieldOffset.top;
          if (col.fieldOffset.height > offset.height)
            offset.height = col.fieldOffset.height;
          // TODO find a better way to calculate font / size
          if (offset.height > characterGrid.fontSize)
            characterGrid.fontSize = offset.height;
        }
        return col;
      });
      row.offset = offset;

      // Expand the rows to all touch like col cells.
      let colOffsetLeftMap = {};
      row.cols.forEach((col, colIdx) => {
        colIdx = parseInt(colIdx);
        if (colIdx === 0) {
          colOffsetLeftMap[col.uid] = 0;
          return;
        }
        let lastCol = colIdx > 0 ? row.cols[colIdx - 1] : null;
        if (lastCol && !lastCol.hasFieldOffset) lastCol = null;

        let nextCol =
          row.cols.length > colIdx + 1 ? row.cols[colIdx + 1] : null;
        if (nextCol && !nextCol.hasFieldOffset) nextCol = null;

        let lastColDiffLeft = lastCol
          ? col.fieldOffset.left -
            (lastCol.fieldOffset.left + lastCol.fieldOffset.width)
          : null;

        let nextColDiffLeft = nextCol
          ? nextCol.fieldOffset.left -
            (col.fieldOffset.left + col.fieldOffset.width)
          : null;

        // Check for whitespace
        let whitespace = col.value.trim() === "" ? true : false;
        let whitespaceLast =
          lastCol && lastCol.value.trim() === "" ? true : false;
        // let whitespaceNext =
        //   nextCol && nextCol.value.trim() === "" ? true : false;
        // if (whitespaceLast) {
        //   console.log(
        //     "WHITE SPACE LAST",
        //     col.value,
        //     lastColDiffLeft,
        //     nextColDiffLeft
        //   );
        // }
        if (whitespaceLast && nextColDiffLeft !== null) {
          colOffsetLeftMap[col.uid] = colOffsetLeftMap[col.uid] =
            col.fieldOffset.left - nextColDiffLeft / 2;
        } else if (whitespace) {
          if (lastCol && lastCol.uid in colOffsetLeftMap) {
            // console.log('CHECK CHECK CHECK', lastCol.value,lastCol.fieldOffset.left - colOffsetLeftMap[lastCol.uid]);
            colOffsetLeftMap[col.uid] =
              lastCol.fieldOffset.left +
              lastCol.fieldOffset.width +
              (lastCol.fieldOffset.left - colOffsetLeftMap[lastCol.uid]) * 2;
          }
        } else {
          colOffsetLeftMap[col.uid] =
            col.fieldOffset.left - lastColDiffLeft / 2;
        }
        if (colOffsetLeftMap[col.uid] > 1) colOffsetLeftMap[col.uid] = 1;
      });

      row.cols = row.cols.map((col, colIdx) => {
        if (!col.hasFieldOffset) return col;
        colIdx = parseInt(colIdx);

        let lastCol = colIdx > 0 ? row.cols[colIdx - 1] : null;
        if (lastCol && !lastCol.hasFieldOffset) lastCol = null;

        let nextCol =
          row.cols.length > colIdx + 1 ? row.cols[colIdx + 1] : null;
        if (nextCol && !nextCol.hasFieldOffset) nextCol = null;

        let nCollOffset = {
          width: col.fieldOffset.width,
          left: col.fieldOffset.left,
        };

        if (col.uid in colOffsetLeftMap) {
          nCollOffset.left = colOffsetLeftMap[col.uid];
          if (nextCol && nextCol.uid in colOffsetLeftMap) {
            nCollOffset.width =
              colOffsetLeftMap[nextCol.uid] - colOffsetLeftMap[col.uid];
          } else {
            nCollOffset.width =
              col.fieldOffset.width +
              (col.fieldOffset.left - nCollOffset.left) * 2;
            if (nCollOffset.left + nCollOffset.width > 1) {
              nCollOffset.width -= nCollOffset.left + nCollOffset.width - 1;
            }
          }
        }
        col.offset = nCollOffset;
        return col;
      });

      return row;
    });
    return characterGrid;
  }

  parseQueueItemUpdate(item) {
    // Clean up the returned value
    let ret = {};
    for (let key in item) {
      switch (key) {
        // case "fieldImageBase64":
        // case "fieldValue":
        // case "fieldName":
        case "timeoutAt":
        case "timeoutSeconds":
          // Skip value;
          break;
        case "annotations":
          ret.annotations = item[key].map((annotation) => {
            let ret = {
              uid: annotation.uid,
              uuid: annotation.uuid,
              type: annotation.type,
              reject: annotation.reject || false,
              valid: annotation.valid || false,
            };
            if (annotation.updatedOffset)
              ret.updatedOffset = annotation.updatedOffset;
            if (annotation.updatedValues)
              ret.updatedValues = annotation.updatedValues;
            else
              ret.updatedValue =
                annotation.updatedValue !== null &&
                annotation.updatedValue != undefined
                  ? annotation.updatedValue
                  : null;
            return ret;
          });
          break;
        default:
          ret[key] = item[key];
      }
    }
    return ret;
  }
  parseQueueItemUpdates(items) {
    return items.map((item) => this.parseQueueItemUpdate(item));
  }
  async updateQueueItems(manualEntryAnnotationSets) {
    let input = this.parseQueueItemUpdates(manualEntryAnnotationSets);
    let res = await this.api.qry(
      `
          mutation UpdateManualEntryAnnotationSetQueue($input: [UpdateManualEntryAnnotationSetInput!]!) {
            updateManualEntryAnnotationSetQueue(input: $input){
              manualEntryAnnotationSets {
                manualEntryAnnotationSet {
                  uid
                  state
                  timeoutAt
                  timeoutSeconds
                  totalSeconds
                  totalKeystrokes
                  annotations {
                    uid
                    uuid
                  }
                }
              }
              success
            }
         }`,
      {
        input,
      }
    );
    if (res.data && res.data.updateManualEntryAnnotationSetQueue)
      return res.data.updateManualEntryAnnotationSetQueue;
    else return null;
  }
  async saveQueue(options = {}) {
    let updatedFields = [];
    try {
      let items = this._queue
        .filter(
          (item) => item.state === this.STATE_REVIEW || options.force === true
        )
        .map((item) => {
          item.state = this.STATE_COMPLETE;
          item.totalSeconds = 0;
          switch (item.mode) {
            case this.MODE_DOCUMENT_SET_FIELDS:
              item.totalSeconds = this.calculateTotalSeconds(options.startTime);
              if (options.force) {
                let reject = options.rejectRemaining === true;
                item.reject = reject;
                item.valid = !reject;
                let currentAnnotation = options.currentAnnotation || null;
                let annotations = item.annotations
                  .filter(
                    (annotation) =>
                      annotation.state === this.STATE_QUEUED &&
                      annotation.type === this.ANNOTATION_TYPE_FIELD
                  )
                  .map((annotation) => ({
                    uuid: annotation.uuid,
                    state: this.STATE_COMPLETE,
                    valid: !reject,
                    reject: reject,
                  }));
                // If there are no annotations send current annotation to update time.
                if (
                  !annotations.length &&
                  currentAnnotation &&
                  currentAnnotation.uuid
                ) {
                  annotations = item.annotations
                    .filter(
                      (annotation) => annotation.uuid === currentAnnotation.uuid
                    )
                    .map((annotation) => ({
                      uuid: annotation.uuid,
                      state: this.STATE_COMPLETE,
                      valid: !currentAnnotation.reject,
                      reject: currentAnnotation.reject,
                    }));
                }
                item.annotations = annotations;
              } else item.annotations = [];
              break;
          }
          return item;
        });
      let updates = await this.updateQueueItems(items);
      if (updates && updates.success && updates.manualEntryAnnotationSets) {
        updates.manualEntryAnnotationSets.forEach(
          ({ manualEntryAnnotationSet }) => {
            if (manualEntryAnnotationSet.state === this.STATE_COMPLETE)
              updatedFields.push(manualEntryAnnotationSet);
          }
        );
      }
    } catch (err) {
      console.log(err);
    }
    this.reset();
    //updatedFields.length && this.updateStats(updatedFields);
  }
  getUserMetricFields() {
    return [
      "cmt",
      "cmts",
      "cmtks",
      "cmtc",
      "cmtcu",
      "cmtcr",
      "cmtca",
      "cmtat",
      "cmtatp",
      "fcmt",
      "fcmts",
      "fcmtks",
      "fcmtc",
      "fcmtcu",
      "fcmtcr",
      "fcmtca",
      "fcmtfu",
      "fcmtfr",
      "fcmtfa",
      "fcmtat",
      "fcmtatp",
      "fmt",
      "fmts",
      "fmtks",
      "fmtfu",
      "fmtfr",
      "fmtfa",
      // "fmtf",
      "fmtat",
      "fmtatp",
      "dsfmt",
      "dsfmts",
      "dsfmtks",
      "dsfmtfu",
      "dsfmtfr",
      "dsfmtfa",
      "dsfmtf",
      "dsfmtat",
      "dsfmtatp",
    ];
  }
  getUserPricingMetricFields() {
    return [
      "cmtc",
      "cmtcu",
      "cmtcr",
      "fcmtc",
      "fcmtcu",
      "fcmtcr",
      "fcmt",
      "fcmtfu",
      "fcmtfr",
      "fmt",
      "fmtfu",
      "fmtfr",
      "dsfmt",
      "dsfmtfu",
      "dsfmtfr",
    ];
  }
  getUserMetricFieldString() {
    let fieldStr = "";
    this.getUserMetricFields().forEach((field) => (fieldStr += `${field}\n`));
    return fieldStr;
  }
  getUserPricingMetricFieldString() {
    let fieldStr = "";
    this.getUserPricingMetricFields().forEach(
      (field) => (fieldStr += `${field}\n`)
    );
    return fieldStr;
  }
  async findUserSessionMetrics(options = {}) {
    let filter = options.filter || {};
    filter.clientUid = { eq: this.dm.userSession.clientUid };
    filter.manualEntryUserUid = { eq: this.dm.userSession.uid };
    options.filter = filter;
    let res = await this.api.qry(
      `
      query FindManualEntryUserSessionMetrics($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ManualEntryUserSessionMetricFilter) {
        manualEntryUserSessionMetrics(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            manualEntryUserSessionUuid
            startAt
            endAt
            paymentTotal
            currency {
              code
              symbol
            }
            baseCurrencyRateToUsd
            currencyRateToUsd
            ${this.getUserMetricFieldString()}
          }
        }
      }`,
      options
    );
    return res.data.manualEntryUserSessionMetrics;
  }
  async fetchUserSession() {
    let qryArgs = { uid: this.dm.userSession.uid };
    let res = await this.api.qry(
      `
      query ManualEntryUserSession {
        manualEntryUserSession {
          uuid
          rates {
            ${this.getUserPricingMetricFieldString()}
          }
          currency {
            code
            symbol
          }
        }
      }`,
      qryArgs
    );
    return res.data && res.data.manualEntryUserSession
      ? res.data.manualEntryUserSession
      : null;
  }
  async confirmUserSession(uuid) {
    let res = await this.api.mutate(
      `
      mutation ConfirmManualEntryUserSession($uuid: String!) {
        confirmManualEntryUserSession(uuid: $uuid){
          uuid
          success
        }
      }`,
      { uuid }
    );
    return res.data &&
      res.data.confirmManualEntryUserSession &&
      res.data.confirmManualEntryUserSession.success
      ? true
      : false;
  }
  formatCurrency(number) {
    // if(number == 0) return "0.00";
    let decimals = 2;
    let ret = Number(
      Math.round(Number(number + "e" + decimals)) + "e" + decimals * -1
    );
    return ret.toFixed(2);
  }
  getUserSessionPricingDisplayInfo(manualEntryUserSession) {
    let metricModeFields = this.getMetricModeFieldInfo(
      manualEntryUserSession.rates
    );
    let perUnits = 1000;
    let displayInfo = [];
    for (let mode in metricModeFields) {
      let modeItem = {
        label: this.getModeLabel(mode),
        key: mode,
        rates: [],
      };
      for (let metricField in metricModeFields[mode]) {
        let metric = metricModeFields[mode][metricField];
        if (!metric.value) continue;
        modeItem.rates.push({
          metricField,
          metric: metric.metric,
          label: `${
            manualEntryUserSession.currency.symbol
          }${this.formatCurrency(metric.value * perUnits)} ${
            manualEntryUserSession.currency.code
          } / ${perUnits} ${this.getMetricSessionLabel(metric.metric)}`,
        });
      }
      modeItem.rates.length && displayInfo.push(modeItem);
    }
    return displayInfo;
  }
  getMetricModeFieldInfo(fields) {
    let metrics = {};
    for (let field in fields) {
      let mode = null;
      let metricTypeAbbr = null;
      if (!this.getUserMetricFields().includes(field)) continue;
      if (field.startsWith("cm")) {
        mode = this.MODE_CHARACTERS;
        metricTypeAbbr = field.substring(2);
      } else if (field.startsWith("fcm")) {
        mode = this.MODE_FIELD_CHARACTERS;
        metricTypeAbbr = field.substring(3);
      } else if (field.startsWith("fm")) {
        mode = this.MODE_FIELD;
        metricTypeAbbr = field.substring(2);
      } else if (field.startsWith("dsfm")) {
        mode = this.MODE_DOCUMENT_SET_FIELDS;
        metricTypeAbbr = field.substring(4);
      }
      if (!mode || !metricTypeAbbr) continue;
      if (!metrics[mode]) metrics[mode] = {};
      let metricType = null;
      switch (metricTypeAbbr) {
        case "ts":
          metricType = this.METRIC_TYPE_SECONDS;
          break;
        case "tks":
          metricType = this.METRIC_TYPE_KEYSTROKES;
          break;
        case "t":
          metricType = this.METRIC_TYPE_ANNOTATION_SETS;
          break;
        case "tc":
          metricType = this.METRIC_TYPE_CHARACTERS;
          break;
        case "tcu":
          metricType = this.METRIC_TYPE_CHARACTER_UPDATES;
          break;
        case "tcr":
          metricType = this.METRIC_TYPE_CHARACTER_REJECTIONS;
          break;
        case "tfu":
          metricType = this.METRIC_TYPE_FIELD_UPDATES;
          break;
        case "tfr":
          metricType = this.METRIC_TYPE_FIELD_REJECTIONS;
          break;
        case "tf":
          metricType = this.METRIC_TYPE_FIELDS;
          break;
      }
      if (!metricType) continue;
      if (
        metricType === this.METRIC_TYPE_ANNOTATION_SETS &&
        [
          this.MODE_FIELD,
          this.MODE_FIELD_CHARACTERS,
          this.MODE_DOCUMENT_SET_FIELDS,
        ].includes(mode)
      )
        metrics[mode][field] = {
          metric: this.METRIC_TYPE_FIELDS,
          value: fields[field],
        };
      else
        metrics[mode][field] = {
          metric: metricType,
          value: fields[field],
        };
    }
    return metrics;
  }

  parseUserMetricFields(fields) {
    // this.MODE_FIELD = "FIELD";
    // this.MODE_FIELD_CHARACTERS = "FIELD_CHARACTERS";
    // this.MODE_DOCUMENT_SET_FIELDS = "FIELD_CHARACTERS";
    // this.METRIC_TYPE_SUBMISSIONS = "SUBMISSIONS";
    // this.METRIC_TYPE_KEYSTROKES = "KEYSTROKES";
    // this.METRIC_TYPE_FIELD_UPDATES = "FIELD_UPDATES";
    // this.METRIC_TYPE_CHARACTER_UPDATES = "CHARACTER_UPDATES";
    // this.METRIC_RATE_MINUTE = "MINUTE";
    let metrics = {
      [this.MODE_CHARACTERS]: {
        [this.METRIC_TYPE_ANNOTATION_SETS]: 0,
        [this.METRIC_TYPE_CHARACTERS]: 0,
        [this.METRIC_TYPE_SECONDS]: 0,
        [this.METRIC_TYPE_KEYSTROKES]: 0,
        [this.METRIC_TYPE_CHARACTER_UPDATES]: 0,
        [this.METRIC_TYPE_CHARACTER_REJECTIONS]: 0,
      },
      [this.MODE_FIELD_CHARACTERS]: {
        [this.METRIC_TYPE_ANNOTATION_SETS]: 0,
        [this.METRIC_TYPE_CHARACTERS]: 0,
        [this.METRIC_TYPE_FIELDS]: 0,
        [this.METRIC_TYPE_KEYSTROKES]: 0,
        [this.METRIC_TYPE_SECONDS]: 0,
        [this.METRIC_TYPE_CHARACTER_UPDATES]: 0,
        [this.METRIC_TYPE_CHARACTER_REJECTIONS]: 0,
        [this.METRIC_TYPE_FIELD_UPDATES]: 0,
        [this.METRIC_TYPE_FIELD_REJECTIONS]: 0,
      },
      [this.MODE_FIELD]: {
        [this.METRIC_TYPE_ANNOTATION_SETS]: 0,
        [this.METRIC_TYPE_FIELDS]: 0,
        [this.METRIC_TYPE_KEYSTROKES]: 0,
        [this.METRIC_TYPE_SECONDS]: 0,
        [this.METRIC_TYPE_FIELD_UPDATES]: 0,
        [this.METRIC_TYPE_FIELD_REJECTIONS]: 0,
      },
      [this.MODE_DOCUMENT_SET_FIELDS]: {
        [this.METRIC_TYPE_ANNOTATION_SETS]: 0,
        [this.METRIC_TYPE_FIELDS]: 0,
        [this.METRIC_TYPE_KEYSTROKES]: 0,
        [this.METRIC_TYPE_SECONDS]: 0,
        [this.METRIC_TYPE_FIELD_UPDATES]: 0,
        [this.METRIC_TYPE_FIELD_REJECTIONS]: 0,
      },
    };
    for (let field in fields) {
      let mode = null;
      let metricTypeAbbr = null;
      if (!this.getUserMetricFields().includes(field)) continue;
      if (field.startsWith("cm")) {
        mode = this.MODE_CHARACTERS;
        metricTypeAbbr = field.substring(2);
      } else if (field.startsWith("fcm")) {
        mode = this.MODE_FIELD_CHARACTERS;
        metricTypeAbbr = field.substring(3);
      } else if (field.startsWith("fm")) {
        mode = this.MODE_FIELD;
        metricTypeAbbr = field.substring(2);
      } else if (field.startsWith("dsfm")) {
        mode = this.MODE_DOCUMENT_SET_FIELDS;
        metricTypeAbbr = field.substring(4);
      }
      if (!mode || !metricTypeAbbr) continue;
      let metricType = null;
      switch (metricTypeAbbr) {
        case "ts":
          metricType = this.METRIC_TYPE_SECONDS;
          break;
        case "tks":
          metricType = this.METRIC_TYPE_KEYSTROKES;
          break;
        case "t":
          metricType = this.METRIC_TYPE_ANNOTATION_SETS;
          break;
        case "tc":
          metricType = this.METRIC_TYPE_CHARACTERS;
          break;
        case "tcu":
          metricType = this.METRIC_TYPE_CHARACTER_UPDATES;
          break;
        case "tcr":
          metricType = this.METRIC_TYPE_CHARACTER_REJECTIONS;
          break;
        case "tfu":
          metricType = this.METRIC_TYPE_FIELD_UPDATES;
          break;
        case "tfr":
          metricType = this.METRIC_TYPE_FIELD_REJECTIONS;
          break;
        case "tf":
          metricType = this.METRIC_TYPE_FIELDS;
          break;
      }
      if (!metricType) continue;
      metrics[mode][metricType] = fields[field];
      if (
        metricType === this.METRIC_TYPE_ANNOTATION_SETS &&
        [this.MODE_FIELD, this.MODE_FIELD_CHARACTERS].includes(mode)
      )
        metrics[mode][this.METRIC_TYPE_FIELDS] = fields[field];
    }
    return metrics;
  }
  // updateStats(updatedFields) {
  //   if (!updatedFields || !updatedFields.length) return false;
  //   // let stats = this.state.stats;
  //   updatedFields.forEach((manualEntryAnnotationSet) => {
  //     if (manualEntryAnnotationSet.state !== this.STATE_COMPLETE) return;
  //     stats.fields++;
  //     manualEntryAnnotationSet.reject && stats.reject++;
  //     manualEntryAnnotationSet.valid && stats.valid++;
  //     stats.seconds += parseInt(manualEntryAnnotationSet.totalSeconds);
  //   });
  //   this.setState({ stats: stats });
  // }
  timeout() {
    this.reset();
  }
  get isMaxQueueLength() {
    return this._maxQueueLength === this._queue.length;
  }
  get isQueueProcessed() {
    return this._queueProcessed;
    // if (!this.isMaxQueueLength) return false;
    // return this.nextUnprocessedQueueItemIdx ? false : true;
  }
  get queueExists() {
    return this._queue.length ? true : false;
  }
  get nextUnprocessedQueueItemIdx() {
    let ret = null;
    for (let idx in this._queue) {
      if (!this.isQueueItemProcessed(idx)) {
        ret = parseInt(idx);
        break;
      }
    }
    return ret;
  }
  get maxQueueLength() {
    return this._maxQueueLength;
  }
  get hasNextQueueItem() {
    return this._queue.length > this._queueIdx + 1;
  }
  get nextQueueItemIdx() {
    return this.hasNextQueueItem ? this._queueIdx + 1 : null;
  }
  get hasPreviousQueueItem() {
    return this._queueIdx > 0;
  }
  get previousQueueItemIdx() {
    return this.hasPreviousQueueItem ? this._queueIdx - 1 : null;
  }
  get userSession() {
    return this._userSession || null;
  }
  get shouldConfirmUserSession() {
    return (
      !this.userSession ||
      (this.userSession && this.userSession.confirmed !== true)
    );
  }
  queueItemExists(idx) {
    return this._queue[idx] ? true : false;
  }
  currQueueItem() {
    return this.queueItem(this._queueIdx);
  }
  queueItem(idx = null) {
    if (idx === null) idx = this._queueIdx;
    if (!this.queueItemExists(idx)) return null;
    return this._queue[idx] || null;
  }
  registerKeystroke(key) {
    if (!this.queueItemExists(this._queueIdx)) return false;
    let shouldRegisterKeystroke = true;
    switch (key) {
      case "Shift":
        shouldRegisterKeystroke = false;
        break;
      case "Control":
        shouldRegisterKeystroke = false;
        break;
      default:
    }
    if (!shouldRegisterKeystroke) return false;

    if (!this._queue[this._queueIdx].totalKeystrokes)
      this._queue[this._queueIdx].totalKeystrokes = 0;
    this._queue[this._queueIdx].totalKeystrokes++;
  }
  get queueIdx() {
    return this._queueIdx;
  }
  get queueLength() {
    return parseInt(this._queue.length);
  }
  get queue() {
    return this._queue;
  }
  get mode() {
    return this._mode;
  }
  get ndaAcceptRequired() {
    return this._ndaAcceptRequired;
  }
  get ndaAccepted() {
    return this._ndaAccepted;
  }
  // get documentSet() {
  //   return this.fetchDocumentSet();
  // }
  get documentSetEditable() {
    return this._documentSetEditable;
  }
  get documentSetUid() {
    return this._manualEntryDocumentSet
      ? this._manualEntryDocumentSet.uid
      : null;
  }
  isQueueItemProcessed(idx) {
    if (!this._queue[idx]) return false;
    return this._queue[idx].state === this.STATE_REVIEW;
  }
  setQueueItemIdx(idx) {
    if (!this._queue[idx]) return false;
    this._queueIdx = idx;
  }
  incrementQueueIdx() {
    this._queueIdx++;
  }
  calculateTotalSeconds(startTime) {
    if (!startTime) throw new Error("Error: Time mismatch (ETM2).");
    let currTime = Date.now();
    // if(! item.totalSeconds) item.totalSeconds = 0;
    let totalSeconds = Math.round((currTime - startTime) / 1000);
    //|| item.totalSeconds > timeoutSeconds
    if (totalSeconds < 0) throw new Error("Error: Time mismatch (ETM1).");
    return totalSeconds;
  }
  compareAnnotationValues(values = [], updatedValues = []) {
    if (values === updatedValues) return true;
    if (!values || !updatedValues) return false;
    if (values.length != updatedValues.length) return false;
    return !values.some(
      (value, idx) =>
        (value.selectionUuid &&
          value.selectionUuid !== updatedValues[idx].selectionUuid) ||
        (!value.selectionUuid && value.value !== updatedValues[idx].value) ||
        !this.utils.array.compare(
          value.questionAnnotationUuidPath,
          updatedValues[idx].questionAnnotationUuidPath
        )
    );
  }
  compareAnnotationOffsets(offset = {}, updatedOffset = {}) {
    if (offset === updatedOffset) return true;
    if (!offset || !updatedOffset) return false;
    return this.utils.object.compare(offset, updatedOffset);
  }
  async updateQueueItem(values, options) {
    let currQueueItem = this.currQueueItem();
    if (!currQueueItem) return false;
    let item = { ...currQueueItem };
    item.state = this.STATE_REVIEW;
    if (values.reject) {
      item.reject = true;
      item.valid = false;
    } else {
      item.reject = false;
      item.valid = true;
    }
    let updatedAnnotations = [];
    switch (item.mode) {
      case this.MODE_FIELD_CHARACTERS:
      case this.MODE_CHARACTERS:
      case this.MODE_FIELD:
        let annotationValueUidMap = {};
        // let t = values.annotations.filter((annotation) => annotation.uuid);
        values.annotations
          .filter((annotation) => (annotation.uuid ? true : false))
          .forEach(
            (updatedAnnotation) =>
              (annotationValueUidMap[updatedAnnotation.uuid] =
                updatedAnnotation)
          );
        item.annotations = item.annotations.map((annotation) => {
          if (annotationValueUidMap[annotation.uuid]) {
            let updatedAnnotation = annotationValueUidMap[annotation.uuid];
            if ("updatedValue" in updatedAnnotation)
              annotation.updatedValue = updatedAnnotation.updatedValue;
            if ("reject" in updatedAnnotation)
              annotation.reject = updatedAnnotation.reject;

            updatedAnnotations.push(annotation);
          }
          return annotation;
        });
        break;
      case this.MODE_DOCUMENT_SET_FIELDS:
        // Find the annotatation and update the value
        item.state = this.STATE_QUEUED;
        item.valid = false;

        if (options.annotation) item.annotations = [options.annotation];

        let annotation = item.annotations.find(
          (annotation) => annotation.uuid === values.uuid
        );

        if (!annotation)
          throw new Error("Unable to update annotation. Annotation not found.");

        // Create the annotation update
        let annotationUpdate = {
          uuid: annotation.uuid,
          reject: values.reject === true,
          valid: values.reject !== true,
          state: this.STATE_REVIEW,
        };
        if (
          values.values &&
          (!annotation.values ||
            !this.utils.array.compare(annotation.values, values.values))
        ) {
          annotationUpdate.updatedValues = values.values;
        } else if (annotation.value !== values.value) {
          annotationUpdate.updatedValue = values.value;
        }

        if (
          values.offset &&
          (!annotation.offset ||
            !this.utils.object.compare(annotation.offset, values.offset))
        ) {
          annotationUpdate.updatedOffset = values.offset;
        }
        annotation = { ...annotation, ...annotationUpdate };
        updatedAnnotations.push(annotationUpdate);

        // Update the item annotations
        for (let i in item.annotations) {
          if (item.annotations[i].uuid === annotation.uuid) {
            item.annotations[i] = annotation;
            break;
          }
        }
        const hasQueued = item.annotations.some(
          (annotation) =>
            annotation.type === this.ANNOTATION_TYPE_FIELD &&
            annotation.state === this.STATE_QUEUED
        );
        // if (!hasQueued) item.state = this.STATE_REVIEW;
        break;
      default:
        throw new Error("Unknown edit mode.");
    }

    // Reset keystrokes and total seconds and update.
    item.totalSeconds = this.calculateTotalSeconds(values.startTime);
    // if (values.startTime) {
    //   let currTime = Date.now();
    //   // if(! item.totalSeconds) item.totalSeconds = 0;
    //   item.totalSeconds = Math.round((currTime - values.startTime) / 1000);
    //   //|| item.totalSeconds > timeoutSeconds
    //   if (item.totalSeconds < 0)
    //     throw new Error("Error: Time mismatch (ETM1).");
    // } else throw new Error("Error: Time mismatch (ETM2).");
    let updates = await this.updateQueueItems([
      { ...item, annotations: updatedAnnotations },
    ]);
    // Update the timeoutAt
    let updateSuccess = false;
    if (
      updates &&
      updates.success &&
      updates.manualEntryAnnotationSets &&
      updates.manualEntryAnnotationSets.length &&
      updates.manualEntryAnnotationSets[0].manualEntryAnnotationSet
    ) {
      // Update Timeout
      if (
        updates.manualEntryAnnotationSets[0].manualEntryAnnotationSet
          .timeoutSeconds &&
        !isNaN(
          updates.manualEntryAnnotationSets[0].manualEntryAnnotationSet
            .timeoutSeconds
        ) &&
        updates.manualEntryAnnotationSets[0].manualEntryAnnotationSet
          .timeoutSeconds > 0
      ) {
        let timeoutAt = new Date();
        timeoutAt.setSeconds(
          timeoutAt.getSeconds() +
            updates.manualEntryAnnotationSets[0].manualEntryAnnotationSet
              .timeoutSeconds
        );
        item.timeoutAt = timeoutAt;
      }
      // Update annotation uids
      let annotationUpdateUuidMap = {};
      updates.manualEntryAnnotationSets[0].manualEntryAnnotationSet
        .annotations &&
        updates.manualEntryAnnotationSets[0].manualEntryAnnotationSet.annotations.forEach(
          (annotation) =>
            (annotationUpdateUuidMap[annotation.uuid] = annotation)
        );
      item.annotations = item.annotations.map((annotation) => {
        if (
          annotationUpdateUuidMap[annotation.uuid] &&
          annotationUpdateUuidMap[annotation.uuid].uid !== annotation.uid
        )
          annotation.uid = annotationUpdateUuidMap[annotation.uuid].uid;
        return annotation;
      });
      updateSuccess = true;
      item.totalKeystrokes = 0;
    }
    // Update the annotation uids
    this._queue[this._queueIdx] = item;
    return item;
  }
  async loadQueueItem(options = {}) {
    await this.fetchQueueItem(options);
  }
  async fetchQueueItem(options = {}) {
    if (options.next) {
      if (this._queueIdx + 1 >= this._maxQueueLength) return null;
      this._queueIdx++;
    } else if (options.previous) {
      if (this._queueIdx === 0) return null;
      this._queueIdx--;
    } else if ("idx" in options) {
      if (!this._queue[options.idx]) return null;
      this._queueIdx = options.idx;
    } else if (options.unprocessed) {
      // Look for the next unprocessed item
      // If it exists, go to it
      // if not, go the the next empty idx
      let idx = this.nextUnprocessedQueueItemIdx;
      if (idx !== null) this._queueIdx = idx;
      else if (this.isMaxQueueLength) return null;
      else this._queueIdx = this.queueLength;
    }

    if (this._queue[this._queueIdx]) return this._queue[this._queueIdx];
    else if (this._queueIdx !== this.queueLength)
      throw new Error("Error: Item Queue idx mismatch");
    let item = null;
    if (!item) return false;
    // If it is an array, join together
    // if (Array.isArray(item.value))
    //   console.log("ITEM VALUE IS ARRAY", item.value);
    if (Array.isArray(item.value)) item.value = item.value.join(" ");
    this._queue.push(item);
    return item;
  }
  getLabel(item, idx) {
    let label = null;
    switch (item.mode) {
      case this.MODE_CHARACTERS:
        label = `Character Set ${idx + 1}`;
        break;
      case this.MODE_FIELD_CHARACTERS:
      case this.MODE_FIELD:
        let field = item.annotations
          ? item.annotations
              .filter(
                (annotation) => annotation.type === this.ANNOTATION_TYPE_FIELD
              )
              .at(0)
          : null;
        let fieldLabel = field.label || field.name;
        if (fieldLabel) {
          let words = fieldLabel.split(/ |_|-/);
          label = words
            .map((w) => this.utils.str.capitalize(w.toLowerCase()))
            .join(" ");
        }
        break;
      default:
        throw new Error("Unknown character set mode.");
    }
    return label;
  }
  getModeLabel(mode) {
    let label = null;
    switch (mode) {
      case this.MODE_CHARACTERS:
        label = `Characters`;
        break;
      case this.MODE_FIELD_CHARACTERS:
        label = `Field Characters`;
        break;
      case this.MODE_FIELD:
        label = `Fields`;
        break;
      case this.MODE_DOCUMENT_SET_FIELDS:
        label = `Document Fields`;
        break;
      default:
        throw new Error("Unknown character set mode.");
    }
    return label;
  }
  getMetricLabel(mode, type) {
    let ret = null;
    switch (type) {
      case this.METRIC_TYPE_SUBMISSIONS:
        switch (mode) {
          case this.MODE_CHARACTERS:
            ret = "Characters";
            break;
          case this.MODE_FIELD_CHARACTERS:
          case this.MODE_FIELD:
            ret = "Fields";
            break;
        }
        break;
      case this.METRIC_TYPE_KEYSTROKES:
        ret = "Keystrokes";
        break;
      case this.METRIC_TYPE_CHARACTER_UPDATES:
        ret = "Changes";
        break;
      case this.METRIC_TYPE_FIELD_UPDATES:
        ret = "Changes";
        break;
      case this.METRIC_TYPE_ACCURACY:
        ret = "Accuracy";
        break;
    }
    return ret;
  }
  getMetricSessionLabel(type) {
    let ret = null;
    switch (type) {
      case this.METRIC_TYPE_ANNOTATION_SETS:
        ret = "Character Sets";
        break;
      case this.METRIC_TYPE_CHARACTERS:
        ret = "Characters";
        // switch (mode) {
        //   case this.MODE_CHARACTERS:
        //     ret = "Characters";
        //     break;
        //   case this.MODE_FIELD_CHARACTERS:
        //   case this.MODE_FIELD:
        //     ret = "Fields";
        //     break;
        // }
        break;
      case this.METRIC_TYPE_FIELDS:
        ret = "Fields";
        break;
      case this.METRIC_TYPE_KEYSTROKES:
        ret = "Keystrokes";
        break;
      case this.METRIC_TYPE_CHARACTER_UPDATES:
        ret = "Character Updates";
        break;
      case this.METRIC_TYPE_CHARACTER_REJECTIONS:
        ret = "Character Rejections";
        break;
      case this.METRIC_TYPE_FIELD_UPDATES:
        ret = "Field Updates";
        break;
      case this.METRIC_TYPE_FIELD_REJECTIONS:
        ret = "Field Rejections";
        break;
      case this.METRIC_TYPE_ACCURACY:
        ret = "Accuracy";
        break;
    }
    return ret;
  }
  getMetricAggregateTypeLabel(aggregateType, options = {}) {
    let ret = null;
    let rate = options.rate || null;
    if (!aggregateType) aggregateType = this.METRIC_AGGREGATE_TYPE_AVERAGE_RATE;
    switch (aggregateType) {
      case this.METRIC_AGGREGATE_TYPE_AVERAGE_RATE:
        switch (rate) {
          case this.METRIC_RATE_MINUTE:
            ret = "/min";
            break;
        }
        break;
      case this.METRIC_AGGREGATE_TYPE_PERCENTAGE:
        ret = "%";
        break;
    }
    return ret;
  }
}
