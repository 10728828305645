import React from "react";
import PropTypes from "prop-types";
import R14 from "../R14";
import FlatList from "./FlatList";
import View from "./View";
import Text from "./Text";
import Button from "./Button";
import TextInput from "./TextInput";
import IconButton from "./IconButton";
import Touchable from "./Touchable";
import PopUpMenu from "./PopUpMenu";
import Icon from "./Icon";
import StyleSheet from "./StyleSheet";
import Checkbox from "./Checkbox";
import ControlsView from "./ControlsView";
import ActivityIndicator from "./ActivityIndicator";
import SelectMenu from "./SelectMenu";
import AnimatedView from "./AnimatedView";
import FadeView from "./FadeView";
import Form from "./Form";
import FieldGroup from "./FieldGroup";
import { Colors } from "./Theme";
import PopUpMenuItem from "./PopUpMenuItem";
import Dialog from "./Dialog";

/**
 *
 * Paginated DataTable
 *
 */
export default R14.connect(
  class DataTable extends React.Component {
    static propTypes = {
      /** The unique name for the data table  */
      name: PropTypes.string.isRequired,
      /** An key / value object, the columns name (key) containing options (label, sortable, cellStyle, colapse(width, height)).  */
      columns: PropTypes.object.isRequired,
      /** Requests items by page / rowsPerPage / filters, function should can return props (page, rowsPerPage, pageData, totalRows, filters)  */
      initializer: PropTypes.func.isRequired,
      /** Requests items by page / rowsPerPage  */
      pageLoader: PropTypes.func.isRequired,
      /** Renders an column cell, passes the column key (columnName), row items (row), and column item */
      cellRenderer: PropTypes.func,
      /** Renders below the current row that spans entire width. */
      rowBottom: PropTypes.func,
      /** The total number of rows. Required for showing paginated buttons */
      initialTotalRows: PropTypes.number,
      /** The interval in number of seconds that the data table should refresh */
      autoRefresh: PropTypes.number,
      /** A key to be used to render a result row. If not given, will default to index or row.key */
      rowKeyExtractor: PropTypes.func,
      // /**
      //  * Callback for when a row is selected or unselected. If a boolean is returned, false will not select the row. If the row is returned it will be re-rendered.
      //  *
      //  * @param {Object} row The selected or unselected row
      //  * @param {Boolean} isSelected Value indicating if the row is selected or not
      //  * @param {Object} dataTable The dataTable object
      //  *
      //  * */
      // onRowSelect: PropTypes.func,
      /**
       * Callback for when a multiple rows are selected or unselected. If a boolean is returned, false will not select the row. If an array of rows are returned they will be re-rendered.
       *
       * @param {Object[]} rows The selected or unselected row
       * @param {Boolean} isSelected Value indicating if the rows are selected or not
       * @param {Object} dataTable The dataTable object
       *
       * */
      onSelection: PropTypes.func,
      /** The number of results to display per page.  */
      initialRowsPerPage: PropTypes.number,
      /** Title for the DataTable  */
      headerTitle: PropTypes.string,
      /** Controls such as buttons, icon buttons, etc... Placed at the right of the header title.*/
      headerControlsRight: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.func,
      ]),
      /** Adds selectable rows with checkboxes.*/
      checkboxes: PropTypes.bool,
      /** Array of row keys that should be selected */
      initialSelectedRows: PropTypes.array,
      /** Calls the given function with row data (row) as a param, should return controls such as buttons, icon buttons. Placed at the right of each row.*/
      rowControlsRight: PropTypes.func,
      /** Calls the given function with row data (row) as a param when a row is pressed.*/
      onRowPress: PropTypes.func,
      /** The initial page to display.  */
      initialPage: PropTypes.number,
      /** The initial column to sort by.  */
      initialSortColumnName: PropTypes.string,
      /** The direction to sort by */
      initialSortDirection: PropTypes.oneOf(["asc", "desc"]),
      /** Enables search filtering and adds a search input to the header.*/
      searchable: PropTypes.bool,
      /** Enables collapsable rows and adds a collapsable menu to the header.*/
      collapsable: PropTypes.bool,
      /** Mode to display the data table.*/
      displayMode: PropTypes.oneOf(["dataTable", "cardGrid"]),
    };
    static defaultProps = {
      initialPage: 1,
      initialRowsPerPage: 10,
      initialTotalRows: 0,
      initialSortDirection: "asc",
      initialSelectedRows: [],
      headerControlsRight: [],
      displayMode: "dataTable",
    };
    constructor(props) {
      super(props);
      this.dataTable = this.props.app.ui.dataTable.create(props, this);
      this.keyExtractor = this.keyExtractor.bind(this);
      this.renderRow = this.renderRow.bind(this);
      this.loadNextPage = this.loadNextPage.bind(this);
      this.loadPreviousPage = this.loadPreviousPage.bind(this);
      this.handleRowsPerPageValueChange =
        this.handleRowsPerPageValueChange.bind(this);
      this.handleRowCheckboxValueChange =
        this.handleRowCheckboxValueChange.bind(this);
      this.handleSelectAllCheckboxValueChange =
        this.handleSelectAllCheckboxValueChange.bind(this);
      this.handleLayout = this.handleLayout.bind(this);
      this.handleHeaderSortMenuClose =
        this.handleHeaderSortMenuClose.bind(this);
      this.handleHeaderFilterMenuClose =
        this.handleHeaderFilterMenuClose.bind(this);
      this.handleHeaderFiltersReset = this.handleHeaderFiltersReset.bind(this);
      this.handleFilterDialogClosePress =
        this.handleFilterDialogClosePress.bind(this);
      this.handleFilterDialogOkPress =
        this.handleFilterDialogOkPress.bind(this);
      this.handleFilterDialogControlPress =
        this.handleFilterDialogControlPress.bind(this);
      this.handleFilterDialogResetPress =
        this.handleFilterDialogResetPress.bind(this);

      this._flatListRef = React.createRef();
      this._widthStyles = {};
      this._rowControlsRightWidthStyle = null;
      this.state = {
        visibleColumnCount: 0,
      };
      this._headerSortMenuRef = React.createRef();
      this._headerFilterMenuRef = React.createRef();
    }

    componentDidMount() {
      this.dataTable.load();
    }

    componentWillUnmount() {
      this.dataTable.remove();
    }

    componentDidUpdate(prevProps, prevState) {}

    handleLayout(event) {
      //let screenWidth = this.props.app.ui.viewport.width;
      // console.log(this.props.app.ui.viewport.width);
      let visibleColumnCount = 0;
      for (let colName in this.dataTable.columns) {
        if (this.dataTable.columns[colName].collapse) {
          this.dataTable.columns[colName].collapsed = this.dataTable.columns[
            colName
          ].collapse({
            height: event.nativeEvent.layout.height,
            width: event.nativeEvent.layout.width,
          });
          if (!this.dataTable.columns[colName].collapsed) visibleColumnCount++;
        }
      }
      if (this.state.visibleColumnCount !== visibleColumnCount)
        this.setState({
          visibleColumnCount: visibleColumnCount,
        });
    }

    widthStyle(colName) {
      if (
        !this._widthStyles[colName] &&
        this.dataTable.columns[colName].width
      ) {
        let style = StyleSheet.create({
          width: {
            flexBasis: this.dataTable.columns[colName].width,
            flexGrow: 0,
            flexShrink: 0,
          },
        });
        this._widthStyles[colName] = style.width;
      }
      return this._widthStyles[colName] || null;
    }
    get rowControlsRightWidthStyle() {
      if (
        (!this._rowControlsRightWidthStyle, this.props.rowControlsRightWidth)
      ) {
        let style = StyleSheet.create({
          width: {
            flexBasis: this.props.rowControlsRightWidth,
            flexGrow: 0,
            flexShrink: 0,
          },
        });
        this._rowControlsRightWidthStyle = style.width;
      }
      return this._rowControlsRightWidthStyle || null;
    }

    handleRowsPerPageValueChange({ value }) {
      this.dataTable.load({ rowsPerPage: value });
    }

    handleHeaderSortMenuClose() {
      this._headerSortMenuRef.current &&
        this._headerSortMenuRef.current.close();
    }
    handleHeaderFilterMenuClose() {
      this._headerFilterMenuRef.current &&
        this._headerFilterMenuRef.current.close();
    }
    handleHeaderFiltersReset() {
      this.dataTable.resetFilters();
      this.handleHeaderFilterMenuClose();
    }

    handleFilterDialogClosePress() {
      this.dataTable.hideFilters();
    }
    handleFilterDialogOkPress() {
      this.dataTable.submitFilters(
        `dataTable${this.props.name}DialogFilterForm`
      );
      this.dataTable.hideFilters();
    }
    handleFilterDialogControlPress() {
      this.dataTable.showFilters();
    }
    handleFilterDialogResetPress() {
      if (window.confirm("Are you sure?")) {
        this.dataTable.resetFilters();
        this.dataTable.hideFilters();
      }
    }

    async handleRowCheckboxValueChange(key, value) {
      return await this.dataTable.handleRowCheckboxValueChange(key, value);
    }

    async handleSelectAllCheckboxValueChange(value) {
      return await this.dataTable.handleSelectAllCheckboxValueChange(value);
    }

    keyExtractor(row, index) {
      return this.dataTable.keyExtractor(row);
    }

    async loadNextPage() {
      return await this.dataTable.load(this.dataTable.page + 1);
    }
    async loadPreviousPage() {
      return await this.dataTable.load(this.dataTable.page - 1);
    }

    scrollToOffset(params) {
      this._flatListRef.current.scrollToOffset({
        offset: params.offset,
        animated: params.animated === false ? false : true,
      });
    }
    renderRow({ item, index }) {
      let row = item;
      let colArr = [];
      let rowKey = this.dataTable.keyExtractor(row);
      let isSelected = this.dataTable.isSelectedRow(row);
      if (this.props.checkboxes) {
        colArr.push(
          <View style={styles.checkboxCell} key="rowCheckbox">
            <Checkbox
              value={isSelected}
              onValueChange={(value) => {
                this.handleRowCheckboxValueChange(rowKey, value);
              }}
            />
          </View>
        );
      }
      for (let colName in this.dataTable.columns) {
        let col = this.dataTable.columns[colName];
        if (!this.dataTable.isColumnVisible(colName)) continue;

        let rowColKey = `${colName}-${rowKey}`;
        let touchableProps = {
          style: [styles.cellTouchable],
        };
        let cellProps = {
          style: [
            styles.cell,
            col.align === "right" && styles.cellRight,
            col.cellStyle,
            this.widthStyle(colName),
          ],
          key: rowColKey,
        };
        let TouchableComponent = null;

        // If onRowPress is set, use a touchable container
        if (this.props.onRowPress) {
          TouchableComponent = Touchable;
          touchableProps.onPress = () => {
            this.props.onRowPress(row, this.tableData);
          };
        }
        let renderFn = col.renderer || this.props.cellRenderer || null;
        let renderedCell = renderFn ? (
          renderFn({
            cell: row[colName] || null,
            columnName: colName,
            row: row,
            columns: this.dataTable.columns,
          })
        ) : (
          <Text>{row[colName] || null}</Text>
        );
        //return <Text>{cell}</Text>;
        colArr.push(
          <View {...cellProps}>
            {/* <View
              style={[
                styles.cell,
                col.align === "right" && styles.cellRight,
                col.cellStyle,
                this.widthStyle(colName),
              ]}
            > */}
            {renderedCell}
            {TouchableComponent && (
              <TouchableComponent {...touchableProps}>
                <View style={styles.cellTouchableView}></View>
              </TouchableComponent>
            )}
            {/* </View> */}
          </View>
        );
      }
      if (this.props.rowControlsRight) {
        let rowControlsRight = this.props.rowControlsRight;
        if (typeof rowControlsRight === "function") {
          rowControlsRight = rowControlsRight(row, this.dataTable);
        }
        colArr.push(
          <View
            style={[
              styles.cell,
              styles.rowControlsRightCell,
              this.rowControlsRightWidthStyle,
            ]}
            key="rowControlsRight"
          >
            <View style={styles.rowControlsRight}>{rowControlsRight}</View>
          </View>
        );
      }
      let rowBottom = this.props.rowBottom
        ? this.props.rowBottom({
            row: row,
            columns: this.dataTable.columns,
          })
        : null;

      if (rowBottom) {
        return (
          <View style={[styles.row, isSelected && styles.rowSelected]}>
            <View style={[styles.rowCells]}>{colArr}</View>
            <View style={[styles.rowBottom]}>{rowBottom}</View>
          </View>
        );
      } else
        return (
          <View
            style={[
              styles.row,
              styles.rowCells,
              isSelected && styles.rowSelected,
            ]}
          >
            {colArr}
          </View>
        );
    }
    renderListEmptyComponent() {
      /** @todo move state.hasLoaded into the main render function because it is so important for rendering */
      if (
        !this.dataTable.state.hasLoaded &&
        this.dataTable.state.showActivityIndicator
      ) {
        return (
          <ActivityIndicator
            size="large"
            containerStyle={styles.activityIndicator}
          />
        );
      } else if (this.props.TableEmptyComponent) {
        return this.props.TableEmptyComponent;
      } else
        return (
          <Text key="noResults" style={styles.emptyComponent}>
            No Results
          </Text>
        );
    }
    renderHeader() {
      let colArr = [];
      if (this.props.checkboxes) {
        colArr.push(
          <View style={styles.checkboxCell} key="rowCheckbox">
            <Checkbox
              value={this.dataTable.hasSelectedAllRows()}
              onValueChange={this.handleSelectAllCheckboxValueChange}
              iconBlank="checkboxMultipleBlank"
              iconMarked="checkboxMultipleMarked"
            />
          </View>
        );
      }

      let shouldRenderSortMenu = false;
      let colIdx = 0;
      let firstVisibleColIdx = null;
      let hasFilterForm = false;
      for (let colName in this.dataTable.columns) {
        colIdx++;
        let widthStyle = null;
        let col = this.dataTable.columns[colName];
        if (!this.dataTable.isColumnVisible(colName)) continue;
        if (!firstVisibleColIdx) firstVisibleColIdx = colIdx;
        let cellContent = null;
        let cellLabel = null;
        if (col.FilterField) hasFilterForm = true;
        let isSortable = col.sortable ? true : false;
        if (isSortable) shouldRenderSortMenu = true;
        if (col.width) {
          widthStyle = StyleSheet;
        }
        let isSortActive =
          isSortable && colName === this.dataTable.state.sortColumnName;
        if (col.label) {
          cellLabel = (
            <Text
              style={[
                styles.headerColsCellText,
                isSortActive && styles.headerColsCellTextActive,
              ]}
            >
              {col.label}
            </Text>
          );
        }
        // If sortable, create a touchable label
        // if (col.filter && this.dataTable.state.showFilters){
        //   cellContent = this.renderFilter(colName);
        // }
        // else
        if (col.sortable) {
          let sortIcon = null;

          // if (colName === this.dataTable.sortColumnName) {
          let sortIconColor = StyleSheet.color(Colors.onSurface).rgba(0.6);
          let iconStyles = [styles.sortIcon];

          if (colIdx === firstVisibleColIdx && col.align !== "right") {
            iconStyles.push(styles.sortIconRight);
          } else {
            iconStyles.push(styles.sortIconLeft);
          }

          // sortIcon =
          //   this.dataTable.sortDirection === "asc" ? (
          //     <Icon
          //       color={sortIconColor}
          //       style={iconStyles}
          //       size='small'
          //       name='arrowUp'
          //     />
          //   ) : (
          //     <Icon
          //       color={sortIconColor}
          //       style={iconStyles}
          //       size='small'
          //       name='arrowDown'
          //     />
          //   );

          sortIcon = (
            <FadeView visible={colName === this.dataTable.sortColumnName}>
              <AnimatedView
                key={colName}
                in={this.dataTable.sortDirection === "desc"}
                // delay={100}
                enter={{
                  transform: [{ rotate: "180deg" }],
                }}
                exit={{
                  transform: [{ rotate: "0deg" }],
                }}
                style={iconStyles}
              >
                <Icon color={sortIconColor} size="small" name="arrowUp" />
              </AnimatedView>
            </FadeView>
          );
          // }
          cellContent = (
            <Touchable
              onPress={() => {
                this.dataTable.setSort(colName);
              }}
            >
              <View
                style={[
                  styles.headerColsCellTouchable,
                  this.widthStyle(colName),
                ]}
              >
                {colIdx > firstVisibleColIdx && sortIcon}
                {cellLabel}
                {colIdx === firstVisibleColIdx && sortIcon}
              </View>
            </Touchable>
          );
        } else cellContent = cellLabel;
        colArr.push(
          <View
            style={[
              styles.headerColsCell,
              col.align === "right" && styles.headerColsCellRight,
              this.widthStyle(colName),
            ]}
            key={`${colName}ColHeader`}
          >
            {cellContent}
            {col.FilterField || null}
          </View>
        );
      }
      // let headerControlsRight = [];
      // if (this.props.headerControlsRight) {
      //   let propHeaderControls =
      //     typeof headerControlsRight === "function"
      //       ? this.props.headerControlsRight(this.dataTable)
      //       : this.props.headerControlsRight;
      //     if(Array.isArray(propHeaderControlsRight)) headerControlsRight = [...propHeaderControls];
      // }
      let headerControlsRight = this.props.headerControlsRight;
      if (typeof headerControlsRight === "function") {
        headerControlsRight = headerControlsRight(this.dataTable);
      }

      if (this.props.rowControlsRight) {
        colArr.push(
          <View
            style={[styles.headerColsCell, this.rowControlsRightWidthStyle]}
            key={`rowControlsRightColHeader`}
          />
        );
      }

      if (!Array.isArray(headerControlsRight))
        headerControlsRight = [headerControlsRight];
      else headerControlsRight = [...headerControlsRight];
      // if (
      //   !Array.isArray(headerControlsRight) &&
      //   (this.props.searchable || shouldRenderSortMenu)
      // )
      //   headerControlsRight = [...headerControlsRight];

      if (shouldRenderSortMenu)
        headerControlsRight.unshift(this.renderHeaderSortMenu());
      if (this.props.filterFields || this.props.headerFilterFields)
        headerControlsRight.unshift(this.renderFilterDialogControl());
      // headerControlsRight.unshift(this.renderHeaderFilterMenu());
      if (this.props.collapsable)
        headerControlsRight.unshift(this.renderHeaderCollapsableMenu());
      // headerControlsRight.unshift(
      //   <IconButton
      //     key='filters'
      //     icon='filter'
      //     color={this.dataTable.hasFilterValues ? Colors.primary : Colors.onSurface}
      //     onPress={() => this.dataTable.toggleFilters()}
      //   />
      // );
      if (this.props.searchable)
        headerControlsRight.unshift(
          <DataTableSearchWidget
            key="dataTableSearchWidget"
            dataTable={this.dataTable}
          />
        );
      let headerControls =
        headerControlsRight && headerControlsRight.length ? (
          <ControlsView align="right">{headerControlsRight}</ControlsView>
        ) : null;
      return (
        <View style={[styles.header, this.props.headerStyle]}>
          {((headerControlsRight && headerControlsRight.length) ||
            this.props.headerTitle) && (
            <View style={styles.headerTitle}>
              <Text
                style={[
                  styles.headerTitleText,
                  this.props.headerTitleTextStyle,
                ]}
              >
                {this.props.headerTitle || null}
              </Text>
              {headerControls}
            </View>
          )}
          {!hasFilterForm && <View style={styles.headerCols}>{colArr}</View>}
          {hasFilterForm && (
            <Form
              name={`dataTable${this.props.name}ColumnFilterForm`}
              onValueChange={(val, elmt, form) =>
                this.dataTable.submitFilters(
                  `dataTable${this.props.name}ColumnFilterForm`
                )
              }
              backgroundColor={Colors.surface}
            >
              <View style={styles.headerCols}>{colArr}</View>
            </Form>
          )}
          {this.renderFilterDialog()}
        </View>
      );
    }
    renderHeaderCollapsableMenu() {
      let items = [];
      for (let colName in this.dataTable.columns) {
        let col = this.dataTable.columns[colName];
        if (this.dataTable.columns[colName].collapsable === false) continue;
        items.push(
          <PopUpMenuItem
            label={col.label}
            icon={
              this.dataTable.isColumnVisible(colName)
                ? "checkboxMarked"
                : "checkboxBlank"
            }
            key={colName}
            onPress={() => {
              this.dataTable.toggleColumn(colName);
            }}
          />
        );
      }
      return (
        <PopUpMenu
          controlIcon="column"
          // ref={this._headerSortMenuRef}
          // controlIconColor={
          //   Theme.componentColors("header").onBackground || Colors.onPrimary
          // }
          key={`dataTableHeaderCollapseMenu`}
          direction="downLeft"
          style={styles.headerMenu}
          onSelect={(value, label) => {}}
        >
          {items}
        </PopUpMenu>
      );
    }
    renderHeaderSortMenu() {
      let items = [];
      for (let colName in this.dataTable.columns) {
        let col = this.dataTable.columns[colName];
        if (col.sortable)
          items.push(
            <View key={colName} style={styles.headerSortMenuRow}>
              <Text style={styles.headerSortMenuLabel}>{col.label}</Text>
              <IconButton
                variant={
                  this.dataTable.sortColumnName === colName &&
                  this.dataTable.sortDirection === "asc"
                    ? "circle"
                    : null
                }
                style={styles.headerSortMenuIcon}
                icon="arrowUp"
                size="small"
                onPress={() => {
                  this.dataTable.setSort(colName, "asc");
                }}
              />
              <IconButton
                variant={
                  this.dataTable.sortColumnName === colName &&
                  this.dataTable.sortDirection === "desc"
                    ? "circle"
                    : null
                }
                style={styles.headerSortMenuIcon}
                icon="arrowDown"
                size="small"
                onPress={() => {
                  this.dataTable.setSort(colName, "desc");
                }}
              />
            </View>
          );
      }
      return (
        <PopUpMenu
          controlIcon="sort"
          ref={this._headerSortMenuRef}
          // controlIconColor={
          //   Theme.componentColors("header").onBackground || Colors.onPrimary
          // }
          key={`dataTableHeaderSortMenu-${this.dataTable.sortColumnName}-${this.dataTable.sortDirection}`}
          direction="downLeft"
          style={styles.headerMenu}
          onSelect={(value, label) => {}}
        >
          <View key="headerSortMenuHeader" style={styles.headerMenuHeader}>
            <Text style={styles.headerMenuHeaderText}>Sort By</Text>
            <IconButton
              style={styles.headerMenuIcon}
              icon="close"
              onPress={this.handleHeaderSortMenuClose}
            />
          </View>
          <View key="headerSortMenuItems">{items}</View>
        </PopUpMenu>
      );
    }
    renderFilterDialogControl() {
      return (
        <IconButton
          // style={styles.headerMenuIcon}
          key="filterDialogControl"
          icon="filter"
          //variant={this.dataTable.state.showFilters && "circle"}
          onPress={this.handleFilterDialogControlPress}
          color={
            this.dataTable.hasFilterValues ? Colors.primary : Colors.onSurface
          }
        />
      );
    }
    // renderHeaderFilterMenu() {
    //   let items = [];
    //   for (let colName in this.dataTable.columns) {
    //     let col = this.dataTable.columns[colName];
    //     if (col.sortable)
    //       items.push(
    //         <View
    //           key={colName}
    //           style={styles.headerFilterMenuRow}
    //           key={colName}
    //         >
    //           <Text style={styles.headerFilterMenuLabel}>{col.label}</Text>
    //           <IconButton
    //             variant={
    //               this.dataTable.sortColumnName === colName &&
    //               this.dataTable.sortDirection === "asc" &&
    //               "circle"
    //             }
    //             style={styles.headerFilterMenuIcon}
    //             icon='arrowUp'
    //             size='small'
    //             onPress={() => {
    //               this.dataTable.setSort(colName, "asc");
    //             }}
    //           />
    //           <IconButton
    //             variant={
    //               this.dataTable.sortColumnName === colName &&
    //               this.dataTable.sortDirection === "desc" &&
    //               "circle"
    //             }
    //             style={styles.headerFilterMenuIcon}
    //             icon='arrowDown'
    //             size='small'
    //             onPress={() => {
    //               this.dataTable.setSort(colName, "desc");
    //             }}
    //           />
    //         </View>
    //       );
    //   }
    //   return (
    //     <PopUpMenu
    //       controlIcon='filter'
    //       ref={this._headerFilterMenuRef}
    //       controlIconColor={
    //         this.dataTable.hasFilterValues ? Colors.primary : Colors.onSurface
    //       }
    //       key={`dataTableHeaderFilterMenu-${this.dataTable.sortColumnName}-${this.dataTable.sortDirection}`}
    //       direction='downLeft'
    //       style={[styles.headerMenu, styles.headerFilterMenu]}
    //       onSelect={(value, label) => {}}
    //     >
    //       <View key='headerFilterMenuHeader' style={styles.headerMenuHeader}>
    //         <Text style={styles.headerMenuHeaderText}>Filters</Text>
    //         <IconButton
    //           style={styles.headerMenuIcon}
    //           icon='deleteSweep'
    //           onPress={this.handleHeaderFiltersReset}
    //           color={Colors.secondary}
    //         />
    //         <IconButton
    //           style={styles.headerMenuIcon}
    //           icon='close'
    //           onPress={this.handleHeaderFilterMenuClose}
    //         />
    //       </View>
    //       <View key='headerFilterMenuItems'>
    //         {this.renderHeaderFilterForm()}
    //       </View>
    //     </PopUpMenu>
    //   );
    // }
    renderFilterDialog() {
      let filterFields =
        this.props.filterFields || this.props.headerFilterFields;
      if (typeof filterFields === "function") {
        filterFields = filterFields(this.dataTable);
      }
      if (!filterFields) return null;
      return (
        <Dialog
          name="filterDialog"
          title={"Filters"}
          visible={this.dataTable.state.showFilters}
          titleControlVariant="close"
          onClosePress={this.handleFilterDialogClosePress}
          onCancelPress={this.handleFilterDialogClosePress}
          onBackdropPress={this.handleFilterDialogClosePress}
          onOkPress={this.handleFilterDialogOkPress}
          okButtonVariant="outlined"
          // okButtonTitle='Filter'
          headerControlsRight={
            this.dataTable.hasFilterValues && [
              <IconButton
                key="delete"
                style={styles.filterDialogResetIconButton}
                icon="deleteSweep"
                onPress={this.handleFilterDialogResetPress}
                tooltip="Clear Filters"
              />,
            ]
          }
        >
          <Form
            style={styles.filterDialogForm}
            name={`dataTable${this.props.name}DialogFilterForm`}
            values={this.dataTable.state.filters}
            backgroundColor={Colors.surface}
          >
            {filterFields}
          </Form>
        </Dialog>
      );
    }

    // renderHeaderFilterForm() {
    //   let headerFilterFields = this.props.headerFilterFields;
    //   if (typeof headerFilterFields === "function") {
    //     headerFilterFields = headerFilterFields(this.dataTable);
    //   }
    //   if (!headerFilterFields) return null;
    //   return (
    //     <AnimatedView
    //       in={this.dataTable.state.showFilters}
    //       enter={{
    //         transform: [{ translateY: 0 }],
    //         opacity: 1,
    //       }}
    //       exit={{
    //         transform: [{ translateY: -48 }],
    //         opacity: 0,
    //       }}
    //       duration={100}
    //       unmountOnExit
    //     >
    //       <Form
    //         style={styles.headerFilterForm}
    //         name={`dataTable${this.props.name}FilterForm`}
    //         onValueChange={(val, elmt, form) =>
    //           this.dataTable.submitFilters(
    //             `dataTable${this.props.name}FilterForm`
    //           )
    //         }
    //         values={this.dataTable.state.filters}
    //         backgroundColor={Colors.surface}
    //       >
    //         {headerFilterFields}
    //       </Form>
    //     </AnimatedView>
    //   );
    // }
    renderFooter() {
      let minRowsPerPage = this.dataTable.rowsPerPageSelectMenuItems.length
        ? this.dataTable.rowsPerPageSelectMenuItems[0].value
        : null;
      if (
        !this.dataTable.isInitialized ||
        !this.dataTable.totalRows ||
        !minRowsPerPage ||
        this.dataTable.totalRows <= minRowsPerPage
      )
        return null;

      let styles = this.footerStyles();
      return (
        <View style={styles.footer}>
          <View style={styles.footerCell}>
            <View style={styles.footerRowControls}>
              <View>
                <Text style={styles.footerText}>Rows Per Page:</Text>
              </View>
              <SelectMenu
                name="rowsPerPage"
                style={styles.rowsPerPageSelectMenu}
                useValuesOnly
                valueLabelTextStyle={styles.rowsPerPageSelectMenuText}
                onValueChange={this.handleRowsPerPageValueChange}
                items={this.dataTable.rowsPerPageSelectMenuItems}
                value={this.dataTable.state.rowsPerPage}
              />
            </View>
          </View>
          <View style={styles.footerCell}>
            <View style={styles.footerRowInfo}>
              <Text style={styles.footerText}>
                {this.dataTable.startIndex + 1} -{" "}
                {this.dataTable.totalRows <= this.dataTable.endIndex
                  ? this.dataTable.totalRows
                  : this.dataTable.endIndex + 1}{" "}
                of {this.dataTable.totalRows}
              </Text>
            </View>
            <View style={styles.footerControls}>
              <IconButton
                icon="keyboardArrowLeft"
                disabled={this.dataTable.page === 1}
                onPress={this.loadPreviousPage}
                size="large"
              />
              <IconButton
                icon="keyboardArrowRight"
                disabled={this.dataTable.page >= this.dataTable.totalPages}
                onPress={this.loadNextPage}
                size="large"
              />
            </View>
          </View>
        </View>
      );
    }
    render() {
      let ListHeaderComponent = this.renderHeader();
      let ListFooterComponent = this.renderFooter();
      let ListEmptyComponent = this.renderListEmptyComponent();

      return (
        <FlatList
          ListHeaderComponent={ListHeaderComponent}
          ListFooterComponent={ListFooterComponent}
          ListEmptyComponent={ListEmptyComponent}
          nestedScrollEnabled
          data={this.dataTable.pageData}
          style={this.props.style}
          autoSize={true}
          numColumns={1}
          keyExtractor={this.keyExtractor}
          renderItem={this.renderRow}
          ref={this._flatListRef}
          onLayout={this.handleLayout}
        />
      );
    }
    footerStyles() {
      return StyleSheet.create({
        footer: {
          flex: 1,
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingBottom: this.props.paddingBottom || 0,
          screen: ({ width }) => {
            if (width <= 480) {
              return {
                flexDirection: "column-reverse",
                alignItems: "flex-end",
              };
            }
          },
        },
        footerText: {
          color: StyleSheet.color(Colors.onSurface).rgba(0.6),
          fontWeight: "500",
          fontSize: 14,
        },
        footerCell: {
          flex: 0,
          flexDirection: "row",
          alignItems: "center",
        },
        footerRowControls: {
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          screen: ({ width }) => {
            if (width <= 480) {
              return {
                marginRight: -14,
              };
            }
          },
        },
        footerRowInfo: {
          marginLeft: 30,
        },
        footerControls: {
          height: 48,
          flex: 0,
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          marginLeft: 44,
        },
        rowsPerPageSelectMenu: {
          screen: ({ width }) => {
            if (width <= 480) {
              return {
                marginRight: 20,
              };
            }
          },
        },
        rowsPerPageSelectMenuText: {
          // width: 80
          color: StyleSheet.color(Colors.onSurface).rgba(0.8),
          fontWeight: "500",
        },
      });
    }
  }
);

const DataTableSearchWidget = R14.connect(
  class DataTableSearchWidget extends React.Component {
    constructor(props) {
      super(props);
      this.dataTable = this.props.dataTable;
      this.handleChangeText = this.handleChangeText.bind(this);
      this.handleShowPress = this.handleShowPress.bind(this);
      this.handleHidePress = this.handleHidePress.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
    }
    async handleChangeText(value) {
      return await this.dataTable.handleSearchChangeText(value);
    }

    handleShowPress() {
      this.dataTable.showSearch();
    }
    handleHidePress() {
      this.dataTable.hideSearch();
    }
    handleBlur() {
      this.dataTable.hideSearch();
    }
    render() {
      /** @todo move this to it's own component to reduce renders */
      let placeholder = this.props.headerTitle
        ? `Search ${this.props.headerTitle}`
        : "Search";
      return (
        <View style={styles.searchWidget}>
          <AnimatedView
            enter={{
              transform: [{ translateX: 0 }, { scale: 1 }],
              opacity: 1,
            }}
            exit={{
              transform: [{ translateX: 80 }, { scale: 0.25 }],
              opacity: 0,
            }}
            in={this.dataTable.state.showSearch}
            duration={150}
            style={styles.searchTextInputFieldContainer}
            unmountOnExit
          >
            <View style={styles.searchTextInputField}>
              <TextInput
                name="search"
                value={this.dataTable.state.searchTextInputValue}
                onChangeText={this.handleChangeText}
                style={styles.searchTextInput}
                autoFocus
                //onBlur={this.handleBlur}
                placeholder={placeholder}
              />
              {/* <IconButton
                icon='close'
                key='reset'
                size='small'
                onPress={this.handleHidePress}
              /> */}
              <IconButton
                icon="arrowForward"
                key="close"
                size="small"
                onPress={this.handleHidePress}
                style={styles.hideIconButton}
              />
            </View>
          </AnimatedView>
          <IconButton
            icon="search"
            key="search"
            color={
              this.dataTable.searchTextExists()
                ? Colors.primary
                : Colors.onSurface
            }
            onPress={this.handleShowPress}
          />
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  dataTable: {
    flex: 1,
    flexDirection: "column",
  },
  header: {
    backgroundColor: Colors.surface,
    elevation: 2,
  },
  headerTitle: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    ...StyleSheet.padding(16, 16, 12, 16),
  },
  headerTitleText: {
    fontSize: 18,
    fontWeight: "500",
    lineHeight: 36,
    color: Colors.onSurface,
  },
  headerCols: {
    flex: 1,
    flexDirection: "row",
    width: "100%",
    // paddingLeft: 8,
    // paddingRight: 8,
    height: 56,
  },
  filterDialogForm: {
    ...StyleSheet.padding(4, 0, 0, 0),
    ...StyleSheet.margin(0, 0, -16, 0),
  },
  filterDialogResetIconButton: {
    marginRight: 12,
  },
  headerFilterForm: {
    flex: 0,
    flexDirection: "column",
    justifyContent: "center",
    ...StyleSheet.padding(16, 12, 0, 12),
  },
  headerFilterField: {
    flex: 0,
    minWidth: 280,
    ...StyleSheet.margin(-16, 8, -24, 8),
  },
  headerColsFilters: {
    backgroundColor: Colors.secondaryDark,
  },
  headerColsCell: {
    flex: 1,
    flexBasis: 1,
    flexGrow: 1,
    flexDirection: "row",
    // justifyContent: "flex-end",
    alignItems: "center",
    // flexShrink: 0,
    ...StyleSheet.padding(16, 16, 12, 16),
  },
  headerColsCellRight: {
    justifyContent: "flex-end",
  },
  headerColsCellTouchable: {
    flex: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  headerColsCellText: {
    // color: StyleSheet.color(Colors.onSurface).rgba(0.6),
    fontWeight: "500",
    fontSize: 14,
    // paddingRight: 4,
    color: Colors.onSurface,
  },
  headerColsCellTextActive: {
    // color: StyleSheet.color(Colors.onSurface).rgba(0.8),
  },
  headerMenu: {
    // marginTop: -4,
    minWidth: 160,
  },
  headerMenuIcon: {
    ...StyleSheet.margin(0),
  },
  headerMenuHeader: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    height: 36,
    justifyContent: "space-between",
    ...StyleSheet.padding(0, 4, 0, 12),
    ...StyleSheet.margin(0, 0, 0, 0),
    // ...StyleSheet.border(1, 'solid', StyleSheet.color(Colors.onSurface).rgba(0.1))
    // borderBottomWIdth:
  },
  headerMenuHeaderText: {
    fontWeight: 500,
    marginRight: "auto",
  },

  headerSortMenuRow: {
    flex: 1,
    flexDirection: "row",
    height: 44,
    alignItems: "center",
    minWidth: 160,
    maxWidth: 256,
    ...StyleSheet.padding(0, 4, 0, 12),
  },
  headerSortMenuLabel: {
    marginRight: "auto",
    fontSize: 14,
    paddingRight: 8,
  },

  headerFilterMenu: {
    width: 256,
  },

  row: {
    width: "100%",
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderColor: StyleSheet.color(Colors.onBackground).rgba(0.1),
    hover: {
      backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.05),
    },
  },
  rowCells: {
    flex: 1,
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-start",
    // borderStyle: "solid",
    // borderBottomWidth: 1,
    // paddingLeft: 8,
    // paddingRight: 8,
    // borderColor: StyleSheet.color(Colors.onBackground).rgba(0.1),
    // hover: {
    //   backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.05),
    // },
  },
  rowBottom: {
    ...StyleSheet.margin(-16, 16, 16, 16),
  },
  rowSelected: {
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.1),
  },
  rowControlsRightCell: {
    // alignSelf: 'flex-end',
    // flex: 0,
    // flexBasis: 0,
    marginLeft: "auto",
  },
  rowControlsRight: {
    flex: 1,
    flexBasis: 1,
    flexGrow: 0,
    flexDirection: "row",
    ...StyleSheet.margin(-8, -8, -8, "auto"),
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  checkboxCell: {
    flex: 0,
    flexDirection: "row",
    justifyContent: "flex-start",
    ...StyleSheet.margin(8, 0, 0, 8),
    alignItems: "flex-start",
    width: 32,
    borderStyle: "solid",
  },
  cell: {
    flex: 1,
    flexBasis: 1,
    flexGrow: 1,
    position: "relative",
    // backgroundColor: Colors.primary,
    // flexShrink: 0,
    ...StyleSheet.padding(16, 16, 16, 16),
    // paddingLeft: 8,
    // paddingTop: 16,
    // paddingBottom: 16,
  },
  cellTouchable: {
    position: "absolute",
    ...StyleSheet.absoluteFill,
    flex: 1,
  },
  cellTouchableView: {
    position: "absolute",
    ...StyleSheet.absoluteFill,
    flex: 1,
  },
  cellRight: {
    alignItems: "flex-end",
  },
  activityIndicator: {
    flex: 1,
    justifyContent: "space-around",
    alignItems: "center",
  },
  emptyComponent: {
    textAlign: "center",
    padding: 16,
    color: StyleSheet.color(Colors.onBackground).rgba(0.5),
  },
  sortIconLeft: {
    ...StyleSheet.margin(0, 4, 0, -24),
  },
  sortIconLeftAlignRight: {
    ...StyleSheet.margin(0, 4, 0, 0),
  },
  sortIconRight: {
    ...StyleSheet.margin(0, 0, 0, 4),
  },
  searchWidget: {
    position: "relative",
    overflow: "visible",
  },
  searchTextInputField: {
    flex: 1,
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.1),
    ...StyleSheet.padding(4, 16, 4, 16),
    borderRadius: 4,
    flexDirection: "row",
  },
  searchTextInputFieldContainer: {
    backgroundColor: Colors.surface,
    height: 36,
    position: "absolute",
    right: 2,
    top: -4,
    zIndex: 2,
  },
  searchTextInput: {
    backgroundColor: "transparent",
    color: Colors.onBackground,
    fontSize: 16,
    width: 144,
  },
  hideIconButton: {
    marginRight: -12,
  },
});
