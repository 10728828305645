import React from "react";
import R14, { StyleSheet, Colors } from "../core";
const constants = {
  ANNOTATION_TYPE_FIELD: "FIELD",
  ANNOTATION_TYPE_FIELD_GROUP: "FIELD_GROUP",
  ANNOTATION_TYPE_SELECTIONS: "SELECTIONS",
  ANNOTATION_TYPE_QUESTION: "QUESTION",
  SORT_BY_LAYOUT: "SORT_BY_LAYOUT",
  INDICATOR_STATE_ACTIVE: "ACTIVE",
  INDICATOR_STATE_SELECTED: "SELECTED",
};

export default class DocumentSetUiDomain extends R14.DomainInstances {
  constructor(key) {
    super();
    // for(let k in constants) this[]
  }
  async instance(uid, options = {}) {
    if (this.exists(uid)) return this.getInstance(uid);
    let docSet = await this.dm.manualEntry.fetchDocumentSet(uid);
    if (!docSet) return null;
    let documentSet = new DocumentSetUiInstanceDomain(
      this,
      uid,
      docSet,
      options
    );
    await documentSet.init();
    this.addInstance(uid, documentSet);
    return documentSet;
  }
  clearInstances() {
    this.forEach((inst) => {
      inst.remove();
    });
  }
}
class DocumentSetUiInstanceDomain extends R14.DomainInstances {
  constructor(documentSets, uid, documentSet, options = {}) {
    super();
    this._documentSets = documentSets;
    this._documentSet = documentSet;
    this._pageAnnotationMap = {
      [constants.ANNOTATION_TYPE_FIELD]: {},
      [constants.ANNOTATION_TYPE_FIELD_GROUP]: {},
    };
    this._uid = uid;
    // this._pages = [];
    this._annotations = new DocumentSetAnnotationsUiDomain(this);
    this._currentAnnotation = null;
    this._saving = false;
    this._documentCompleteDialogOptions = {};
    this._currPage = null;
    this.state = {
      documentCompleteDialogVisible: false,
      currPage: null,
      currAnnotation: null,
      metadata: {
        state: this.dm.manualEntry.STATE_QUEUED,
      },
      totalDocumentImages: documentSet.totalDocumentImages,
      documentImagesPageInfo: documentSet.documentImagesPageInfo,
      documentImagesLoading: false,
      totalFieldAnnotations: documentSet.totalFieldAnnotations,
      fieldAnnotationsPageInfo: documentSet.fieldAnnotationsPageInfo,
      annotationsLoading: false,
    };
  }
  get uid() {
    return this._uid;
  }
  get annotationSetUid() {
    return this._documentSet.annotationSetUid;
  }
  get editable() {
    return this.dm.manualEntry.documentSetEditable === true;
  }
  hasAppFeature(value) {
    return this.dm.manualEntry.hasAppFeature(value);
  }
  // get disableReject() {
  //   return true;
  //   return this.dm.manualEntry.disableReject === true;
  // }
  // get disableRejectRemaining() {
  //   return true;
  //   return (
  //     this.disableReject || this.dm.manualEntry.disableRejectRemaining === true
  //   );
  // }
  // get disableAcceptRemaining() {
  //   return true;
  //   return this.dm.manualEntry.disableAcceptRemaining === true;
  // }
  // get disableDocumentPreview() {
  //   return false;
  //   return this.dm.manualEntry.disableDocumentPreview === true;
  // }
  get initialDocumentScaleMode() {
    return this.dm.manualEntry._initialDocumentScaleMode;
  }
  get documentImageStorageMode() {
    return this._documentSet.documentImageStorageMode;
  }
  get totalPages() {
    return this.state.totalDocumentImages;
    //return this._documentSet.totalDocumentImages;
  }
  get totalFieldAnnotations() {
    return this.state.totalFieldAnnotations;
  }
  get currentPage() {
    if (!this._currPage || this._currPage.number !== this.state.currPage)
      this._currPage = this.getPage(this.state.currPage);
    return this._currPage;
  }
  get currentPageNumber() {
    return this.state.currPage;
  }
  get currentAnnotation() {
    // Check annotation
    return this.state.currAnnotation &&
      this._currentAnnotation &&
      this._currentAnnotation.uuid === this.state.currAnnotation.uuid
      ? this._currentAnnotation
      : null;
    // return this.state.currAnnotation
    //   ? this.getAnnotation(this.state.currAnnotation.uuid)
    //   : null;
  }
  get currentAnnotationInitialized() {
    return this._currAnnotationInitialized;
  }
  get currentAnnotationKey() {
    return this.state.currAnnotation ? this.state.currAnnotation.key : null;
  }
  get currentAnnotationType() {
    return this.state.currAnnotation ? this.state.currAnnotation.type : null;
  }
  get pageScroller() {
    return this.ui.scroller("pageScroller");
  }
  get annotationScroller() {
    return this.ui.scroller("annotationScroller");
  }
  get pages() {
    // Keep page iteration in order
    return this._documentSet.pages.map((page) => this.getPage(page.number));
  }
  get documentImagesPageInfo() {
    return this.state.documentImagesPageInfo;
  }
  get fieldAnnotationsPageInfo() {
    return this.state.fieldAnnotationsPageInfo;
  }
  get annotations() {
    return this._annotations;
  }
  findFieldAnnotations(options = {}) {
    return this.findAnnotations({
      type: constants.ANNOTATION_TYPE_FIELD,
      sortBy: constants.SORT_BY_LAYOUT,
      ...options,
    });
  }
  get documentCompleteDialogVisible() {
    return this.state.documentCompleteDialogVisible;
  }
  get documentCompleteDialogOptions() {
    return this._documentCompleteDialogOptions || {};
  }
  get shouldReview() {
    return this.metadata.state === this.dm.manualEntry.STATE_REVIEW;
  }
  get nextAnnotation() {
    return this.findNextAnnotation({
      currentAnnotation: this.currentAnnotation,
    });
  }
  findNextAnnotation(options = {}) {
    let ret = null;
    let annotations = this.findAnnotations({
      type: constants.ANNOTATION_TYPE_FIELD,
      sortBy: constants.SORT_BY_LAYOUT,
    });

    if (options.currentAnnotation) {
      let foundNext = false;
      for (let annotation of annotations) {
        if (annotation.uuid === this.currentAnnotation.uuid) {
          foundNext = true;
        } else if (
          foundNext &&
          annotation.metadata.state === this.dm.manualEntry.STATE_QUEUED
        ) {
          ret = annotation;
          break;
        }
      }
    } else
      ret = annotations.find(
        (annotation) =>
          annotation.metadata.state === this.dm.manualEntry.STATE_QUEUED
      );
    return ret;
  }
  get metadata() {
    return this.state.metadata;
  }
  findAnnotations(options = {}) {
    let annotations = this.annotations.filter((annotation) => {
      if (options.pageNumber && !annotation.inPage(options.pageNumber))
        return false;
      if (options.type && annotation.type !== options.type) return false;
      if (
        options.parentAnnotationUuid &&
        annotation.uuid !== options.parentAnnotationUuid
      )
        return false;
      return true;
    });
    if (options.sortBy) {
      annotations.sort((a, b) => {
        return a.positionIndex > b.positionIndex ? 1 : -1;
        // if (a.documentPositionIndex === b.documentPositionIndex)
        //   return a.positionIndex > b.positionIndex ? -1 : 1;
        // else return a.documentPositionIndex > b.documentPositionIndex ? 1 : -1;
      });
    }
    return annotations;
  }

  // runDocumentAnnotationsAction(action, updatedAnnotations) {
  //   throw new Error("REMOVED");
  //   let updatedPositionIndex = updatedAnnotations[0].positionIndex;
  //   let toPage = updatedAnnotations[0].documentPositionIndex;
  //   let fromPage = null;
  //   let updatedUuids = updatedAnnotations.map((annotation) => annotation.uuid);

  //   // Get all annotations that are not being updated
  //   let annotations = this.annotations
  //     .filter((annotation) => {
  //       if (updatedUuids.includes(annotation.uuid))
  //         fromPage = annotation.documentPositionIndex;
  //       return updatedUuids.includes(annotation.uuid) ? false : true;
  //     })
  //     .map((annotation) => ({
  //       uuid: annotation.uuid,
  //       documentUuid: annotation.documentUuid,
  //       positionIndex: annotation.positionIndex,
  //       documentPositionIndex: annotation.documentPositionIndex,
  //     }))
  //     .sort((a, b) => (a.positionIndex > b.positionIndex ? 1 : -1));
  //   annotations.splice(updatedPositionIndex - 1, 0, ...updatedAnnotations);

  //   // Update the position index, page, document position index
  //   let positionIndex = 1;
  //   annotations.forEach((annotation) => {
  //     //let updateAnnotation = this.annotations.get(annotation.uuid);
  //     //updateAnnotation.positionIndex = positionIndex++;
  //     let documentPositionIndex = annotation.documentPositionIndex;
  //     if (!updatedUuids.includes(annotation.uuid)) {
  //       //if(documentPositionIndex > updateDocumentPositionIndex)
  //       switch (action) {
  //         case this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_COPY:
  //           if (annotation.documentPositionIndex >= toPage)
  //             documentPositionIndex++;
  //           break;
  //         case this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_MOVE:
  //           if (
  //             toPage > fromPage &&
  //             annotation.documentPositionIndex > fromPage &&
  //             annotation.documentPositionIndex <= toPage
  //           )
  //             documentPositionIndex--;
  //           else if (
  //             toPage < fromPage &&
  //             annotation.documentPositionIndex < fromPage &&
  //             annotation.documentPositionIndex >= toPage
  //           )
  //             documentPositionIndex++;
  //           break;
  //         default:
  //           throw new Error("Unknown action");
  //       }
  //       // Updated the annotation
  //     }
  //     let updateAnnotation = this.annotations.exists(annotation.uuid)
  //       ? this.annotations.getInstance(annotation.uuid)
  //       : this.addAnnotationInstance(annotation);

  //     // console.log("CHECK UPDATE: ", {
  //     //   uuid: annotation.uuid,
  //     //   updated: updatedUuids.includes(annotation.uuid),
  //     //   uPidx: updateAnnotation.positionIndex,
  //     //   udPidx: updateAnnotation.documentPositionIndex,
  //     //   upn: updateAnnotation.pageNumber,
  //     //   positionIndex,
  //     //   documentPositionIndex,
  //     // });

  //     updateAnnotation.positionIndex = positionIndex;
  //     updateAnnotation.documentPositionIndex = documentPositionIndex;
  //     updateAnnotation.pageNumber = documentPositionIndex;

  //     positionIndex++;
  //   });
  // }

  findAnnotation(options = {}) {
    let ret = null;
    let prevAnnotation = null;

    for (let annotation of this.annotations.toArray()) {
      if (options.type && annotation.type !== options.type) continue;
      if (
        options.beforeAnnotationUuid &&
        annotation.uuid === options.beforeAnnotationUuid
      ) {
        ret = prevAnnotation;
        break;
      } else if (
        options.afterAnnotationUuid &&
        prevAnnotation &&
        prevAnnotation.uuid === options.afterAnnotationUuid
      ) {
        ret = annotation;
        break;
      }
      prevAnnotation = annotation;
    }
    return ret;
  }
  async init() {
    if (!this._documentSet.pages) return false;
    // Create pages
    this._documentSet.pages.sort((a, b) => (a.number > b.number ? 1 : -1));
    for (let page of this._documentSet.pages) {
      this.addPageInstance(page);
    }
    this.addAnnotationInstances();
    await this.update();
  }
  async update() {
    let metadata = this.state.metadata;
    let nextAnnotation = this.findNextAnnotation({
      currentAnnotation: this.currentAnnotation,
    });
    if (!nextAnnotation) {
      let complete = false;
      // If all annotations are loaded, look for any incomplete
      if (this.totalFieldAnnotations === this.findFieldAnnotations().length) {
        nextAnnotation = this.findNextAnnotation();
        complete = nextAnnotation ? false : true;
      } else {
        await this.loadAnnotations({
          findNext: true,
          currentAnnotationUuid: this.currentAnnotation
            ? this.currentAnnotation.uuid
            : null,
          updateScroller: true,
        });
        nextAnnotation = this.findNextAnnotation();
        if (!nextAnnotation) complete = true;
      }
      if (complete) {
        metadata.state = this.dm.manualEntry.STATE_REVIEW;
        this._documentCompleteDialogOptions = {
          startTime: new Date(),
        };
        this.setState({
          documentCompleteDialogVisible: true,
          metadata,
        });
      }
    }
    return nextAnnotation;
  }

  async loadNextDocuments(options = {}) {
    let page = this.documentImagesPageInfo.page;
    if (!this.documentImagesPageInfo.hasNextPage)
      throw new Error("Next page out of range");
    return await this.loadDocuments({
      ...options,
      page: page + 1,
    });
  }
  async loadPrevDocuments(options = {}) {
    let page = this.documentImagesPageInfo.page;
    if (page <= 1) throw new Error("Previous page out of range");
    return await this.loadDocuments({
      ...options,
      page: page - 1,
    });
  }
  goToDocumentPage(documentPage, options = {}) {
    this.navToPage(documentPage);
  }
  documentLoaded(documentUuid) {
    let ret = false;

    for (let p of this.pages) {
      // console.log("check for doc", p.number, documentUuid, p.documentUuid);
      if (p.documentUuid === documentUuid) {
        ret = true;
        break;
      }
    }
    return ret;
  }
  async loadAnnotations(options = {}) {
    let documentSet =
      options.documentSet ||
      (await this.dm.manualEntry.loadAnnotationSetAnnotations(
        this.annotationSetUid,
        {
          ...options,
        }
      ));

    const annotations = await this.updateAnnotationInstances(
      documentSet.annotations,
      { reset: options.reset || false }
    );
    options.updateScroller &&
      this.annotationScroller &&
      (await this.annotationScroller.addPageData(
        documentSet.fieldAnnotationsPageInfo.page,
        annotations,
        { reset: options.reset || false }
      ));

    return annotations;
  }
  async loadDocuments(options = {}) {
    options.loading !== false && this.ui.progressIndicator.show();
    // options.loading !== false &&
    //   this.setState({
    //     documentImagesLoading: true,
    //   });

    let documentSet =
      options.documentSet ||
      (await this.dm.manualEntry.loadAnnotationSetDocuments(
        this.annotationSetUid,
        {
          ...options,
        }
      ));

    // for (let page of this._documentSet.pages) {
    //   await this.addPageInstance(page);
    // }
    const pages = await this.updatePageInstances(documentSet.pages, {
      reset: options.reset || false,
    });

    if (options.updateScroller && this.pageScroller) {
      let res = await this.pageScroller.addPageData(
        documentSet.documentImagesPageInfo.page,
        pages,
        {
          reset: options.reset || false,
        }
      );
    }

    // Update the current page
    let newCurrPage = documentSet.pages.find(
      (page) => page.documentUuid === this.currentPage.documentUuid
    );
    if (newCurrPage) {
      this.currentPage.update(newCurrPage);
    }

    options.loading !== false &&
      this.ui.progressIndicator.hide({ timeout: 500 });

    // this.addAnnotationInstances();
    this.setState({
      totalDocumentImages: documentSet.totalDocumentImages,
      documentImagesPageInfo: documentSet.documentImagesPageInfo,
      documentImagesLoading: false,
    });
    return pages;
  }
  async rotateDocument() {
    if (!this.hasAppFeature("enableDocumentRotate")) return false;
    return await this.runDocumentAction(
      this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_ROTATE,
      this.currentPage.state.rotate,
      {
        progressIndicator: true,
        documentImagesLoading: true,
      }
    );
  }
  async moveDocument(toPage) {
    return await this.runDocumentAction(
      this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_MOVE,
      toPage,
      {
        documentImagesLoading: true,
        annotationsLoading: true,
      }
    );
  }
  async copyDocument(toPage) {
    return await this.runDocumentAction(
      this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_COPY,
      toPage,
      {
        documentImagesLoading: true,
        annotationsLoading: true,
      }
    );
  }
  async runDocumentAction(action, value, options = {}) {
    options.progressIndicator && this.ui.progressIndicator.show();
    this.setState({
      documentImagesLoading: options.documentImagesLoading || false,
      annotationsLoading: options.annotationsLoading || false,
    });

    // Check if current annotation is being moved
    // switch (action) {
    //   case this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_MOVE:
    //     if (
    //       this.currentPage.documentUuid === currentAnnotationUuid.documentUuid
    //     ) {

    //     }
    //     break;
    //   default: // Do nothing
    // }

    let documentSet = await this.dm.manualEntry.runAnnotationSetDocumentAction(
      this.annotationSetUid,
      this.currentPage.documentUuid,
      action,
      parseInt(value),
      this.currentAnnotation.uuid
    );

    switch (action) {
      case this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_MOVE:
      case this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_COPY:
        break;
      default:
      // Do nothing
    }

    await this.loadDocuments({
      documentSet,
      updateScroller: true,
      reset: true,
    });
    let updatedPage = this.pages.find((page) => {
      return (
        page.documentUuid === this.currentPage.documentUuid &&
        page.number !== this.currentPage.number
      );
    });
    updatedPage && this.navToPage(updatedPage.number);

    const lastAnnotationPositionIndex = this.currentAnnotation.positionIndex;

    await this.loadAnnotations({
      documentSet,
      updateScroller: true,
      reset: true,
    });

    // Check if the current annotation needs to be scrolled to
    if (
      this.annotationScroller &&
      lastAnnotationPositionIndex &&
      this.currentAnnotation.positionIndex &&
      lastAnnotationPositionIndex !== this.currentAnnotation.positionIndex
    )
      this.annotationScroller.scrollToIndex(
        this.currentAnnotation.positionIndex - 1,
        {
          autoAnimate: true,
        }
      );

    // let updatedAnnotation = this.annotations.find(
    //   (annotation) => annotation.uuid === this.currentAnnotation.uuid
    // );
    // updatedAnnotation && this.setCurrentAnnotation(updatedAnnotation);

    this.setState({
      totalDocumentImages: documentSet.totalDocumentImages,
      documentImagesPageInfo: documentSet.documentImagesPageInfo,
      documentImagesLoading: false,
      annotationsLoading: false,
    });

    // Remove current pages
    // let forwardPage = null;
    // let forwardAnnotationPage = null;
    // switch (action) {
    //   case this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_COPY:
    //     for (let page of documentSet.pages) {
    //       if (page.documentUuid === this.currentPage.documentUuid)
    //         forwardPage = page.number;
    //     }
    //     break;
    //   case this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_MOVE:
    //     forwardPage = value;
    //     break;
    //   case this.dm.manualEntry.ANNOTATION_SET_DOCUMENT_ACTION_ROTATE:
    //     //forwardPage = this.currentPage.number;
    //     break;
    // }

    //this.loadDocuments({ documentSet, loading: false, updateScroller: true });

    // await this.annotationScroller.reload();
    // await this.pageScroller.reload();
    options.progressIndicator &&
      this.ui.progressIndicator.hide({ timeout: 750 });

    // console.log("CURR ANNOATION?", documentSet, this.currentAnnotation);

    // if (documentSet.annotations && documentSet.annotations.length)
    //   this.runDocumentAnnotationsAction(action, documentSet.annotations);

    // await this.updatePageInstances(documentSet.pages, {
    //   pageNumber: forwardPage,
    // });
    // for (let page of this._documentSet.pages) {
    //   console.log("AddPageInstance!!!", page);
    //   await this.addPageInstance(page, { remove: true });
    // }
    // this.cleanupPageInstances();
    // await this.addAnnotationInstances();

    return true;
  }

  remove() {
    this._documentSets.removeInstance(this.uid);
  }
  async complete(options = {}) {
    if (this._saving) {
      return false;
    }
    if (
      options.logout &&
      !window.confirm("Are you sure you want to complete and logout?")
    )
      return false;
    this._saving = true;
    this.ui.progressIndicator.show();
    let complete = await this.dm.manualEntry.saveQueue({
      force: true,
      currentAnnotation: {
        uuid: this.currentAnnotation.uuid,
        state: this.currentAnnotation.metadata.state,
        reject: this.currentAnnotation.metadata.reject,
      },
      ...this.documentCompleteDialogOptions,
    });
    this.remove();
    this.ui.progressIndicator.hide({ timeout: 750 });
    this.ui.snackBar.show({
      message: "Your updates have been submitted.",
      variant: "success",
    });
    this.nav.to(options.logout ? "logout" : "manualEntryLoad");
    this._saving = false;
  }
  // async complete() {
  //   //try {
  //   let complete = await this.dm.manualEntry.saveQueue();
  //   this.remove();
  //   console.log("CHECK COMPLETE", complete);
  // }
  timeout() {
    this.remove();
    this.dm.manualEntry.timeout();
  }
  setDocumentCompleteDialogVisible(
    documentCompleteDialogVisible,
    documentCompleteDialogOptions = {}
  ) {
    this._documentCompleteDialogOptions = documentCompleteDialogOptions;
    this.setState({
      documentCompleteDialogVisible,
    });
  }
  setCurrentPage(number) {
    number = parseInt(number);
    if (this.state.currPage === number) return true;
    let currPage = this.getPage(number);

    if (!currPage) return false;

    // Make sure currPage is available
    let activeItemStyle =
      this.currentAnnotation &&
      this.currentAnnotation.documentUuid === currPage.documentUuid
        ? constants.INDICATOR_STATE_SELECTED
        : constants.INDICATOR_STATE_ACTIVE;

    this.pageScroller &&
      this.pageScroller.setActiveItemName(currPage.key, activeItemStyle);

    this.pageScroller &&
      this.pageScroller.scrollToIndex(number - 1, {
        autoScroll: true,
        autoAnimate: true,
      });

    this._currPage = currPage;
    this.setState({
      currPage: currPage.number,
    });

    // Make sure the form is on the current page
    // if (
    //   this.currentAnnotation &&
    //   !this.currentAnnotation.inPage(currPage.number) &&
    //   this.currentAnnotation.editForm.offsetSelectionModeEnabled
    // ) {
    //   console.log(
    //     "DEV DEV DEV: This should be moved completely to the editForm"
    //   );
    //   this.currentAnnotation.editForm.disableOffsetSelectionMode();
    // }

    return true;
  }
  refreshCurrentAnnotation() {
    // What is the point of this?
    return false;
    let currAnnotation = this.state.currAnnotation;
    this.annotationScroller.setActiveItemName(null);
    this.setState({ currAnnotation: null });
    this._currentAnnotation = null;
    if (!currAnnotation) return false;
    let annotation = this.annotations.getInstance(currAnnotation.uuid) || null;
    if (!annotation) return false;
    this.setCurrentAnnotation(annotation);
  }
  setCurrentAnnotation(annotation) {
    if (
      this.state.currAnnotation &&
      this.state.currAnnotation.uuid === annotation.uuid
    )
      return true;

    let currPage = this.getPage(annotation.pageNumber);
    this.annotationScroller.setActiveItemName(annotation.uuid);
    this.forEach((page) => page.setCurrentAnnotation(annotation));
    this._currentAnnotation = annotation;
    this.setState({
      currAnnotation: {
        uuid: annotation.uuid,
        documentUuid: annotation.documentUuid,
      },
    });

    this.annotationScroller &&
      this.annotationScroller.scrollToIndex(annotation.positionIndex - 1, {
        autoAnimate: true,
      });

    currPage &&
      this.pageScroller &&
      this.pageScroller.setActiveItemName(
        currPage.key,
        constants.INDICATOR_STATE_SELECTED
      );

    return true;
  }
  setMetadata(metadata) {
    this.setState({ metadata });
  }
  getPage(number, options = {}) {
    let pageKey = this.createPageKey(number);
    let page = this.getInstance(pageKey) || null;
    page && options.current && this.setCurrentPage(number);
    return page;
    // let page = this.pages[number - 1];
    // if (!page || page.number !== number)
    //   page = this.findPage({ number: number });
    // return page;
  }
  getAnnotation(uuid, options = {}) {
    // console.log("get annotation")
    // if(this.currentAnnotation.uuid === uuid) return this.currentAnnotation;
    let annotation = this.annotations.getInstance(uuid) || null;
    annotation && options.current && this.setCurrentAnnotation(annotation);
    return annotation;
  }
  navToPage(number, options = {}) {
    // R14.instance.app.ui.progressIndicator.show();
    let navMethod = options.replace ? "replace" : "to";
    let params = {
      uid: this.uid,
      pageNumber: parseInt(number),
    };

    this.nav[navMethod](`manualEntryDocumentSetDocument`, params);

    // this.nav[navMethod](
    //   options.refresh
    //     ? "manualEntryDocumentSetDocumentReload"
    //     : `manualEntryDocumentSetDocument`,
    //   params
    // );
    // R14.instance.app.ui.progressIndicator.hide({ timeout: 500 });
  }
  navToAnnotation(annotationUuid) {
    this.nav.to(`manualEntryDocumentSetAnnotationEdit`, {
      uid: this.uid,
      annotationUuid,
    });
  }
  navToNextAnnotation(options = {}) {
    let annotation = null;
    if (options.afterAnnotationUuid) {
      throw new Error("HERE");
    } else annotation = this.nextAnnotation;
    this.navToAnnotation(annotation.uuid);
  }
  // findPage(filters = {}) {
  //   let ret = null;
  //   for (let page of this.pages) {
  //     let foundPage = true;
  //     for (let k in filters) {
  //       if (page[k] !== filters[k]) {
  //         foundPage = false;
  //         break;
  //       }
  //     }
  //     if (foundPage) {
  //       ret = page;
  //       break;
  //     }
  //   }
  //   console.log("CHECK PAGE?", ret);
  //   return ret;
  // }
  // async init() {
  //   // this._documentSet = documentSet;
  //   // console.log("DOCUMENT SET ini?", documentSet);
  // }

  createPageKey(pageNumber) {
    return `${this.uid}-${pageNumber}`;
  }
  // async addPageInstance(page) {
  //   if (!page.number) throw new Error("Page number not found.");
  //   let pageKey = this.createPageKey(page.number);
  //   if (this.exists(pageKey)) return this.getInstance(pageKey);
  //   let document = new DocumentUiInstanceDomain(this, pageKey, page);
  //   await document.init();
  //   this.addInstance(pageKey, document);
  //   return document;
  // }
  pageInstanceExists({ number }) {
    if (!number) throw new Error("Page number not found.");
    let pageKey = this.createPageKey(number);
    return this.exists(pageKey);
  }
  updatePageInstance(page) {
    let pageKey = this.createPageKey(page.number);
    if (!this.exists(pageKey)) return false;
    let document = this.getInstance(pageKey);
    document.update(page);
    return document;
  }
  addPageInstance(page, options = {}) {
    if (!page.number) throw new Error("Page number not found.");

    let pageKey = this.createPageKey(page.number);
    if (this.exists(pageKey)) {
      let document = this.getInstance(pageKey);
      document.update(page);
      return document;
    }

    let document = new DocumentUiInstanceDomain(this, pageKey, page);
    document.init();
    this.addInstance(pageKey, document);
    return document;
  }
  removePageInstance(page) {
    if (!page.number) throw new Error("Page number not found.");
    // if (this.currentPageNumber === page.number) return false;
    let pageKey = this.createPageKey(page.number);
    this._documentSet.pages = this._documentSet.pages.filter(
      (p) => p.number !== page.number
    );
    return this.exists(pageKey) ? this.removeInstance(pageKey) : false;
  }
  async updatePageInstances(pages, options = {}) {
    // update the raw document set
    let pageKeys = this._documentSet.pages.map((page) =>
      this.createPageKey(page.number)
    );

    // Remove all the instances of the current pages
    options.reset &&
      this.pages.forEach((page) => this.removePageInstance(page));

    let updatedPages = pages
      .sort((a, b) => a.number > b.number)
      .map((page) => {
        let ret = null;
        if (this.pageInstanceExists(page)) ret = this.updatePageInstance(page);
        else {
          ret = this.addPageInstance(page, { remove: true });
          this._documentSet.pages.push(page);
        }

        // this.currentPage &&
        //   page.documentUuid === this.currentPage.documentUuid &&
        //   this.currentPage.update(page);

        return ret;
      });
    this._documentSet.pages.sort((a, b) => a.number > b.number);

    let pageNumber = options.pageNumber || null;
    pageNumber && this.navToPage(pageNumber, { refresh: true });
    // this.refreshCurrentAnnotation();
    return updatedPages;
  }

  updateAnnotationInstance(annotation) {
    if (!this.annotations.exists(annotation.uuid)) return false;
    let annotationInstance = this.getAnnotation(annotation.uuid);
    annotationInstance.updateInstance(annotation);
    return annotationInstance;
  }
  removeAnnotationInstance(annotation) {
    // Hacky fix to make sure this is the correct annotation
    if (this.annotations.exists(annotation.uuid)) {
      let currAnnotation = this.getAnnotation(annotation.uuid);
      if (currAnnotation.positionIndex === annotation.positionIndex)
        this.annotations.removeInstance(annotation.uuid);
    }
    // if (!page.number) throw new Error("Page number not found.");
    // // if (this.currentPageNumber === page.number) return false;
    // let pageKey = this.createPageKey(page.number);
    // this._documentSet.pages = this._documentSet.pages.filter(
    //   (p) => p.number !== page.number
    // );
    // return this.exists(pageKey) ? this.removeInstance(pageKey) : false;
    // this.annotations.exists(annotation.uuid) &&
    //   this.annotations.removeInstance(annotation.uuid);
  }

  async updateAnnotationInstances(annotations, options = {}) {
    // update the raw document set
    // let pageKeys = this._documentSet.pages.map((page) =>
    //   this.createPageKey(page.number)
    // );
    // const updatedAnnotations = annotations.map((annotation) =>
    //   this.annotations.exists(annotation.uuid)
    //     ? this.annotations.getInstance(annotation.uuid)
    //     : this.addAnnotationInstance(annotation)
    // );
    // Remove all the instances of the current pages
    // console.log("addPageData update instances");
    // this.annotations
    //   .filter((a) => a.type === "FIELD")
    //   .forEach((a) => console.log(a.documentPositionIndex));

    options.reset &&
      this.annotations
        .filter(
          (annotation) => annotation.type === constants.ANNOTATION_TYPE_FIELD
        )
        .forEach((annotation) => this.removeAnnotationInstance(annotation));

    let updatedAnnotations = annotations
      //.sort((a, b) => a.number > b.number)
      .map((annotation) => {
        let ret = null;
        if (this.annotations.exists(annotation.uuid))
          ret = this.updateAnnotationInstance(annotation);
        else if (
          this._currentAnnotation &&
          this._currentAnnotation.uuid === annotation.uuid
        ) {
          this._currentAnnotation.updateInstance(annotation);
          this.annotations.addInstance(
            this._currentAnnotation.uuid,
            this._currentAnnotation
          );
          ret = this._currentAnnotation;
        } else {
          ret = this.addAnnotationInstance(annotation);
        }
        // if (
        //   this._currentAnnotation &&
        //   this._currentAnnotation.uuid === ret.uuid
        // ) {
        //   console.log(
        //     "UPDATE CURRENT ANNOTATION!!!",
        //     this._currentAnnotation._tid,
        //     ret._tid
        //   );
        //   this._currentAnnotation = ret;
        // }

        return ret;
      });

    //this._documentSet.pages.sort((a, b) => a.number > b.number);

    // let pageNumber = options.pageNumber || null;
    // pageNumber && this.navToPage(pageNumber, { refresh: true });

    // this.refreshCurrentAnnotation();
    return updatedAnnotations;
  }
  addAnnotationInstance(annotation) {
    let changed = false;
    let offsetChanged = false;
    // Please check compareAnnotationValues, should it be annotation.values?
    if (
      annotation.updatedValue !== null &&
      annotation.updatedValue !== undefined &&
      annotation.updatedValue !== annotation.value
    )
      changed = true;
    else if (
      annotation.updatedValues &&
      !this.dm.manualEntry.compareAnnotationValues(
        annotation.updatedValues,
        annotation.values
      )
    )
      changed = true;
    let documentAnnotation = new DocumentSetAnnotationUiInstanceDomain(
      this.annotations,
      this,
      annotation.uuid,
      {
        uuid: annotation.uuid,
        name: annotation.name,
        label: annotation.label,
        type: annotation.type,
        fieldType: annotation.fieldType || null,
        description: annotation.description || null,
        readOnly: annotation.readOnly || false,
        value: changed ? annotation.updatedValue : annotation.value,
        valueSelections:
          annotation.valueSelections && annotation.valueSelections.length
            ? annotation.valueSelections
            : null,
        valueSelectionEditable:
          annotation.valueSelections &&
          annotation.valueSelections.length &&
          annotation.valueSelectionEditable
            ? true
            : false,
        // questionFields:
        //   annotation.questionFields && annotation.questionFields.length
        //     ? this.parseQuestionFields(annotation.questionFields)
        //     : null,
        values: annotation.updatedValues || annotation.values || null,
        selectionQuestionAnnotations:
          annotation.selectionQuestionAnnotations &&
          annotation.selectionQuestionAnnotations.length
            ? annotation.selectionQuestionAnnotations
            : null,
        selectionsAnnotationUuid: annotation.selectionsAnnotationUuid || null,
        selections:
          annotation.selections && annotation.selections.length
            ? annotation.selections
            : null,
        positionIndex: annotation.positionIndex,
        documentPositionIndex: annotation.documentPositionIndex,
        pageNumber: annotation.documentPositionIndex || null,
        documentUuid: annotation.documentUuid || null,
        offset: annotation.updatedOffset || annotation.offset || null,
        parentAnnotationUuid: annotation.parentAnnotationUuid,
        metadata: {
          changed,
          reject: annotation.reject || false,
          searchable: annotation.searchable || false,
          required: annotation.required || false,
          offsetRequired: annotation.offsetRequired || false,
          valid: annotation.valid || false,
          state:
            annotation.state ||
            (annotation.valid
              ? this.dm.manualEntry.STATE_REVIEW
              : this.dm.manualEntry.STATE_QUEUED),
          // state: this.dm.manualEntry.STATE_COMPLETE,
          // valid: true,
        },
      }
    );
    documentAnnotation.init();
    this.annotations.addInstance(annotation.uuid, documentAnnotation);
    return documentAnnotation;
  }
  addAnnotationInstances() {
    for (let annotation of this._documentSet.annotations) {
      this.addAnnotationInstance(annotation);
    }
  }
  clearPageInstances() {
    this.forEach((inst) => {
      inst.remove();
    });
  }
  initInitialValues() {
    let ret = {
      page: 1,
      annotation: { key: null, type: null },
    };
    let annotation = this.nextAnnotation;
    if (!annotation) {
      // There are no more annotations to complete
      // Just get the last visible annotation
      let annotations = this.findAnnotations({
        type: constants.ANNOTATION_TYPE_FIELD,
        sortBy: constants.SORT_BY_LAYOUT,
      });
      if (annotations.length) annotation = annotations[annotations.length - 1];
    }
    let page = this.getPage(annotation.pageNumber);
    ret = {
      page: page,
      annotationUuid: annotation.uuid,
    };
    return ret;
  }
}
class DocumentImageUiInstanceDomain extends R14.Domain {
  constructor(documentSet, document, image) {
    super();
    this._documentSet = documentSet;
    this._document = document;
    this._image = image;
  }
  update(image) {
    this._image = image;
  }
  get url() {}
}
class DocumentUiInstanceDomain extends R14.Domain {
  constructor(documentSet, key, page) {
    super();
    this.IMAGE_STORAGE_MODE_DATASET =
      this.dm.manualEntry.DOCUMENT_IMAGE_STORAGE_MODE_DATASET;
    this.IMAGE_STORAGE_MODE_STORAGE =
      this.dm.manualEntry.DOCUMENT_IMAGE_STORAGE_MODE_STORAGE;
    this._key = key;
    this._page = page;
    this._image = new DocumentImageUiInstanceDomain(
      documentSet,
      this,
      page.image
    );
    this._documentSet = documentSet;
    this._annotationLayout = null;
    this._padding = 32;
    this._scrollPosition = { x: 0, y: 0 };
    this._currAnnotationInitialized = false;
    this.documentRef = React.createRef();
    this._metadata = {
      state: this.dm.manualEntry.STATE_QUEUED,
    };
    this.state = {
      layout: false,
      height: 0,
      width: 0,
      scale: 1,
      zoomLevel: 1,
      annotate: false,
      annotationType: null,
      screenLayoutHeight: null,
      screenLayoutWidth: null,
      currAnnotation: null,
      rotate: page.image.rotate || 0,
      rotateUpdated: false,
      number: page.number,
      refresh: Date.now(),
      // selectionModeEnabled: false,
      // selectionOffset: null,
      // bottomSheetHeight: null,
      // bottomSheetMode:
      //   this.dm.documentTemplateAnnotation.BOTTOM_SHEET_SIZE_MODE_RESTORE,
    };
  }
  update(page) {
    this._page = page;
    let rotateState = this.state.rotate || 0;
    let imageRotate = page.image.rotate || 0;

    this.setState({
      rotate: rotateState || imageRotate,
      rotateUpdated: rotateState !== imageRotate,
    });
    this._image.update(page.image);
  }
  remove() {
    this._key && this._documentSet.removeInstance(this._key);
  }
  get rotateUpdated() {
    return this.state.rotateUpdated;
  }
  get key() {
    return this._key;
  }
  get number() {
    return this.state.number;
  }
  get selectionModeEnabled() {
    return this.state.selectionModeEnabled;
  }
  get selectionOffset() {
    return this.state.selectionOffset;
  }
  get documentUuid() {
    return this._page.documentUuid;
  }
  get imageStorageMode() {
    return this._documentSet.documentImageStorageMode;
  }
  get documentSet() {
    return this._documentSet;
  }
  get scale() {
    return this.state.scale;
  }
  get scrollPosition() {
    return this._scrollPosition;
  }
  get layout() {
    return this.state.layout;
  }
  get annotationType() {
    return this.state.annotationType;
  }
  get name() {
    return (
      this._page.name ||
      (this._page.manualEntryDocument
        ? this._page.manualEntryDocument.name
        : null)
    );
  }
  get project() {
    return this._page ? this._page.project : null;
  }
  get image() {
    return this._page ? this._page.image : null;
  }
  get bottomSheet() {
    return {
      height: this.state.bottomSheetHeight,
      mode: this.state.bottomSheetMode,
    };
  }
  get metadata() {
    return this._metadata;
  }
  get padding() {
    return this._padding;
  }
  get annotationLayout() {
    return this._annotationLayout;
  }
  get annotations() {
    return this._documentSet.findAnnotations({
      pageNumber: this.number,
      sortBy: constants.SORT_BY_LAYOUT,
    });
  }
  get currentAnnotation() {
    return this.state.currAnnotation &&
      this.state.currAnnotation.uuid &&
      this.state.currAnnotation.uuid === this._currentAnnotation.uuid
      ? this._currentAnnotation
      : null;
    // return this.state.currAnnotation
    //   ? this._documentSet.getAnnotation(this.state.currAnnotation.uuid)
    //   : null;
  }
  get currentAnnotationInitialized() {
    return this._currentAnnotationInitialized ? true : false;
  }
  get words() {
    return this._page.words;
    // return [
    //   {
    //     word: "BBV",
    //     offset: {
    //       width: 0.05064026266336441,
    //       height: 0.01684199459850788,
    //       left: 0.7157837152481079,
    //       top: 0.021097538992762566,
    //     },
    //   },
    //   {
    //     word: "076102",
    //     offset: {
    //       width: 0.09556716680526733,
    //       height: 0.019039811566472054,
    //       left: 0.7758773565292358,
    //       top: 0.019640833139419556,
    //     },
    //   },
    //   {
    //     word: "CERTIFIED",
    //     offset: {
    //       width: 0.12217694520950317,
    //       height: 0.01604567840695381,
    //       left: 0.26165878772735596,
    //       top: 0.049752864986658096,
    //     },
    //   },
    //   {
    //     word: "COPY",
    //     offset: {
    //       width: 0.06124873086810112,
    //       height: 0.014842617325484753,
    //       left: 0.39124494791030884,
    //       top: 0.048277318477630615,
    //     },
    //   },
    //   {
    //     word: "OF",
    //     offset: {
    //       width: 0.029694516211748123,
    //       height: 0.014270144514739513,
    //       left: 0.5455808639526367,
    //       top: 0.04694975167512894,
    //     },
    //   },
    //   {
    //     word: "AN",
    //     offset: {
    //       width: 0.03063112124800682,
    //       height: 0.013912751339375973,
    //       left: 0.5805994272232056,
    //       top: 0.046424202620983124,
    //     },
    //   },
    //   {
    //     word: "ENTRY",
    //     offset: {
    //       width: 0.07508417218923569,
    //       height: 0.014748919755220413,
    //       left: 0.6192387938499451,
    //       top: 0.044373173266649246,
    //     },
    //   },
    //   {
    //     word: "Pursuant",
    //     offset: {
    //       width: 0.07189421355724335,
    //       height: 0.013871022500097752,
    //       left: 0.24701711535453796,
    //       top: 0.07534146308898926,
    //     },
    //   },
    //   {
    //     word: "to",
    //     offset: {
    //       width: 0.016073381528258324,
    //       height: 0.011396273039281368,
    //       left: 0.3226338028907776,
    //       top: 0.07679373770952225,
    //     },
    //   },
    //   {
    //     word: "the",
    //     offset: {
    //       width: 0.025326354429125786,
    //       height: 0.012833011336624622,
    //       left: 0.3417876660823822,
    //       top: 0.07480243593454361,
    //     },
    //   },
    //   {
    //     word: "Births",
    //     offset: {
    //       width: 0.04849753528833389,
    //       height: 0.013460691086947918,
    //       left: 0.37090107798576355,
    //       top: 0.07305514067411423,
    //     },
    //   },
    //   {
    //     word: "and",
    //     offset: {
    //       width: 0.030260927975177765,
    //       height: 0.01308196596801281,
    //       left: 0.4224008023738861,
    //       top: 0.07274681329727173,
    //     },
    //   },
    //   {
    //     word: "Deaths",
    //     offset: {
    //       width: 0.05510986968874931,
    //       height: 0.013365819118916988,
    //       left: 0.5459651350975037,
    //       top: 0.07112564891576767,
    //     },
    //   },
    //   {
    //     word: "Registration",
    //     offset: {
    //       width: 0.09573756903409958,
    //       height: 0.017100311815738678,
    //       left: 0.6041844487190247,
    //       top: 0.06833022087812424,
    //     },
    //   },
    //   {
    //     word: "Act",
    //     offset: {
    //       width: 0.027628494426608086,
    //       height: 0.01323230005800724,
    //       left: 0.7033910751342773,
    //       top: 0.06773441284894943,
    //     },
    //   },
    //   {
    //     word: "1953",
    //     offset: {
    //       width: 0.037040192633867264,
    //       height: 0.01425840612500906,
    //       left: 0.735138475894928,
    //       top: 0.06678080558776855,
    //     },
    //   },
    //   {
    //     word: "a",
    //     offset: {
    //       width: 0.012005333788692951,
    //       height: 0.006767815910279751,
    //       left: 0.49352654814720154,
    //       top: 0.08754231780767441,
    //     },
    //   },
    //   {
    //     word: "NHS",
    //     offset: {
    //       width: 0.02958296611905098,
    //       height: 0.010889234021306038,
    //       left: 0.16246546804904938,
    //       top: 0.15337345004081726,
    //     },
    //   },
    //   {
    //     word: "Entry",
    //     offset: {
    //       width: 0.03235090151429176,
    //       height: 0.012914452701807022,
    //       left: 0.6688737273216248,
    //       top: 0.14976508915424347,
    //     },
    //   },
    //   {
    //     word: "Number",
    //     offset: {
    //       width: 0.04890937730669975,
    //       height: 0.011214683763682842,
    //       left: 0.1619684100151062,
    //       top: 0.16496963798999786,
    //     },
    //   },
    //   {
    //     word: "LRC92A",
    //     offset: {
    //       width: 0.13448971509933472,
    //       height: 0.022046662867069244,
    //       left: 0.22221805155277252,
    //       top: 0.15747866034507751,
    //     },
    //   },
    //   {
    //     word: "104",
    //     offset: {
    //       width: 0.051871005445718765,
    //       height: 0.02220667526125908,
    //       left: 0.3801599144935608,
    //       top: 0.15748007595539093,
    //     },
    //   },
    //   {
    //     word: "BIRTH",
    //     offset: {
    //       width: 0.06809618324041367,
    //       height: 0.016286637634038925,
    //       left: 0.45599982142448425,
    //       top: 0.1576414704322815,
    //     },
    //   },
    //   {
    //     word: "No.",
    //     offset: {
    //       width: 0.021631736308336258,
    //       height: 0.010803292505443096,
    //       left: 0.6694403886795044,
    //       top: 0.1621505618095398,
    //     },
    //   },
    //   {
    //     word: "104",
    //     offset: {
    //       width: 0.03983365744352341,
    //       height: 0.019187036901712418,
    //       left: 0.7326341867446899,
    //       top: 0.15394142270088196,
    //     },
    //   },
    //   {
    //     word: "Registration",
    //     offset: {
    //       width: 0.07225194573402405,
    //       height: 0.013709727674722672,
    //       left: 0.16021205484867096,
    //       top: 0.2009868025779724,
    //     },
    //   },
    //   {
    //     word: "district",
    //     offset: {
    //       width: 0.04229503870010376,
    //       height: 0.011617208831012249,
    //       left: 0.23455439507961273,
    //       top: 0.20107321441173553,
    //     },
    //   },
    //   {
    //     word: "Newham",
    //     offset: {
    //       width: 0.13796445727348328,
    //       height: 0.027069995179772377,
    //       left: 0.30666252970695496,
    //       top: 0.1900048553943634,
    //     },
    //   },
    //   {
    //     word: "Administrative",
    //     offset: {
    //       width: 0.08534248173236847,
    //       height: 0.011529511772096157,
    //       left: 0.6249771118164062,
    //       top: 0.18712036311626434,
    //     },
    //   },
    //   {
    //     word: "area",
    //     offset: {
    //       width: 0.02550365962088108,
    //       height: 0.009387596510350704,
    //       left: 0.7124900221824646,
    //       top: 0.18892638385295868,
    //     },
    //   },
    //   {
    //     word: "London",
    //     offset: {
    //       width: 0.12246988713741302,
    //       height: 0.022989939898252487,
    //       left: 0.5118761658668518,
    //       top: 0.19569751620292664,
    //     },
    //   },
    //   {
    //     word: "Borough",
    //     offset: {
    //       width: 0.13547267019748688,
    //       height: 0.029307624325156212,
    //       left: 0.6598839163780212,
    //       top: 0.19680190086364746,
    //     },
    //   },
    //   {
    //     word: "Sub-district",
    //     offset: {
    //       width: 0.07074611634016037,
    //       height: 0.011675002984702587,
    //       left: 0.1593010574579239,
    //       top: 0.22892367839813232,
    //     },
    //   },
    //   {
    //     word: "Newham",
    //     offset: {
    //       width: 0.14506466686725616,
    //       height: 0.028061838820576668,
    //       left: 0.30239468812942505,
    //       top: 0.21899622678756714,
    //     },
    //   },
    //   {
    //     word: "of",
    //     offset: {
    //       width: 0.03714161366224289,
    //       height: 0.030076049268245697,
    //       left: 0.616524338722229,
    //       top: 0.22413647174835205,
    //     },
    //   },
    //   {
    //     word: "Newham",
    //     offset: {
    //       width: 0.13480144739151,
    //       height: 0.023413192480802536,
    //       left: 0.6712996363639832,
    //       top: 0.22435212135314941,
    //     },
    //   },
    //   {
    //     word: "1.",
    //     offset: {
    //       width: 0.010404822416603565,
    //       height: 0.010363566689193249,
    //       left: 0.15834791958332062,
    //       top: 0.2514527440071106,
    //     },
    //   },
    //   {
    //     word: "Date",
    //     offset: {
    //       width: 0.02937180921435356,
    //       height: 0.011003030464053154,
    //       left: 0.17253777384757996,
    //       top: 0.2512243688106537,
    //     },
    //   },
    //   {
    //     word: "and",
    //     offset: {
    //       width: 0.023898407816886902,
    //       height: 0.010900053195655346,
    //       left: 0.2044023722410202,
    //       top: 0.2511669993400574,
    //     },
    //   },
    //   {
    //     word: "place",
    //     offset: {
    //       width: 0.032934460788965225,
    //       height: 0.012962596490979195,
    //       left: 0.23082119226455688,
    //       top: 0.2512235939502716,
    //     },
    //   },
    //   {
    //     word: "of",
    //     offset: {
    //       width: 0.013517613522708416,
    //       height: 0.010822221636772156,
    //       left: 0.2661246061325073,
    //       top: 0.2513952851295471,
    //     },
    //   },
    //   {
    //     word: "birth",
    //     offset: {
    //       width: 0.028215257450938225,
    //       height: 0.010753259062767029,
    //       left: 0.2822642922401428,
    //       top: 0.25137874484062195,
    //     },
    //   },
    //   {
    //     word: "CHILD",
    //     offset: {
    //       width: 0.057044193148612976,
    //       height: 0.014097423292696476,
    //       left: 0.46200093626976013,
    //       top: 0.25281786918640137,
    //     },
    //   },
    //   {
    //     word: "Twentysecond",
    //     offset: {
    //       width: 0.23438091576099396,
    //       height: 0.03485032543540001,
    //       left: 0.2835138738155365,
    //       top: 0.2614933252334595,
    //     },
    //   },
    //   {
    //     word: "October",
    //     offset: {
    //       width: 0.14174918830394745,
    //       height: 0.023340744897723198,
    //       left: 0.5421514511108398,
    //       top: 0.2648351192474365,
    //     },
    //   },
    //   {
    //     word: "1992",
    //     offset: {
    //       width: 0.0804520696401596,
    //       height: 0.02473442256450653,
    //       left: 0.7111189365386963,
    //       top: 0.26390010118484497,
    //     },
    //   },
    //   {
    //     word: "Newham",
    //     offset: {
    //       width: 0.15184058248996735,
    //       height: 0.025352774187922478,
    //       left: 0.2632800340652466,
    //       top: 0.2901797294616699,
    //     },
    //   },
    //   {
    //     word: "Hospital",
    //     offset: {
    //       width: 0.15158969163894653,
    //       height: 0.029973318800330162,
    //       left: 0.450785368680954,
    //       top: 0.293711394071579,
    //     },
    //   },
    //   {
    //     word: "Plaistow",
    //     offset: {
    //       width: 0.13738510012626648,
    //       height: 0.024157140403985977,
    //       left: 0.6275718808174133,
    //       top: 0.28884387016296387,
    //     },
    //   },
    //   {
    //     word: "2.",
    //     offset: {
    //       width: 0.012718917801976204,
    //       height: 0.01097611803561449,
    //       left: 0.15413761138916016,
    //       top: 0.3167608082294464,
    //     },
    //   },
    //   {
    //     word: "Name",
    //     offset: {
    //       width: 0.036239560693502426,
    //       height: 0.010863489471375942,
    //       left: 0.17086414992809296,
    //       top: 0.3168465495109558,
    //     },
    //   },
    //   {
    //     word: "and",
    //     offset: {
    //       width: 0.024317799136042595,
    //       height: 0.010642485693097115,
    //       left: 0.20955467224121094,
    //       top: 0.31679487228393555,
    //     },
    //   },
    //   {
    //     word: "surname",
    //     offset: {
    //       width: 0.05314207449555397,
    //       height: 0.009101244620978832,
    //       left: 0.23640519380569458,
    //       top: 0.3186952769756317,
    //     },
    //   },
    //   {
    //     word: "3.",
    //     offset: {
    //       width: 0.012067303992807865,
    //       height: 0.01112416759133339,
    //       left: 0.7204764485359192,
    //       top: 0.31575343012809753,
    //     },
    //   },
    //   {
    //     word: "Sex",
    //     offset: {
    //       width: 0.02376422844827175,
    //       height: 0.01126783899962902,
    //       left: 0.736362874507904,
    //       top: 0.3154903054237366,
    //     },
    //   },
    //   {
    //     word: "Sheyaa",
    //     offset: {
    //       width: 0.13764610886573792,
    //       height: 0.03887409344315529,
    //       left: 0.18649455904960632,
    //       top: 0.33267879486083984,
    //     },
    //   },
    //   {
    //     word: "Bin",
    //     offset: {
    //       width: 0.0671999454498291,
    //       height: 0.028148911893367767,
    //       left: 0.36662861704826355,
    //       top: 0.33670979738235474,
    //     },
    //   },
    //   {
    //     word: "ABRAHAM",
    //     offset: {
    //       width: 0.20119397342205048,
    //       height: 0.026880845427513123,
    //       left: 0.4789239764213562,
    //       top: 0.33547070622444153,
    //     },
    //   },
    //   {
    //     word: "male",
    //     offset: {
    //       width: 0.08171475678682327,
    //       height: 0.02271580509841442,
    //       left: 0.7213869690895081,
    //       top: 0.3358123004436493,
    //     },
    //   },
    //   {
    //     word: "4.",
    //     offset: {
    //       width: 0.011944690719246864,
    //       height: 0.010536082088947296,
    //       left: 0.15254102647304535,
    //       top: 0.3681095242500305,
    //     },
    //   },
    //   {
    //     word: "Name",
    //     offset: {
    //       width: 0.03604179248213768,
    //       height: 0.010703474283218384,
    //       left: 0.1690150946378708,
    //       top: 0.3679804801940918,
    //     },
    //   },
    //   {
    //     word: "and",
    //     offset: {
    //       width: 0.024244772270321846,
    //       height: 0.010579736903309822,
    //       left: 0.20765157043933868,
    //       top: 0.3676612079143524,
    //     },
    //   },
    //   {
    //     word: "surname",
    //     offset: {
    //       width: 0.05540267005562782,
    //       height: 0.009767056442797184,
    //       left: 0.23349878191947937,
    //       top: 0.3696593642234802,
    //     },
    //   },
    //   {
    //     word: "FATHER",
    //     offset: {
    //       width: 0.07061465084552765,
    //       height: 0.014648393727838993,
    //       left: 0.45622050762176514,
    //       top: 0.3695697784423828,
    //     },
    //   },
    //   {
    //     word: "Kevin",
    //     offset: {
    //       width: 0.10758677870035172,
    //       height: 0.0281778983771801,
    //       left: 0.22320401668548584,
    //       top: 0.3881446123123169,
    //     },
    //   },
    //   {
    //     word: "Cornelius",
    //     offset: {
    //       width: 0.17223477363586426,
    //       height: 0.025997363030910492,
    //       left: 0.3763122856616974,
    //       top: 0.39052966237068176,
    //     },
    //   },
    //   {
    //     word: "Emmons",
    //     offset: {
    //       width: 0.15763679146766663,
    //       height: 0.02386545017361641,
    //       left: 0.5935266017913818,
    //       top: 0.3904215097427368,
    //     },
    //   },
    //   {
    //     word: "5.",
    //     offset: {
    //       width: 0.011575253680348396,
    //       height: 0.010951885022222996,
    //       left: 0.15001711249351501,
    //       top: 0.41846418380737305,
    //     },
    //   },
    //   {
    //     word: "Place",
    //     offset: {
    //       width: 0.03351332247257233,
    //       height: 0.011303671635687351,
    //       left: 0.1664734035730362,
    //       top: 0.41813474893569946,
    //     },
    //   },
    //   {
    //     word: "of",
    //     offset: {
    //       width: 0.01413796842098236,
    //       height: 0.011357019655406475,
    //       left: 0.20264776051044464,
    //       top: 0.41786134243011475,
    //     },
    //   },
    //   {
    //     word: "birth",
    //     offset: {
    //       width: 0.029016902670264244,
    //       height: 0.01136157289147377,
    //       left: 0.21961162984371185,
    //       top: 0.41781213879585266,
    //     },
    //   },
    //   {
    //     word: "Lambeth",
    //     offset: {
    //       width: 0.1522311419248581,
    //       height: 0.02579651214182377,
    //       left: 0.419991135597229,
    //       top: 0.4249211847782135,
    //     },
    //   },
    //   {
    //     word: "6.",
    //     offset: {
    //       width: 0.01193477027118206,
    //       height: 0.0115006472915411,
    //       left: 0.1479061245918274,
    //       top: 0.4522203505039215,
    //     },
    //   },
    //   {
    //     word: "Occupation",
    //     offset: {
    //       width: 0.07236845046281815,
    //       height: 0.014173736795783043,
    //       left: 0.16434384882450104,
    //       top: 0.45184072852134705,
    //     },
    //   },
    //   {
    //     word: "Sales",
    //     offset: {
    //       width: 0.10109147429466248,
    //       height: 0.02394934929907322,
    //       left: 0.34705451130867004,
    //       top: 0.4738551080226898,
    //     },
    //   },
    //   {
    //     word: "Assistant",
    //     offset: {
    //       width: 0.17223411798477173,
    //       height: 0.027705153450369835,
    //       left: 0.49165794253349304,
    //       top: 0.47088420391082764,
    //     },
    //   },
    //   {
    //     word: "7.",
    //     offset: {
    //       width: 0.012978245504200459,
    //       height: 0.011620959267020226,
    //       left: 0.14452561736106873,
    //       top: 0.5031176805496216,
    //     },
    //   },
    //   {
    //     word: "Name",
    //     offset: {
    //       width: 0.036814961582422256,
    //       height: 0.011615583673119545,
    //       left: 0.162009596824646,
    //       top: 0.5031484961509705,
    //     },
    //   },
    //   {
    //     word: "and",
    //     offset: {
    //       width: 0.024716539308428764,
    //       height: 0.011507685296237469,
    //       left: 0.20193080604076385,
    //       top: 0.5030906200408936,
    //     },
    //   },
    //   {
    //     word: "surname",
    //     offset: {
    //       width: 0.05506832152605057,
    //       height: 0.010040830820798874,
    //       left: 0.22954513132572174,
    //       top: 0.5051475763320923,
    //     },
    //   },
    //   {
    //     word: "MOTHER",
    //     offset: {
    //       width: 0.08309236168861389,
    //       height: 0.014812321402132511,
    //       left: 0.45037320256233215,
    //       top: 0.5049074292182922,
    //     },
    //   },
    //   {
    //     word: "Heather",
    //     offset: {
    //       width: 0.1547672301530838,
    //       height: 0.02743859402835369,
    //       left: 0.20149554312229156,
    //       top: 0.5228404998779297,
    //     },
    //   },
    //   {
    //     word: "Carmillia",
    //     offset: {
    //       width: 0.1784093976020813,
    //       height: 0.027408340945839882,
    //       left: 0.4016174077987671,
    //       top: 0.5230674743652344,
    //     },
    //   },
    //   {
    //     word: "JOSEPH",
    //     offset: {
    //       width: 0.12659302353858948,
    //       height: 0.025812678039073944,
    //       left: 0.6194221377372742,
    //       top: 0.5245276689529419,
    //     },
    //   },
    //   {
    //     word: "8.",
    //     offset: {
    //       width: 0.012898735702037811,
    //       height: 0.01174396462738514,
    //       left: 0.14205260574817657,
    //       top: 0.5529032945632935,
    //     },
    //   },
    //   {
    //     word: "Place",
    //     offset: {
    //       width: 0.03415839746594429,
    //       height: 0.011857698671519756,
    //       left: 0.1597614586353302,
    //       top: 0.5528143048286438,
    //     },
    //   },
    //   {
    //     word: "of",
    //     offset: {
    //       width: 0.014644356444478035,
    //       height: 0.011772075667977333,
    //       left: 0.1967216283082962,
    //       top: 0.5526624917984009,
    //     },
    //   },
    //   {
    //     word: "birth",
    //     offset: {
    //       width: 0.029614325612783432,
    //       height: 0.011873673647642136,
    //       left: 0.2139049768447876,
    //       top: 0.5525915622711182,
    //     },
    //   },
    //   {
    //     word: "Plaistow",
    //     offset: {
    //       width: 0.15633147954940796,
    //       height: 0.027082301676273346,
    //       left: 0.38430705666542053,
    //       top: 0.558204710483551,
    //     },
    //   },
    //   {
    //     word: "Newham",
    //     offset: {
    //       width: 0.15504169464111328,
    //       height: 0.02656327374279499,
    //       left: 0.5736411213874817,
    //       top: 0.5589073896408081,
    //     },
    //   },
    //   {
    //     word: "9.(a)",
    //     offset: {
    //       width: 0.029872827231884003,
    //       height: 0.013105125166475773,
    //       left: 0.1402028203010559,
    //       top: 0.5883780121803284,
    //     },
    //   },
    //   {
    //     word: "Maiden",
    //     offset: {
    //       width: 0.048145946115255356,
    //       height: 0.012347805313766003,
    //       left: 0.1742577850818634,
    //       top: 0.5881414413452148,
    //     },
    //   },
    //   {
    //     word: "surname",
    //     offset: {
    //       width: 0.056138135492801666,
    //       height: 0.010486616753041744,
    //       left: 0.225100576877594,
    //       top: 0.5901607275009155,
    //     },
    //   },
    //   {
    //     word: "(b)",
    //     offset: {
    //       width: 0.01699816808104515,
    //       height: 0.014923195354640484,
    //       left: 0.5092010498046875,
    //       top: 0.5861506462097168,
    //     },
    //   },
    //   {
    //     word: "Surname",
    //     offset: {
    //       width: 0.057963985949754715,
    //       height: 0.011852405034005642,
    //       left: 0.5310588479042053,
    //       top: 0.5873249173164368,
    //     },
    //   },
    //   {
    //     word: "at",
    //     offset: {
    //       width: 0.013670913875102997,
    //       height: 0.01009164284914732,
    //       left: 0.5922727584838867,
    //       top: 0.5888748168945312,
    //     },
    //   },
    //   {
    //     word: "marriage",
    //     offset: {
    //       width: 0.055620625615119934,
    //       height: 0.013436934910714626,
    //       left: 0.6086934208869934,
    //       top: 0.5877450108528137,
    //     },
    //   },
    //   {
    //     word: "if",
    //     offset: {
    //       width: 0.009287440218031406,
    //       height: 0.010916194878518581,
    //       left: 0.5302102565765381,
    //       top: 0.6011200547218323,
    //     },
    //   },
    //   {
    //     word: "different",
    //     offset: {
    //       width: 0.054598208516836166,
    //       height: 0.012676001526415348,
    //       left: 0.5416513085365295,
    //       top: 0.6004066467285156,
    //     },
    //   },
    //   {
    //     word: "from",
    //     offset: {
    //       width: 0.030230598524212837,
    //       height: 0.011422949843108654,
    //       left: 0.5986477732658386,
    //       top: 0.6009308695793152,
    //     },
    //   },
    //   {
    //     word: "1",
    //     offset: {
    //       width: 0.03829377517104149,
    //       height: 0.006025136448442936,
    //       left: 0.6907437443733215,
    //       top: 0.6026572585105896,
    //     },
    //   },
    //   {
    //     word: "maiden",
    //     offset: {
    //       width: 0.04725578799843788,
    //       height: 0.011613573879003525,
    //       left: 0.5302495956420898,
    //       top: 0.6145265102386475,
    //     },
    //   },
    //   {
    //     word: "surname",
    //     offset: {
    //       width: 0.05593172833323479,
    //       height: 0.009177226573228836,
    //       left: 0.5804290175437927,
    //       top: 0.6169503927230835,
    //     },
    //   },
    //   {
    //     word: "10.",
    //     offset: {
    //       width: 0.018379397690296173,
    //       height: 0.011957396753132343,
    //       left: 0.1392693966627121,
    //       top: 0.6339496970176697,
    //     },
    //   },
    //   {
    //     word: "Usual",
    //     offset: {
    //       width: 0.037241045385599136,
    //       height: 0.01215054839849472,
    //       left: 0.1616232693195343,
    //       top: 0.6335943937301636,
    //     },
    //   },
    //   {
    //     word: "address",
    //     offset: {
    //       width: 0.04979289323091507,
    //       height: 0.012087752111256123,
    //       left: 0.20129777491092682,
    //       top: 0.6334500908851624,
    //     },
    //   },
    //   {
    //     word: "(if",
    //     offset: {
    //       width: 0.014398141764104366,
    //       height: 0.012959353625774384,
    //       left: 0.160813570022583,
    //       top: 0.6470440626144409,
    //     },
    //   },
    //   {
    //     word: "different",
    //     offset: {
    //       width: 0.055501095950603485,
    //       height: 0.013039030134677887,
    //       left: 0.17711858451366425,
    //       top: 0.6465504169464111,
    //     },
    //   },
    //   {
    //     word: "from",
    //     offset: {
    //       width: 0.03104489855468273,
    //       height: 0.011948948726058006,
    //       left: 0.2352791726589203,
    //       top: 0.6467544436454773,
    //     },
    //   },
    //   {
    //     word: "place",
    //     offset: {
    //       width: 0.0353788360953331,
    //       height: 0.014489862136542797,
    //       left: 0.15942847728729248,
    //       top: 0.6611281633377075,
    //     },
    //   },
    //   {
    //     word: "of",
    //     offset: {
    //       width: 0.014573212713003159,
    //       height: 0.011947193183004856,
    //       left: 0.19772471487522125,
    //       top: 0.6607922911643982,
    //     },
    //   },
    //   {
    //     word: "child's",
    //     offset: {
    //       width: 0.041040025651454926,
    //       height: 0.012034677900373936,
    //       left: 0.21531569957733154,
    //       top: 0.6605157852172852,
    //     },
    //   },
    //   {
    //     word: "birth)",
    //     offset: {
    //       width: 0.03531772270798683,
    //       height: 0.013167990371584892,
    //       left: 0.25945156812667847,
    //       top: 0.6599836945533752,
    //     },
    //   },
    //   {
    //     word: "INFORMANT",
    //     offset: {
    //       width: 0.12459688633680344,
    //       height: 0.015576698817312717,
    //       left: 0.43034088611602783,
    //       top: 0.6879603862762451,
    //     },
    //   },
    //   {
    //     word: "11.",
    //     offset: {
    //       width: 0.01672280579805374,
    //       height: 0.012208990752696991,
    //       left: 0.13521304726600647,
    //       top: 0.710147500038147,
    //     },
    //   },
    //   {
    //     word: "Name",
    //     offset: {
    //       width: 0.039003800600767136,
    //       height: 0.012204162776470184,
    //       left: 0.1561402827501297,
    //       top: 0.7101030349731445,
    //     },
    //   },
    //   {
    //     word: "and",
    //     offset: {
    //       width: 0.02587297558784485,
    //       height: 0.012149788439273834,
    //       left: 0.1978342980146408,
    //       top: 0.7097269892692566,
    //     },
    //   },
    //   {
    //     word: "surname",
    //     offset: {
    //       width: 0.05701115354895592,
    //       height: 0.01048538088798523,
    //       left: 0.22632451355457306,
    //       top: 0.7114161849021912,
    //     },
    //   },
    //   {
    //     word: "(if",
    //     offset: {
    //       width: 0.014791803434491158,
    //       height: 0.013265791349112988,
    //       left: 0.2860422134399414,
    //       top: 0.7088727951049805,
    //     },
    //   },
    //   {
    //     word: "not",
    //     offset: {
    //       width: 0.02233661524951458,
    //       height: 0.010819469578564167,
    //       left: 0.30344879627227783,
    //       top: 0.7101882696151733,
    //     },
    //   },
    //   {
    //     word: "the",
    //     offset: {
    //       width: 0.022261077538132668,
    //       height: 0.011841872707009315,
    //       left: 0.32856863737106323,
    //       top: 0.7090849876403809,
    //     },
    //   },
    //   {
    //     word: "mother",
    //     offset: {
    //       width: 0.047435056418180466,
    //       height: 0.012123784981667995,
    //       left: 0.353240966796875,
    //       top: 0.7087073922157288,
    //     },
    //   },
    //   {
    //     word: "or",
    //     offset: {
    //       width: 0.014572396874427795,
    //       height: 0.00930522195994854,
    //       left: 0.40349411964416504,
    //       top: 0.7111148238182068,
    //     },
    //   },
    //   {
    //     word: "father)",
    //     offset: {
    //       width: 0.042779743671417236,
    //       height: 0.013267828151583672,
    //       left: 0.4206009805202484,
    //       top: 0.708102285861969,
    //     },
    //   },
    //   {
    //     word: "12.",
    //     offset: {
    //       width: 0.01850595511496067,
    //       height: 0.012104079127311707,
    //       left: 0.5122166872024536,
    //       top: 0.7083362936973572,
    //     },
    //   },
    //   {
    //     word: "Qualification",
    //     offset: {
    //       width: 0.08184814453125,
    //       height: 0.014634470455348492,
    //       left: 0.5340895056724548,
    //       top: 0.7076777815818787,
    //     },
    //   },
    //   {
    //     word: "Father",
    //     offset: {
    //       width: 0.12058816850185394,
    //       height: 0.025300798937678337,
    //       left: 0.5506788492202759,
    //       top: 0.7220972180366516,
    //     },
    //   },
    //   {
    //     word: "Mother",
    //     offset: {
    //       width: 0.11845789104700089,
    //       height: 0.022326117381453514,
    //       left: 0.6970056295394897,
    //       top: 0.722510814666748,
    //     },
    //   },
    //   {
    //     word: "15.",
    //     offset: {
    //       width: 0.01850890927016735,
    //       height: 0.012922551482915878,
    //       left: 0.12644122540950775,
    //       top: 0.8809035420417786,
    //     },
    //   },
    //   {
    //     word: "Date",
    //     offset: {
    //       width: 0.031938888132572174,
    //       height: 0.012932770885527134,
    //       left: 0.1496368795633316,
    //       top: 0.8806514739990234,
    //     },
    //   },
    //   {
    //     word: "of",
    //     offset: {
    //       width: 0.015303616411983967,
    //       height: 0.012998097576200962,
    //       left: 0.18463890254497528,
    //       top: 0.8801303505897522,
    //     },
    //   },
    //   {
    //     word: "registration",
    //     offset: {
    //       width: 0.07531421631574631,
    //       height: 0.015504393726587296,
    //       left: 0.2028028815984726,
    //       top: 0.879765510559082,
    //     },
    //   },
    //   {
    //     word: "Eighteenth",
    //     offset: {
    //       width: 0.14883200824260712,
    //       height: 0.03994902968406677,
    //       left: 0.1184249222278595,
    //       top: 0.9073063135147095,
    //     },
    //   },
    //   {
    //     word: "November",
    //     offset: {
    //       width: 0.1292751431465149,
    //       height: 0.03470836207270622,
    //       left: 0.2879026234149933,
    //       top: 0.900571346282959,
    //     },
    //   },
    //   {
    //     word: "1992",
    //     offset: {
    //       width: 0.061819061636924744,
    //       height: 0.03050203248858452,
    //       left: 0.4389188587665558,
    //       top: 0.9016368985176086,
    //     },
    //   },
    //   {
    //     word: "17.",
    //     offset: {
    //       width: 0.01858586072921753,
    //       height: 0.012936130166053772,
    //       left: 0.12288913875818253,
    //       top: 0.9431620240211487,
    //     },
    //   },
    //   {
    //     word: "Name",
    //     offset: {
    //       width: 0.03971252962946892,
    //       height: 0.012894626706838608,
    //       left: 0.1467699259519577,
    //       top: 0.9426825642585754,
    //     },
    //   },
    //   {
    //     word: "given",
    //     offset: {
    //       width: 0.03740552067756653,
    //       height: 0.01567293331027031,
    //       left: 0.1898004114627838,
    //       top: 0.9418478608131409,
    //     },
    //   },
    //   {
    //     word: "after",
    //     offset: {
    //       width: 0.03304021432995796,
    //       height: 0.013215608894824982,
    //       left: 0.14434823393821716,
    //       top: 0.9579095244407654,
    //     },
    //   },
    //   {
    //     word: "registration,",
    //     offset: {
    //       width: 0.07988634705543518,
    //       height: 0.015796922147274017,
    //       left: 0.18046551942825317,
    //       top: 0.956932544708252,
    //     },
    //   },
    //   {
    //     word: "and",
    //     offset: {
    //       width: 0.027136575430631638,
    //       height: 0.013312196359038353,
    //       left: 0.14372625946998596,
    //       top: 0.972968339920044,
    //     },
    //   },
    //   {
    //     word: "surname",
    //     offset: {
    //       width: 0.059756357222795486,
    //       height: 0.010185514576733112,
    //       left: 0.17361383140087128,
    //       top: 0.9754606485366821,
    //     },
    //   },
    //   {
    //     word: "MailOnline",
    //     offset: {
    //       width: 0.06216619536280632,
    //       height: 0.01187856774777174,
    //       left: 0.018502917140722275,
    //       top: 0.986918032169342,
    //     },
    //   },
    // ];
  }
  setMetadata(metadata) {
    this._metadata = metadata;
  }
  handleComponentMount() {
    this.initCurrentAnnotation();
  }
  handleComponentUpdate() {
    this.initCurrentAnnotation();
  }
  initCurrentAnnotation(options = {}) {
    // If the page is not the same as the actual current annotation
    // Disable offest selection mode

    if (
      !this.currentAnnotation ||
      !this.currentAnnotation.inPage(this.number) ||
      (!options.force && this.currentAnnotationInitialized)
    )
      return true;

    let annotation = this.currentAnnotation;

    if (!this.documentRef || !this.documentRef.current) return false;
    let pageHeight = this.state.height;
    let pageWidth = this.state.width;

    if (!pageHeight || !pageWidth) return false;

    // let size = this.calculateAnnotationSize(annotation);
    let pos = annotation.sizeExists
      ? this.calculateAnnotationPosition(annotation)
      : annotation.parentAnnotation && annotation.parentAnnotation.sizeExists
      ? this.calculateAnnotationPosition(annotation.parentAnnotation)
      : null;

    // let scrollPos = this.scrollPosition;

    if (pos !== null) {
      //   let width =
      //   event.nativeEvent && event.nativeEvent.layout
      //     ? event.nativeEvent.layout.width
      //     : null;
      // let imageWidth = this.document.image.width;
      // let imageHeight = this.document.image.height;
      // if (!width || !imageWidth || !imageHeight) return false;

      // // Add 16 pixels padding
      // let annotationWidth = annotation.width * imageWidth + 32;
      // let annotationHeight = annotation.height * imageHeight + 32;
      // let scrollTo = {};

      // let annotationPreviewScale = width / annotationWidth;

      // let annotationPreviewInitialScroll = {
      //   x: annotation.left * annotationPreviewScale * imageWidth + 16,
      //   y: annotation.top * annotationPreviewScale * imageHeight + 16,
      //   animated: false,
      // };
      // setTimeout(async () => {
      //   this.documentRef &&
      //     this.documentRef.current &&
      //     this.documentRef.current.scrollTo(annotationPreviewInitialScroll);
      // }, 100);
      // this.setState({ annotationPreviewScale, annotationPreviewInitialScroll });

      let top = pos.top * this.state.zoomLevel + this.padding;
      // let right = pos.right * this.state.zoomLevel + this.padding;
      // let bottom = pos.bottom * this.state.zoomLevel + this.padding;
      let left = pos.left * this.state.zoomLevel + this.padding;

      // let shouldScroll =
      //   top < scrollPos.y ||
      //   bottom > scrollPos.y + pageHeight ||
      //   left < scrollPos.x ||
      //   right > scrollPos.x + pageWidth;
      this.scrollTo({
        x: left - this.padding,
        y: top - this.padding,
        // animated: true,
        animated: false,
      });
    }

    this._currentAnnotationInitialized = true;
  }
  isFullPageAnnotation(annotation) {
    return (
      annotation.sizeExists &&
      annotation.top === 0 &&
      annotation.left === 0 &&
      annotation.height === 1 &&
      annotation.width === 1
    );
  }
  isCurrentAnnotation(annotation) {
    return this.currentAnnotation &&
      this.currentAnnotation.uuid === annotation.uuid
      ? true
      : false;
  }
  setCurrentAnnotation(annotation) {
    if (this.isCurrentAnnotation(annotation)) return true;
    // Should it replace the current annotation?
    let currAnnotation = null;
    if (annotation.inPage(this.number))
      currAnnotation = {
        uuid: annotation.uuid,
        name: annotation.name,
        documentUuid: annotation.documentUuid,
      };
    this._currentAnnotationInitialized = false;
    this._currentAnnotation = annotation;
    this.setState({
      currAnnotation,
    });
    return true;
  }

  init() {
    // throw new Error("HERE");
  }
  getAnnotationOffset(annotation) {
    // Check if it is current annotation and is in the form
    let offset = null;
    if (this.isCurrentAnnotation(annotation)) {
      // Check for the offset info
      let form = this.ui.form("documentAnnotationForm");
      if (form && form.elmts.offset) offset = form.elmts.offset.value;
    }
    if (!offset) offset = annotation.offset;
    return offset;
  }
  calculateAnnotationPosition(annotation) {
    const offset = this.getAnnotationOffset(annotation);
    if (!offset) return null;
    let size = this.calculateAnnotationSize(annotation);
    let top = offset.top * this.image.height * this.state.scale;
    let left = offset.left * this.image.width * this.state.scale;
    return {
      top,
      left,
      bottom: top + size.height,
      right: left + size.width,
    };
  }

  calculateAnnotationSize(annotation) {
    const offset = this.getAnnotationOffset(annotation);
    if (!offset) return null;
    return {
      width: offset.width * this.image.width * this.state.scale,
      height: offset.height * this.image.height * this.state.scale,
    };
  }
  calculateScale(height, width, mode) {
    let scale = 1;
    let imageHeight = this._page.image.height;
    let imageWidth = this._page.image.width;

    if (height && width && imageWidth && imageHeight) {
      switch (mode) {
        case this.dm.manualEntry.DOCUMENT_IMAGE_INITIAL_SCALE_MODE_COVER:
          scale = width / imageWidth;
          break;
        case this.dm.manualEntry.DOCUMENT_IMAGE_INITIAL_SCALE_MODE_CONTAIN:
          if (imageHeight > imageWidth) {
            scale = height / imageHeight;
            if (imageWidth * scale > width) scale = width / imageWidth;
          } else {
            scale = width / imageWidth;
            if (imageHeight * scale > height) scale = height / imageHeight;
          }
          break;
      }
    }

    return scale;
  }
  scrollTo(params) {
    this.documentRef.current && this.documentRef.current.scrollTo(params);
  }
  setAnnotationLayout(annotationLayout) {
    this._annotationLayout = annotationLayout;
  }
  getAnnotationLayout() {
    return this._annotationLayout;
  }
  setAnnotationMode(annotationType) {
    this.setState({
      annotate: annotationType ? true : false,
      annotationType: annotationType || null,
    });
    this._annotationLayout = null;
  }
  disableAnnotationMode() {
    this.setAnnotationMode(null);
  }
  updateScrollViewLayout(layout) {
    let { height, width } = layout;

    // Scale available based on padding
    height = height - this.padding * 2;
    width = width - this.padding * 2;

    this.setState({
      layout: true,
      height,
      width,
      scale: this.calculateScale(
        height,
        width,
        this.documentSet.initialDocumentScaleMode
      ),
      //prevScale: this.state.scale
    });
  }
  updateScroll(scrollValues) {
    this._scrollPosition = scrollValues;
  }
  updateScreenLayout({ height, width }) {
    let nState = {
      screenLayoutHeight: height,
      screenLayoutWidth: width,
    };
    if (!this.state.bottomSheetMode) {
      // Init the bottom sheet
      screenLayoutHeight: {
      }
      // nState.bottomSheetMode =
      //   this.dm.documentTemplateAnnotation.BOTTOM_SHEET_SIZE_MODE_RESTORE;
      // nState.bottomSheetHeight = height / 3;
    }
    this.setState(nState);
  }
  setBottomSheetHeight(height) {
    this.setState({
      bottomSheetHeight: height,
    });
  }
  setZoomLevel(zoomLevel) {
    this.setState({
      zoomLevel,
    });
  }
  zoomIn() {
    let zoomLevel = this.state.zoomLevel;
    // zoomLevel += 0.1;
    // if (zoomLevel < 2) this.setZoomLevel(zoomLevel);

    zoomLevel += 0.2;
    this.setZoomLevel(zoomLevel);
  }
  zoomOut() {
    let zoomLevel = this.state.zoomLevel;
    // zoomLevel -= 0.1;
    // if (zoomLevel > -2) this.setZoomLevel(zoomLevel);
    zoomLevel -= 0.2;
    this.setZoomLevel(zoomLevel);
  }
  zoomReset() {
    this.setZoomLevel(1);
    this.initCurrentAnnotation({ force: true });
  }
  scrollInDirection(direction, amount = 10) {
    let { x, y } = this._scrollPosition;
    switch (direction) {
      case "up":
        y -= amount;
        break;
      case "down":
        y += amount;
        break;
      case "left":
        x -= amount;
        break;
      case "right":
        x += amount;
        break;
    }
    this.scrollTo({ x, y });
  }
  scrollUp() {
    this.scrollInDirection("up");
  }
  scrollDown() {
    this.scrollInDirection("down");
  }
  scrollLeft() {
    this.scrollInDirection("left");
  }
  scrollRight() {
    this.scrollInDirection("right");
  }
  rotateRight() {
    return this._rotate(90);
  }
  rotateLeft() {
    return this._rotate(-90);
  }
  _rotate(amount) {
    let rotate = this.state.rotate || 0;
    rotate += amount;
    if (rotate < -270) rotate = 360 + (rotate % 360);
    if (rotate > 270) rotate = rotate % 360;
    let rotateUpdated =
      this._documentSet.hasAppFeature("enableDocumentRotate") &&
      rotate !== this._page.image.rotate;
    this.setState({ rotate: rotate, rotateUpdated });
  }
  forceRefresh() {
    // console.log("DEV MODE: Force refresh being used. Please remove.");
    // this.setState({ refresh: Date.now() });
  }
  // updateSelectionOffset(selectionOffset) {
  //   this.setState({ selectionOffset });
  // }
  // handleSelectAnnotationArea(selectArea) {
  //   let layout = null;
  //   if (selectArea) {
  //     let { x, y, height, width } = selectArea;
  //     let imageScaleHeight =
  //       this.image.height * this.state.scale * this.state.zoomLevel;
  //     let imageScaleWidth =
  //       this.image.width * this.state.scale * this.state.zoomLevel;
  //     layout = {
  //       x: x / imageScaleWidth,
  //       y: y / imageScaleHeight,
  //       height: height / imageScaleHeight,
  //       width: width / imageScaleWidth,
  //     };
  //   }
  //   this.setAnnotationLayout(layout);
  // }
  getAnnotationColorByType(type) {
    return this.dm.documentTemplateAnnotation.getColorByType(type);
  }
  toggleSelectionMode() {
    const selectionModeEnabled = !this.state.selectionModeEnabled;
    const selectionOffset = selectionModeEnabled
      ? this.currentAnnotation.offset
      : null;
    this.setState({
      selectionModeEnabled,
      selectionOffset,
    });
  }
}
class DocumentSetAnnotationsUiDomain extends R14.DomainInstances {
  constructor(documentsSet) {
    super();
    this._documentSet = documentsSet;
  }
  async instance(uuid, options = {}) {
    if (this.exists(uuid)) return this.getInstance(uuid);
    throw new Error("Error finding instance?");
    // let DocumentSetAnnotation = new DocumentSetAnnotationUiInstanceDomain(
    //   this,
    //   this._documentSet,
    //   uuid,
    //   options
    // );
    // await DocumentSetAnnotation.init();
    // this.addInstance(uuid, DocumentSetAnnotation);
    // return DocumentSetAnnotation;
  }
  clearInstances() {
    this.forEach((inst) => {
      inst.remove();
    });
  }
}
class DocumentSetAnnotationUiInstanceDomain extends R14.Domain {
  constructor(annotations, documentSet, uuid, annotation = {}) {
    super();
    this._annotation = annotation;
    this._annotations = annotations;
    this._documentSet = documentSet;
    this._uuid = uuid;
    this._type = annotation.type || null;
    this._name = annotation.name || null;
    this._label = annotation.label || null;

    let value = annotation.value || null;
    // let random = Math.random() >= 0.5;
    // if (random)
    //   value =
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

    this._value = value || null;
    this._fieldType = annotation.fieldType || null;
    this._description = annotation.description || null;
    this._readOnly = annotation.readOnly || false;

    // annotation.metadata.state = "COMPLETE";
    // this._readOnly = true;

    this._parentAnnotationUuid = annotation.parentAnnotationUuid || null;
    // this._sizeExists = annotation.offset ? true : false;
    this._valueSelections =
      annotation.valueSelections && annotation.valueSelections.length
        ? annotation.valueSelections
        : null;
    this._valueSelectionEditable = annotation.valueSelectionEditable || false;

    this._selectionQuestionAnnotations =
      annotation.selectionQuestionAnnotations &&
      annotation.selectionQuestionAnnotations.length
        ? annotation.selectionQuestionAnnotations
        : null;

    this._selectionsAnnotationUuid =
      annotation.selectionsAnnotationUuid || null;
    // this._questionFields =
    //   annotation.questionFields && annotation.questionFields.length
    //     ? annotation.questionFields
    //     : null;
    this._values =
      annotation.values && annotation.values.length ? annotation.values : null;
    this._editForm = null;
    this._documentUuid = annotation.documentUuid;
    this._positionIndex = annotation.positionIndex;
    this._documentPositionIndex = annotation.documentPositionIndex;
    this._pageNumbers =
      annotation.pageNumbers || annotation.pageNumber
        ? [annotation.pageNumber]
        : [];
    this.state = {
      offset: annotation.offset || null,
      metadata: annotation.metadata || {},
      editFormExists: false,
    };
  }
  updateInstance(annotation) {
    this._documentUuid = annotation.documentUuid;
    this._positionIndex = annotation.positionIndex;
    this._documentPositionIndex = annotation.documentPositionIndex;
    let pageNumbers = [];
    if (annotation.pageNumbers) pageNumbers = annotation.pageNumbers;
    else if (annotation.pageNumber) pageNumbers.push(annotation.pageNumber);
    else if (annotation.documentPositionIndex)
      pageNumbers.push(annotation.documentPositionIndex);
    if (pageNumbers.length) this._pageNumbers = pageNumbers;
    return this;
  }
  init() {}
  // const createFieldKey = (field) =>
  // `_mdsdqf_${field.path
  //   .map((uuid) => annotationUUidKeyMap[uuid])
  //   .filter((key) => (key ? true : false))
  //   .join("-")}`;
  generateQuestionFields(
    questionFields = null,
    parentField = null,
    selectionsAnnotationsUuidMap = null,
    fieldUuidKeyMap = null
  ) {
    let ret = [];
    const createFieldKey = (field) =>
      `_mdsdqf-${field.path
        .map((uuid) => fieldUuidKeyMap[uuid])
        .filter((key) => (key ? true : false))
        .join("-")}`;
    if (!questionFields)
      questionFields = this._annotations
        .filter(
          (annotation) =>
            [
              //constants.ANNOTATION_TYPE_SELECTIONS,
              constants.ANNOTATION_TYPE_QUESTION,
            ].includes(annotation.type) ||
            (annotation.type === constants.ANNOTATION_TYPE_FIELD &&
              annotation.uuid === this.uuid)
        )
        .map((annotation) => annotation._annotation);
    if (!selectionsAnnotationsUuidMap)
      selectionsAnnotationsUuidMap = this.documentSet
        .findAnnotations({
          type: constants.ANNOTATION_TYPE_SELECTIONS,
        })
        .reduce(
          (ret, annotation) => ({
            ...ret,
            [annotation.uuid]: annotation._annotation,
          }),
          {}
        );
    if (!fieldUuidKeyMap)
      fieldUuidKeyMap = questionFields.reduce(
        (ret, field, idx) =>
          field.uuid in ret ? ret : { ...ret, [field.uuid]: `a${idx}` },
        {}
      );

    ret = questionFields
      .filter((field) =>
        parentField
          ? parentField.selectionQuestionAnnotations.some(
              ({ questionAnnotationUuids }) =>
                questionAnnotationUuids.includes(field.uuid)
            )
          : field.uuid === this.uuid &&
            field.type === constants.ANNOTATION_TYPE_FIELD
      )
      .map((annotation) => {
        let field = {
          uuid: annotation.uuid,
          required: annotation.metadata.required || false,
          offsetRequired: annotation.metadata.offsetRequired || false,
          searchable: annotation.metadata.searchable || false,
          label: annotation.label,
          type: annotation.type,
          fieldType: annotation.fieldType,
          offset: annotation.offset,
          path: parentField
            ? [...parentField.path, annotation.uuid]
            : [annotation.uuid],
          selectionQuestionAnnotations: annotation.selectionQuestionAnnotations,
          selectionsAnnotationUuid: annotation.selectionsAnnotationUuid,
        };
        // Create the unique field key
        field.key = createFieldKey(field);
        field.parentKey = parentField ? parentField.key : null;
        // Add selections
        if (
          annotation.selectionsAnnotationUuid &&
          selectionsAnnotationsUuidMap[annotation.selectionsAnnotationUuid]
        ) {
          field.selections = selectionsAnnotationsUuidMap[
            annotation.selectionsAnnotationUuid
          ].selections.map((selection, idx) => ({
            ...selection,
            key: `${field.key}-s${idx}`,
          }));
        }
        let parentSelectionQuestionAnnotations = parentField
          ? parentField.selectionQuestionAnnotations.find(
              ({ questionAnnotationUuids }) =>
                questionAnnotationUuids.includes(field.uuid)
            )
          : null;
        if (parentSelectionQuestionAnnotations) {
          let parentSelection = parentField.selections.find(
            ({ uuid }) =>
              uuid === parentSelectionQuestionAnnotations.selectionUuid
          );
          if (parentSelection) field.parentSelectionKey = parentSelection.key;
        }

        field.questionFields = field.selectionQuestionAnnotations
          ? this.generateQuestionFields(
              questionFields,
              field,
              selectionsAnnotationsUuidMap,
              fieldUuidKeyMap
            )
          : [];
        field.childKeys = [];
        field.questionFields.forEach(
          (childField) =>
            !field.childKeys.includes(childField.key) &&
            field.childKeys.push(childField.key, ...childField.childKeys)
        );
        return field;
      });

    return ret;
  }
  // parseQuestionFields(questionFields, parentField = null) {
  //   const getChildSelectionUids = (selection) => {
  //     let childUuids = [];
  //     questionFields.forEach((field) => {
  //       if (field.selections && field.parentSelectionUuid === selection.uuid) {
  //         field.selections.forEach((childSelection) => {
  //           childUuids.push(
  //             childSelection.uuid,
  //             ...getChildSelectionUids(childSelection)
  //           );
  //         });
  //       }
  //     });
  //     return childUuids;
  //   };

  //   return questionFields
  //     .filter((field) =>
  //       parentField
  //         ? parentField.selections.some(
  //             (uuid) => uuid === field.parentSelectionUuid
  //           )
  //         : !field.parentSelectionUuid
  //     )
  //     .map((field) => {
  //       field.questionFields = this.parseQuestionFields(questionFields, field);
  //       field.childUuids = [];
  //       field.questionFields.forEach((childField) =>
  //         field.childUuids.push(childField.uuid, ...childField.childUuids)
  //       );
  //       if (parentField) field.parentUuid = parentField.uuid;
  //       // if (field.selections)
  //       //   field.selections = field.selections.map((selection) => {
  //       //     selection.childUuids = getChildSelectionUids(selection);
  //       //     return selection;
  //       //   });
  //       return field;
  //     });
  // }
  get uuid() {
    return this._uuid;
  }
  get positionIndex() {
    return this._positionIndex;
  }
  get documentPositionIndex() {
    return this._documentPositionIndex;
  }
  set positionIndex(positionIndex) {
    this._positionIndex = positionIndex;
    return this;
  }
  set documentPositionIndex(documentPositionIndex) {
    this._documentPositionIndex = documentPositionIndex;
    return this;
  }
  get parentAnnotationUuid() {
    return this._parentAnnotationUuid;
  }
  get parentAnnotation() {
    return this.parentAnnotationUuid
      ? this.documentSet
          .findAnnotations({
            parentAnnotationUuid: this.parentAnnotationUuid,
          })
          .at(0)
      : null;
  }
  get type() {
    return this._type;
  }
  get fieldType() {
    return this._fieldType;
  }
  get name() {
    return this._name;
  }
  get label() {
    return this._label;
  }
  get description() {
    return this._description;
  }
  get readOnly() {
    return this._readOnly;
  }
  get value() {
    return this._value;
  }
  get valueSelections() {
    return this._valueSelections || [];
  }
  get valueSelectionEditable() {
    return this._valueSelectionEditable;
  }
  get selectionQuestionAnnotations() {
    return this._selectionQuestionAnnotations || [];
  }
  get selectionsAnnotationUuid() {
    return this._selectionsAnnotationUuid || null;
  }
  get values() {
    return this._values || [];
  }
  get metadata() {
    return this.state.metadata;
  }
  get sizeExists() {
    return this.state.offset ? true : false;
  }
  get valueExists() {
    return this.value === undefined || this.value === null || this.value === ""
      ? false
      : true;
  }
  get valuesExist() {
    return this.values.length;
  }
  get documentUuid() {
    return this._documentUuid;
  }
  get pageNumber() {
    return this._pageNumbers.length ? this._pageNumbers[0] : null;
  }
  set pageNumber(pageNumber) {
    this._pageNumbers = [pageNumber];
    return this;
  }
  get page() {
    let pageNumber = this.pageNumber;
    if (!pageNumber) return null;
    return this._documentSet.getPage(pageNumber);
  }
  get documentSet() {
    return this._documentSet;
  }
  get offset() {
    return this.state.offset;
  }
  get height() {
    return this.state.offset ? this.state.offset.height : null;
  }
  get width() {
    return this.state.offset ? this.state.offset.width : null;
  }
  get top() {
    return this.state.offset ? this.state.offset.top : null;
  }
  get left() {
    return this.state.offset ? this.state.offset.left : null;
  }
  get editForm() {
    return this._editForm;
  }
  get editFormExists() {
    return this.state.editFormExists;
  }
  get isFullPageOffset() {
    return (
      this.state.top === 0 &&
      this.state.left === 0 &&
      this.state.height === 1 &&
      this.state.width === 1
    );
  }
  get valueLabel() {
    let ret = "blank";
    // get random true false
    if (this.valueExists) ret = this.value;
    else if (this.valuesExist) {
      const labelValueMap = this.documentSet
        .findAnnotations({
          type: constants.ANNOTATION_TYPE_SELECTIONS,
        })
        .reduce((ret, annotation) => {
          annotation._annotation.selections.forEach((selection) => {
            ret[selection.uuid] = selection;
          });
          return ret;
        }, {});
      ret =
        this.values
          .map((value) =>
            value.selectionUuid &&
            labelValueMap[value.selectionUuid] &&
            labelValueMap[value.selectionUuid].label
              ? labelValueMap[value.selectionUuid].label
              : value.value
          )
          .filter((label) => (label ? true : false))
          .join(", ") || "blank";
    }
    return ret;
  }
  setMetadata(metadata) {
    console.log("SET METADATA!", this.name, metadata);
    this.setState({ metadata });
  }
  inPage(pageNumber) {
    return this._pageNumbers.includes(pageNumber);
  }
  get pageNumbers() {
    return this._pageNumbers;
  }
  isValueSelectionOther(value) {
    return value === "__r14_valueSelectionEditable";
  }
  addValueKeys(questionFields, values) {
    return values.map((value) => {
      // find key and parent key
      let key = null;
      let parentKey = null;
      let selectionKey = null;
      let fields = questionFields;
      value.questionAnnotationUuidPath.forEach((uuid) => {
        let field = fields.find((f) => f.uuid === uuid);
        if (key && !parentKey) parentKey = key;
        key = field.key;
        fields = field.questionFields;
        if (value.selectionUuid && field.selections) {
          let selection = field.selections.find(
            (selection) => selection.uuid === value.selectionUuid
          );
          selectionKey = selection ? selection.key : null;
        }
      });
      return { ...value, key, parentKey, selectionKey };
    });
  }
  initEditForm() {
    // let title = this.name;
    // let label = this.name;
    if (!this._editForm)
      this._editForm = new DocumentSetAnnotationEditFormUiDomain(this);

    let formData = {
      title: this.label || this.name,
      // fieldType: this.fieldType || null,
      fieldType: null,
      description: null,
      label: this.label || this.name,
      required: this.metadata.required,
      offsetRequired: this.metadata.offsetRequired,
      document: this.page,
      documentSet: this.documentSet,
      annotation: this,
      values: {
        documentSetUid: this.documentSet.uid,
        value: this.value,
        offset: this.offset,
        uuid: this.uuid,
        type: this.type,
      },
      readOnly: this.readOnly,
    };
    if (this.parentAnnotationUuid) {
      let parentAnnotation = this.documentSet
        .findAnnotations({
          parentAnnotationUuid: this.parentAnnotationUuid,
          debug: true,
        })
        .at(0);
      if (parentAnnotation) {
        if (parentAnnotation.label || parentAnnotation.name)
          formData.title = parentAnnotation.label || parentAnnotation.name;
        if (parentAnnotation.description)
          formData.description = parentAnnotation.description;
      }
    }
    if (this.selectionsAnnotationUuid) {
      formData.questionFields = this.generateQuestionFields();
      // formData.annotation = this;
      // formData.selectionQuestionAnnotations = this.selectionQuestionAnnotations;
      // formData.questionAnnotations = this.documentSet.findAnnotations({
      //   type: constants.ANNOTATION_TYPE_QUESTION,
      // });
      // formData.selectionsAnnotations = this.documentSet.findAnnotations({
      //   type: constants.ANNOTATION_TYPE_SELECTIONS,
      // });
    }
    if (this.questionFields && this.questionFields.length) {
      formData.questionFields = [...this.questionFields];
    }
    if (formData.questionFields && this.values && this.values.length) {
      formData.values.values = this.addValueKeys(
        formData.questionFields,
        this.values
      );

      // formData.values.values = this.values.map((value) => {
      //   // find key and parent key
      //   let key = null;
      //   let parentKey = null;
      //   let selectionKey = null;
      //   console.log("CHECK QUESTION FIELDS", formData.questionFields);
      //   let questionFields = formData.questionFields;
      //   value.questionAnnotationUuidPath.forEach((uuid) => {
      //     let field = questionFields.find((f) => f.uuid === uuid);
      //     if (key && !parentKey) parentKey = key;
      //     key = field.key;
      //     questionFields = field.questionFields;
      //     if (value.selectionUuid && field.selections) {
      //       let selection = field.selections.find((selection) => selection.uid);
      //       selectionKey = selection ? selection.key : null;
      //     }
      //   });
      //   console.log("CHECK UPDATEDE SUBMTI VALS", {
      //     ...value,
      //     key,
      //     parentKey,
      //     selectionKey,
      //   });
      //   return { ...value, key, parentKey, selectionKey };
      // });
    }
    if (this.valueSelections && this.valueSelections.length) {
      formData.valueSelections = [];
      formData.valueSelectionEditable = this.valueSelectionEditable;
      // Check if value is in values selections
      let foundSelection = false;
      for (let valueSelection of this.valueSelections) {
        if (
          typeof this.value === "string" &&
          this.value.trim().toUpperCase() === valueSelection.value.toUpperCase()
        ) {
          formData.values.valueSelection = valueSelection.value;
          // formData.values.value = valueSelection;
          foundSelection = true;
        }
        let label = valueSelection.label || valueSelection.value;
        formData.valueSelections.push({
          label: label.trim(),
          value: valueSelection.value,
        });
      }
      if (this.valueSelectionEditable) {
        formData.valueSelections.push({
          label: "Other",
          value: "__r14_valueSelectionEditable",
        });
        // if (!foundSelection) {
        //   formData.values.valueSelection = "__r14_valueSelectionEditable";
        // }
      }
    }
    // const offsetSelection = formData.values.offset || null;
    // this.editForm.updateOffsetSelection();
    // console.log("UPDAT#E OFFSET SELECTION", offsetSelection);
    this.setState({ editFormExists: true });
    return formData;
  }
  async removeEditForm() {
    this._editForm = null;
    this.setState({ editFormExists: false });
  }
  async update(values) {
    // console.log("UPDATE THE ANNOTATION!", values);
    // throw new Error(
    //   "IT IS NOT ACTUALLY UPDATING THE OFFSET IN THE IN MEMORY ANNOTATIONS!"
    // );
    //try {
    let updateValues = await this.dm.manualEntry.updateDocumentSetAnnotation(
      values,
      { annotation: this._annotation }
    );
    let updatedState = {};
    // let annotation = this._annotations.getInstance(this._uuid);
    let { uuid, type } = values;
    switch (type) {
      case constants.ANNOTATION_TYPE_FIELD:
        let reject =
          "reject" in updateValues ? updateValues.reject : this.metadata.reject;

        let changed = this.metadata.changed || false;
        if (!changed) {
          if (
            "updatedValues" in updateValues &&
            !this.dm.manualEntry.compareAnnotationValues(
              this._values,
              updateValues.updatedValues
            )
          )
            changed = true;
          else if (
            "updatedValue" in updateValues &&
            updateValues.updatedValue !== null &&
            updateValues.updatedValue !== this._value
          )
            changed = true;
        }

        if (changed) {
          this._value = updateValues.updatedValue;
          this._values = updateValues.updatedValues;
        }

        // Check if the offset has changed
        if (
          "updatedOffset" in updateValues &&
          !this.dm.manualEntry.compareAnnotationOffsets(
            this.state.offset,
            updateValues.updatedOffset
          )
        ) {
          updatedState.offset = updateValues.updatedOffset;
          changed = true;
        }
        const currMetadata = this.metadata || {};
        if (updateValues.state)
          updatedState.metadata = {
            ...currMetadata,
            state: updateValues.state,
            changed,
            reject,
          };
        // this.setMetadata({ state: updateValues.state, changed, reject });
        // Update the state if it has values
        Object.keys(updatedState).length && this.setState(updatedState);

        break;
      default:
        throw new Error(`Document Set Update Error: Unknown type '${type}'.`);
    }
    return await this.documentSet.update();
  }
}

class DocumentSetAnnotationEditFormUiDomain extends R14.Domain {
  constructor(annotation) {
    super();
    this.handleKeyMapperAction = this.handleKeyMapperAction.bind(this);
    //this.onOffsetSelectPress = this.onOffsetSelectPress.bind(this);
    this._annotation = annotation;
    this._previewDocument = new DocumentUiInstanceDomain(
      annotation.documentSet,
      null,
      annotation.page
    );
    this._offsetFieldRef = React.createRef();
    this.state = {
      offsetSelectionModeEnabled: this.documentSet.hasAppFeature(
        "enableDocumentFieldOffsetSelect"
      ),
      // offsetSelectionModeEnabled:
      //   this.documentSet.hasAppFeature("enableDocumentFieldOffsetSelect") &&
      //   !this.annotation.offset &&
      //   this.annotation.metadata.offsetRequired
      //     ? true
      //     : false,
      offsetSelection: annotation.offset || null,
    };
    this._changed = false;
    this.keyMapper = null;
  }
  get annotation() {
    return this._annotation;
  }
  get previewDocument() {
    return this._previewDocument;
  }
  get documentSet() {
    return this.annotation.documentSet;
  }
  get documentAnnotationForm() {
    return this.ui.form("documentAnnotationForm");
  }
  get changed() {
    return this._changed;
  }
  get valueSelections() {
    return this._annotation.valueSelections || [];
  }
  get valueSelectionsExist() {
    return this._annotation.valueSelections &&
      this._annotation.valueSelections.length
      ? true
      : false;
  }
  get radioQuestionFieldsExist() {
    return this._annotation.selectionsAnnotationUuid ? true : false;
    // get selectionQuestionAnnotations() {
    //   return this._selectionQuestionAnnotations || [];
    // }
    // get selectionsAnnotationUuid() {
    //   return this._selectionsAnnotationUuid || null;
    // }
    // let annotationForm = this.ui.form("documentAnnotationForm");
    // annotationForm.elmts.forEach((elmt) =>
    //   console.log("CHECK ELEMENT NAME", elmt)
    // );
    // console.log(
    //   "check questions",
    //   this._annotation.selectionQuestionAnnotations,
    //   this._annotation.selectionsAnnotationUuid
    // );
    // return true;
  }
  get valueSelectionEditable() {
    return this.valueSelectionsExist && this._annotation.valueSelectionEditable
      ? true
      : false;
  }

  get offsetFieldRef() {
    return this._offsetFieldRef;
  }

  setChanged(changed) {
    this._changed = changed;
  }
  unmount() {
    this.keyMapper && this.keyMapper.disconnect();
    // remove this instance and set editFormExists to false
  }
  mount() {
    this.initKeyMapper();
  }
  get offsetSelectionModeEnabled() {
    return this.state.offsetSelectionModeEnabled;
  }
  // disableOffsetSelectionMode() {
  //   this.setState({ offsetSelectionModeEnabled: false, offsetSelection: null });
  // }
  // toggleOffsetSelectionMode() {
  //   if (this.annotation.uuid !== this.documentSet.currentAnnotation.uuid) {
  //     console.error("Annotation edit mode must be current annotation");
  //     return false;
  //   }
  //   // Make sure the document is showing
  //   if (
  //     this.documentSet.currentPageNumber !==
  //     this.annotation.documentPositionIndex
  //   ) {
  //     this.documentSet.navToPage(this.annotation.documentPositionIndex);
  //   }
  //   // this.documentSet.currentPage.toggleSelectionMode();
  //   const updatedValue = !this.state.offsetSelectionModeEnabled;
  //   this.setState({
  //     offsetSelectionModeEnabled: updatedValue,
  //     offsetSelection: updatedValue
  //       ? this.documentAnnotationForm.elmts.offset.value
  //       : null,
  //   });
  // }
  // get disableOffsetSelect() {
  //   return this.annotation.metadata.offsetRequired &&
  //     !this.state.offsetSelection
  //     ? true
  //     : false;

  //   // return this.state.offsetSelection ? false : true;
  // }
  get hideDocumentToolbar() {
    this.documentSet.hasAppFeature("enableDocumentFieldOffsetSelect");
  }
  parseOffsetSelection(offsetSelection) {
    // Based on the page height and width, calculate the relative padding for 5 pixels
    // const paddingY = 4 / this.annotation.page.image.height;
    // const paddingX = 4 / this.annotation.page.image.width;
    const paddingY = 0;
    const paddingX = 0;
    // for testing add padding to the offsetSelection
    // offsetSelection.top -= paddingY;
    // offsetSelection.height += paddingY * 2;
    // offsetSelection.left -= paddingX;
    // offsetSelection.width += paddingX * 2;
    const offsetSelectionTop = offsetSelection.top - paddingY;
    const offsetSelectionHeight = offsetSelection.height + paddingY * 2;
    const offsetSelectionLeft = offsetSelection.left - paddingX;
    const offsetSelectionWidth = offsetSelection.width + paddingX * 2;
    const offsetSelectionBottom = offsetSelectionTop + offsetSelectionHeight;
    const offsetSelectionRight = offsetSelectionLeft + offsetSelectionWidth;
    // let updatedOffsetSelection = { ...offsetSelection };

    const words = this.annotation.page.words
      ? this.annotation.page.words.filter(({ offset, word }) => {
          const offsetBottom = offset.top + offset.height;
          const offsetRight = offset.left + offset.width;
          // Calculate the percentage that the offset intersects with the selection
          let intersection = {};
          if (offsetSelectionLeft <= offset.left)
            intersection.left = offset.left;
          else if (offsetSelectionLeft < offsetRight)
            intersection.left = offsetSelectionLeft;
          else return false;

          if (offsetSelectionTop <= offset.top) intersection.top = offset.top;
          else if (offsetSelectionTop < offsetBottom)
            intersection.top = offsetSelectionTop;
          else return false;

          if (offsetSelectionRight >= offsetRight)
            intersection.right = offsetRight;
          else if (offsetSelectionRight > offset.left)
            intersection.right = offsetSelectionRight;
          else return false;

          if (offsetSelectionBottom >= offsetBottom)
            intersection.bottom = offsetBottom;
          else if (offsetSelectionBottom > offset.top)
            intersection.bottom = offsetSelectionBottom;
          else return false;

          const intersectionArea = Math.abs(
            (intersection.right - intersection.left) *
              (intersection.bottom - intersection.top)
          );

          const wordArea = offset.height * offset.width;

          const intersectionPercentage = Math.round(
            (intersectionArea / wordArea) * 100
          );

          return intersectionPercentage >= 60;

          // return (
          //   offsetSelectionTop <= offset.top &&
          //   offsetSelectionBottom >= offsetBottom &&
          //   offsetSelectionLeft <= offset.left &&
          //   offsetSelectionRight >= offsetRight
          // );
        })
      : [];
    const value = words.map(({ word }) => word).join(" ");

    const updatedOffsetSelection = words.reduce((acc, { offset }) => {
      const offsetBottom = offset.top + offset.height;
      const offsetRight = offset.left + offset.width;
      if (!acc) return offset;
      if (offset.top < acc.top) acc.top = offset.top;
      if (offset.left < acc.left) acc.left = offset.left;
      if (offsetBottom > acc.top + acc.height)
        acc.height = offsetBottom - acc.top;
      if (offsetRight > acc.left + acc.width)
        acc.width = offsetRight - acc.left;
      return acc;
    }, null);

    // const value =
    //   offsetSelection && this.annotation.page.words
    //     ? this.annotation.page.words
    //         .filter(({ offset, word }) => {
    //           const offsetBottom = offset.top + offset.height;
    //           const offsetRight = offset.left + offset.width;
    //           return (
    //             offsetSelectionTop <= offset.top &&
    //             offsetSelectionBottom >= offsetBottom &&
    //             offsetSelectionLeft <= offset.left &&
    //             offsetSelectionRight >= offsetRight
    //           );
    //         })
    //         .map(({ word, offset }) => {
    //           if (updatedOffsetSelection.top > offset.top)
    //             updatedOffsetSelection.top = offset.top;
    //           if (updatedOffsetSelection.left > offset.left)
    //             updatedOffsetSelection.left = offset.left;
    //           if (updatedOffsetSelection.height < offset.height)
    //             updatedOffsetSelection.height = offset.height;
    //           if (updatedOffsetSelection.width < offset.width)
    //             updatedOffsetSelection.width = offset.width;
    //           return word;
    //         })
    //         .join(" ")
    //     : "";
    return {
      value,
      offsetSelection: updatedOffsetSelection || offsetSelection,
    };
  }
  updateOffsetSelectionByLocation(location) {
    if (!location) return false;
    const { top, left } = location;
    const currentSelection = this.state.offsetSelection;
    // find the annotation by the top / left
    const annotation = this.annotation.page.words.find(
      ({ offset }) =>
        offset.top <= top &&
        offset.top + offset.height >= top &&
        offset.left <= left &&
        offset.left + offset.width >= left
    );
    // Add the annotation to the current offset selection
    if (!annotation) return false;

    let updatedOffsetSelection = { ...annotation.offset };
    if (currentSelection) {
      if (currentSelection.top < annotation.offset.top)
        updatedOffsetSelection.top = currentSelection.top;
      if (currentSelection.left < annotation.offset.left)
        updatedOffsetSelection.left = currentSelection.left;
      const offsetBottom =
        currentSelection.top + currentSelection.height >
        annotation.offset.top + annotation.offset.height
          ? currentSelection.top + currentSelection.height
          : annotation.offset.top + annotation.offset.height;
      const offsetRight =
        currentSelection.left + currentSelection.width >
        annotation.offset.left + annotation.offset.width
          ? currentSelection.left + currentSelection.width
          : annotation.offset.left + annotation.offset.width;

      updatedOffsetSelection.height = offsetBottom - updatedOffsetSelection.top;
      updatedOffsetSelection.width = offsetRight - updatedOffsetSelection.left;
    }

    this.updateOffsetSelection(updatedOffsetSelection);
  }
  updateOffsetSelection(offsetSelection) {
    // const paddingY = 20 / this.annotation.page.image.height;
    // const paddingX = 20 / this.annotation.page.image.width;

    // console.log("CHECK PADDING Y", paddingY, paddingX);

    // // for testing add padding to the offsetSelection
    // offsetSelection.top -= paddingY;
    // offsetSelection.height += paddingY * 2;
    // offsetSelection.left -= paddingX;
    // offsetSelection.width += paddingX * 2;

    let updatedOffsetSelection = offsetSelection || null;
    // Update the value based on ocr
    if (this.documentAnnotationForm.elmts.value && this.annotation.page.words) {
      let updatedValue = "";
      if (updatedOffsetSelection) {
        const offsetSelectionData = this.parseOffsetSelection(
          updatedOffsetSelection
        );
        // Automatically crop to orc field
        let snapMode = false;
        if (snapMode)
          updatedOffsetSelection = offsetSelectionData.offsetSelection;
        updatedValue = offsetSelectionData.value;
      }

      // offsetSelectionData.value &&
      this.documentAnnotationForm.elmts.value.setValue(updatedValue);
    }

    // if (this.annotation.metadata.offsetRequired && !updatedOffsetSelection)
    //   return false;
    this.setState({ offsetSelection: updatedOffsetSelection });
    this.offsetFieldRef.current &&
      this.offsetFieldRef.current.triggerValueChange(updatedOffsetSelection);

    return true;
  }
  // onOffsetSelectPress() {
  //   // if (!this.state.offsetSelectionModeEnabled) {
  //   //   this.toggleOffsetSelectionMode();
  //   //   return false;
  //   // }
  //   // this.updateOffsetSelection(this.state.offsetSelection) &&
  //   //   this.disableOffsetSelectionMode();

  //   //this.updateOffsetSelection(this.state.offsetSelection);
  // }
  initKeyMapper() {
    this.keyMapper = this.ui.keyMapper.create("documentSetAnnotationEditForm", {
      repeat: false,
    });

    // If question fields, disable accept on Enter
    if (
      !this.annotation.selectionsAnnotationUuid
      // && !this.documentSet.hasAppFeature("enableDocumentFieldOffsetSelect")
    )
      this.keyMapper.addAction(
        "accept",
        ["Enter"],
        this.handleKeyMapperAction,
        {
          label: "Accept",
        }
      );
    this.keyMapper
      .onKeyDown(async ({ key }) => this.dm.manualEntry.registerKeystroke(key))
      .addAction("acceptRemaining", ["AltEnter"], this.handleKeyMapperAction, {
        label: "Accept Remaining",
      })
      .addAction(
        "rejectRemaining",
        ["ShiftCtrlEnter"],
        this.handleKeyMapperAction,
        {
          label: "Reject Remaining",
        }
      )
      .addAction("reject", ["CtrlEnter"], this.handleKeyMapperAction, {
        label: "Reject",
      })
      .addAction("previous", ["Ctrl,"], this.handleKeyMapperAction, {
        label: "Previous",
      })
      .addAction("next", ["Ctrl."], this.handleKeyMapperAction, {
        label: "Next",
      })
      .addAction(
        "previewDocumentZoomIn",
        ["ShiftCtrl}"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Zoom In",
        }
      )
      .addAction(
        "previewDocumentZoomOut",
        ["ShiftCtrl{"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Zoom Out",
        }
      )
      .addAction(
        "previewDocumentZoomReset",
        ["ShiftCtrl|"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Zoom Reset",
        }
      )
      .addAction(
        "previewDocumentScrollUp",
        ["ShiftCtrlArrowUp"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Scroll Up",
        }
      )
      .addAction(
        "previewDocumentScrollDown",
        ["ShiftCtrlArrowDown"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Scroll Down",
        }
      )
      .addAction(
        "previewDocumentScrollLeft",
        ["ShiftCtrlArrowLeft"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Scroll Left",
        }
      )
      .addAction(
        "previewDocumentScrollRight",
        ["ShiftCtrlArrowRight"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Scroll Right",
        }
      )
      .addAction(
        "previewDocumentRotateLeft",
        ["ShiftCtrl<"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Rotate Left",
        }
      )
      .addAction(
        "previewDocumentRotateRight",
        ["ShiftCtrl>"],
        this.handleKeyMapperAction,
        {
          label: "Preview Document Rotate Right",
        }
      )
      .addAction("documentZoomIn", ["ShiftAlt}"], this.handleKeyMapperAction, {
        label: "Document Zoom In",
      })
      .addAction("documentZoomOut", ["ShiftAlt{"], this.handleKeyMapperAction, {
        label: "Document Zoom Out",
      })
      .addAction(
        "documentZoomReset",
        ["ShiftAlt|"],
        this.handleKeyMapperAction,
        {
          label: "Document Zoom Reset",
        }
      )
      .addAction(
        "documentScrollUp",
        ["ShiftAltArrowUp"],
        this.handleKeyMapperAction,
        {
          label: "Document Scroll Up",
        }
      )
      .addAction(
        "documentScrollDown",
        ["ShiftAltArrowDown"],
        this.handleKeyMapperAction,
        {
          label: "Document Scroll Down",
        }
      )
      .addAction(
        "documentScrollLeft",
        ["ShiftAltArrowLeft"],
        this.handleKeyMapperAction,
        {
          label: "Document Scroll Left",
        }
      )
      .addAction(
        "documentScrollRight",
        ["ShiftAltArrowRight"],
        this.handleKeyMapperAction,
        {
          label: "Document Scroll Right",
        }
      )
      .addAction(
        "documentRotateLeft",
        ["ShiftAlt<"],
        this.handleKeyMapperAction,
        {
          label: "Document Rotate Left",
        }
      )
      .addAction(
        "documentRotateRight",
        ["ShiftAlt>"],
        this.handleKeyMapperAction,
        {
          label: "Document Rotate Right",
        }
      )
      .addAction(
        "documentRotateSave",
        ["ShiftAltEnter"],
        this.handleKeyMapperAction,
        {
          label: "Document Rotate Save",
        }
      );
    // Enter value selections
    if (this.valueSelectionsExist) {
      let kIdx = 0;
      this.valueSelections.forEach((valueSelection, idx) => {
        kIdx = idx + 1;
        if (kIdx > 12) return;
        this.keyMapper.addAction(
          `selectValueSelection${kIdx}`,
          [`F${kIdx}`],

          this.handleKeyMapperAction,
          { label: `Selection ${kIdx}` }
        );
      });
      kIdx++;
      if (this.valueSelectionEditable)
        this.keyMapper.addAction(
          `selectValueSelectionOther`,
          [`F${kIdx}`],
          this.handleKeyMapperAction,
          { label: `Selection ${kIdx}` }
        );
    } else if (this.radioQuestionFieldsExist) {
      for (let kIdx = 1; kIdx <= 12; kIdx++) {
        this.keyMapper.addAction(
          `selectRadioQuestionSelection${kIdx}`,
          [`F${kIdx}`],

          this.handleKeyMapperAction,
          { label: `Selection ${kIdx}` }
        );
      }
    }
  }
  async handleKeyMapperAction({ e, actionName, key }) {
    e.preventDefault();
    let annotation = null;
    switch (actionName) {
      case "next":
        annotation = this.documentSet.findAnnotation({
          afterAnnotationUuid: this.annotation.uuid,
        });
        annotation && this.documentSet.navToAnnotation(annotation.uuid);
        break;
      case "previous":
        annotation = this.documentSet.findAnnotation({
          beforeAnnotationUuid: this.annotation.uuid,
        });
        annotation && this.documentSet.navToAnnotation(annotation.uuid);
        break;
      case "reject":
        if (
          !this.documentSet.hasAppFeature("disableReject") &&
          !this.documentSet.documentCompleteDialogVisible
        )
          await this.documentAnnotationForm.submit({ reject: true });
        break;
      case "accept":
        if (this.documentSet.documentCompleteDialogVisible)
          await this.documentSet.complete();
        else this.documentAnnotationForm.submit();
        // this.documentAnnotationForm.submit();
        break;
      case "acceptRemaining":
        if (
          !this.documentSet.hasAppFeature("disableAcceptRemaining") &&
          !this.documentSet.documentCompleteDialogVisible
        )
          this.documentAnnotationForm.submit({ acceptRemaining: true });
        break;
      case "rejectRemaining":
        if (
          !this.documentSet.hasAppFeature("disableRejectRemaining") &&
          !this.documentSet.documentCompleteDialogVisible
        )
          this.documentAnnotationForm.submit({ rejectRemaining: true });
        break;
      case "previewDocumentZoomIn":
        this.previewDocument.zoomIn();
        break;
      case "previewDocumentZoomOut":
        this.previewDocument.zoomOut();
        break;
      case "previewDocumentZoomReset":
        this.previewDocument.zoomReset();
        break;
      case "previewDocumentRotateLeft":
        this.previewDocument.rotateLeft();
        break;
      case "previewDocumentRotateRight":
        this.previewDocument.rotateRight();
        break;
      case "previewDocumentScrollUp":
        this.previewDocument.scrollUp();
        break;
      case "previewDocumentScrollDown":
        this.previewDocument.scrollDown();
        break;
      case "previewDocumentScrollLeft":
        this.previewDocument.scrollLeft();
        break;
      case "previewDocumentScrollRight":
        this.previewDocument.scrollRight();
        break;
      case "documentZoomIn":
        this.documentSet.currentPage.zoomIn();
        break;
      case "documentZoomOut":
        this.documentSet.currentPage.zoomOut();
        break;
      case "documentZoomReset":
        this.documentSet.currentPage.zoomReset();
        break;
      case "documentScrollUp":
        this.documentSet.currentPage.scrollUp();
        break;
      case "documentScrollDown":
        this.documentSet.currentPage.scrollDown();
        break;
      case "documentScrollLeft":
        this.documentSet.currentPage.scrollLeft();
        break;
      case "documentScrollRight":
        this.documentSet.currentPage.scrollRight();
        break;
      case "documentRotateLeft":
        this.documentSet.currentPage.rotateLeft();
        break;
      case "documentRotateRight":
        this.documentSet.currentPage.rotateRight();
        break;
      case "documentRotateSave":
        this.documentSet.rotateDocument();
        break;
      default:
        if (
          this.valueSelectionsExist &&
          actionName.startsWith("selectValueSelection") &&
          key.startsWith("F") &&
          [2, 3].includes(key.length)
        ) {
          if (actionName === "selectValueSelectionOther") {
            this.documentAnnotationForm.elmts.valueSelection.value =
              "__r14_valueSelectionEditable";

            // this.documentAnnotationForm.elmts.valueSelection.focus({
            //   item: {
            //     label: "Other",
            //     value: "__r14_valueSelectionEditable",
            //   },
            // });
          } else {
            this.valueSelections.forEach((valueSelection, idx) => {
              idx++;
              if (key !== `F${idx}`) return;
              this.documentAnnotationForm.elmts.valueSelection.value =
                valueSelection.value;
              // this.documentAnnotationForm.elmts.valueSelection.focus({
              //   item: valueSelection,
              // });
              // this.documentAnnotationForm.elmts.valueSelection.blurItems();
            });
          }
        } else if (
          this.radioQuestionFieldsExist &&
          actionName.startsWith("selectRadioQuestionSelection") &&
          key.startsWith("F") &&
          [2, 3].includes(key.length)
        ) {
          // Try to find a radio group in the form
          let elmt = this.documentAnnotationForm.elmts.find((elmt) =>
            elmt.name.startsWith("_mdsdqf-")
          );
          if (!elmt) return;
          let item = elmt.items
            ? elmt.items.find((item, idx) => key === `F${idx + 1}`)
            : null;
          if (!item) return;
          elmt.value = item.value;
        }
    }
  }
}
